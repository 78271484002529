import React, { useState, useEffect } from "react";
// import Calendar from "react-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
// import { RiArrowGoBackFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { firestore } from "../../firebase/config";
import AcademicCalendarListView from "./AcademicCalendarListView";

function AcademicCalendar(props) {
  const [myEvents, setMyEvents] = useState([]);
  const localizer = momentLocalizer(moment);
  const [showView, setView] = useState("monthly");
  const [events, setEvents] = useState(null);
  // const [showPrePrimary, setShowPrePrimary] = useState(false);
  // const [showPrimary, setShowPrimary] = useState(false);
  // const [showUpperPrimary, setShowUpperPrimary] = useState(false);
  // const [showSecondary, setShowSecondary] = useState(false);
  const [section, setSection] = useState("");
  const [sectionDisplayName, setSectionDisplayName] = useState("");
  const param = useParams();
  useEffect(() => {
    getEvents();
  }, []);
  const divStyle = {
    height: "700px",
  };

  const eventSelected = (event, e) => {
    // alert(event.title);
  };

  /**
   * Gets current academic year as per today's date
   * @param {} month
   * @returns
   */
  const getCurrentAY = () => {
    const tDate = new Date();
    let year = tDate.getFullYear();
    let preYear = year - 1;
    let nextYear = year + 1;
    let month = tDate.getMonth();

    if (month < 5) {
      //From Jan to May
      return preYear.toString() + "-" + year.toString().substring(2, 4);
    } else {
      //From June to Dec
      return year.toString() + "-" + nextYear.toString().substring(2, 4);
    }
  }; //End of getCurrentAY
  const getMonthOfDate = (month) => {
    switch (month) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";
      default:
        break;
    }
  }; //End of getMonthOfDate

  const getEvents = () => {
    setSection(param.section);
    let queryCriteria = "";
    switch (param.section) {
      case "prePrimary":
        queryCriteria = "isPrePrimary";
        setSectionDisplayName("Pre Primary");
        break;
      case "Primary":
        queryCriteria = "isPrimary";
        setSectionDisplayName("Primary");
        break;
      case "upperPrimary":
        queryCriteria = "isUpperPrimary";
        setSectionDisplayName("Upper Primary");
        break;
      case "secondary":
        queryCriteria = "isSecondary";
        setSectionDisplayName("Secondary");
        break;
      default:
        queryCriteria = "isPrePrimary";
        break;
    }
    let sCurrentAY = getCurrentAY();
    firestore
      .collection("academic-calendar-events")
      .where(queryCriteria, "==", true)
      .where("academicYear", "==", sCurrentAY)
      .orderBy("start", "asc")
      .onSnapshot((querySnapshot) => {
        let eventList = [];
        let arrEvents = [];
        querySnapshot.forEach((event) => {
          let dStart = event.data().start.toDate();
          let sStart =
            dStart.getDate() +
            "-" +
            getMonthOfDate(dStart.getMonth()) +
            "-" +
            dStart.getFullYear();

          let dEnd = event.data().end.toDate();
          let sEnd =
            dEnd.getDate() +
            "-" +
            getMonthOfDate(dEnd.getMonth()) +
            "-" +
            dEnd.getFullYear();

          arrEvents.push({
            id: event.id,
            title: event.data().title,
            start: sStart,
            end: sEnd,
            isPrePrimary: event.data().isPrePrimary,
            isPrimary: event.data().isPrimary,
            isUpperPrimary: event.data().isUpperPrimary,
            isSecondary: event.data().isSecondary,
            type: event.data().type,
            academicYear: event.data().academicYear,
          }); //arrEvents.push

          eventList.push({
            id: event.id,
            title: event.data().title,
            start: event.data().start.toDate(),
            end: event.data().end.toDate(),
            resources: {
              type: event.data().type,
            },
          }); //eventList.push
        });
        setMyEvents(eventList);
        setEvents(arrEvents);
      });
  }; //End of getEvents
  /**
   * provide a function that returns an object of *className or style props to be applied to the the * event node
   * @param {*} event
   * @returns a style prop
   */
  const customEventPropGetter = (event) => {
    if (event.resources !== undefined) {
      if (event.resources.type === "Holiday") {
        return {
          style: {
            backgroundColor: "red",
            fontSize: "25px",
            height: "35px",
          },
        };
      } else if (event.resources.type === "Exam") {
        return {
          style: {
            backgroundColor: "green",
            fontSize: "25px",
            height: "35px",
          },
        };
      } else {
        return {
          style: {
            fontSize: "25px",
            height: "35px",
          },
        };
      }
    }
    return {};
  }; //End of customEventPropGetter

  useEffect(() => {
    setSectionDisplayName(param.section);
    getEvents();
    return () => {};
  }, [param.section]);
  return (
    <>
      <div className="AcademicCalendarContent">
        <div className="row">
          <div className="col-sm text-danger text-center">
            <h4>
              These dates are for reference purposes only, and are subject to
              change according to the COVID situation and related Government
              Guidelines
            </h4>
          </div>
        </div>
        {showView === "monthly" ? (
          <div>
            <div className="row">
              <div className="col-sm text-primary text-center m-2">
                <button
                  className="btn btn-link"
                  onClick={(e) => {
                    e.preventDefault();
                    setView("yearly");
                  }}
                >
                  <h3>Go To Yearly Calendar</h3>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-sm text-center">
                <h4>{sectionDisplayName}</h4>
              </div>
            </div>
            <div className="row" style={divStyle}>
              <div className="col-sm">
                {/* <Calendar minDate = {new Date('01-Jun-2021')} maxDate={new Date('16-Apr-2022')} defaultView='month' minDetail='month'/> */}
                <Calendar
                  localizer={localizer}
                  popup
                  popupOffset={30}
                  events={myEvents}
                  step={12 * 60}
                  startAccessor="start"
                  endAccessor="end"
                  views={["month"]}
                  onSelectEvent={eventSelected}
                  eventPropGetter={customEventPropGetter}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-sm text-primary text-center">
                <button
                  className="btn btn-link"
                  onClick={(e) => {
                    e.preventDefault();
                    setView("monthly");
                  }}
                >
                  <h3>Go To Monthly Calendar</h3>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-sm text-center">
                <h4>{sectionDisplayName}</h4>
              </div>
            </div>
            {/**List of holidays */}
            <div className="row mt-2">
              <div className="col text-center text-danger">
                <h5>List of Holidays for AY {getCurrentAY()}</h5>
              </div>
            </div>

            <div
              className="table-responsive-sm mr-5 ml-5"
              style={{ width: "unset" }}
            >
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Occasion</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                  <AcademicCalendarListView
                    academicevents={events}
                    section={section}
                    type="Holiday"
                    academicYear={getCurrentAY()}
                  />
                </thead>
              </table>
            </div>

            {/**List of events */}
            <div className="row mt-2">
              <div className="col text-center text-danger">
                <h5>List of Events for AY {getCurrentAY()}</h5>
              </div>
            </div>
            <div
              className="table-responsive-sm mr-5 ml-5"
              style={{ width: "unset" }}
            >
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Occasion</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                  <AcademicCalendarListView
                    academicevents={events}
                    section={section}
                    type="School Event"
                    academicYear={getCurrentAY()}
                  />
                </thead>
              </table>
            </div>

            {/**List of exams */}
            <div className="row mt-2">
              <div className="col text-center text-danger">
                <h5>List of Exams for AY {getCurrentAY()}</h5>
              </div>
            </div>
            <div
              className="table-responsive-sm mr-5 ml-5"
              style={{ width: "unset" }}
            >
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Occasion</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                  <AcademicCalendarListView
                    academicevents={events}
                    section={section}
                    type="Exam"
                    academicYear={getCurrentAY()}
                  />
                </thead>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default AcademicCalendar;
