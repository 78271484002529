import React, { useState } from "react";

import map from "../img/map.svg";
import phone from "../img/phone.svg";
import email from "../img/email.svg";
import { ImFacebook2 } from "react-icons/im";
function Footer() {
  const [year] = useState(new Date().getFullYear());

  return (
    <>
      <div className="row bg-dark text-white text-center resetMargin">
        <div className="col mr-3 ml-3">
          <h5 className="pt-2 pb-2 font-weigh-bold border-bottom conactInfoText mb-2">
            Contact Info{" "}
          </h5>
        </div>
      </div>

      <div className="row bg-dark text-white text-center black-bottom-border resetMargin">
        <div className="col-md-4 alignFooterContent ">
          <div className="text-left m-2">
            <img src={map} alt="map"></img>
          </div>
          <div className="text-left m-2">
            <div className="text-left">
              Bhakrivedanta Model School <br />
              Kondhwa-Katraj Bypass Road, <br />
              Pune – 411048, Maharashtra,
              <br /> India
            </div>
          </div>
        </div>

        <div className="col-md-4 alignFooterContent resetMargin">
          <div className="text-left m-2">
            <img src={phone} alt="phone"></img>
          </div>
          <div className="text-left m-2">
            <div className="text-left">
              <a href="tel:9823429936">9823429936</a>
            </div>
          </div>
        </div>

        <div className="col-md-4 alignFooterContent resetMargin">
          <div className="text-left m-2">
            <img src={email} alt="email"></img>
          </div>
          <div className="text-left m-2">
            <div className="text-left">
              <a href="mailto:office@bmspune.org">office@bmspune.org</a>
            </div>
          </div>
        </div>
      </div>

      <div className="row bg-dark text-white text-center d-flex flex-column justify-content-center resetMargin">
        <div className="col footer-social-icon mt-2 mb-2">
          <span>Follow us</span>
        </div>
      </div>
      <div className="row bg-dark text-white text-center d-flex flex-column justify-content-center black-bottom-border resetMargin">
        <div className="col footer-social-icon mb-2">
          <span>
            <a href="https://www.facebook.com/bmschool.pune" target="_blank">
              <ImFacebook2
                style={{ color: "#3B5998", backgroundColor: "white" }}
              />
            </a>
          </span>
        </div>
        <div className="row footer-copyright ml-2 mr-2 pt-1">
          <div className="col-md-4 text-center text-white">{`Copyright © ${year}, All Right Reserved`}</div>
          <div className="col-md-4 text-center" style={{ color: "#ff5e14" }}>
            Bhaktivedanta Model School, Pune
          </div>
          <div className="col-md-4 text-center text-white">
            <div className="row">
              <div className="col-md-4 text-center text-white">
                <a
                  href="https://bmspune.org/#/terms"
                  style={{ color: "white" }}
                >
                  Terms
                </a>
              </div>
              <div className="col-md-4 text-center text-white">
                <a
                  href="https://bmspune.org/#/disclaimer"
                  style={{ color: "white" }}
                >
                  Disclaimer
                </a>
              </div>
              <div className="col-md-4 text-center text-white">
                <a
                  href="https://bmspune.org/#/privacy"
                  style={{ color: "white" }}
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
