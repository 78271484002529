import "./App.css";
import { useEffect, useState } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Header from "./pages/Header";
import { UserProvider, useSession } from "./firebase/UserProvider";
import PageRoute from "./route/PageRoute";
import Default from "./pages/Default";
import TestPage from "./pages/TestPage";
import { UserPrivilageProvider } from "./firebase/UserPrivilageProvider";
import PrivateDMSRoute from "./route/PrivateDMSRoute";

import PrivateParentRoute from "./route/PrivateParentRoute";
import PrivateStaffRoute from "./route/PrivateStaffRoute";
import PrivateEMSRoute from "./route/PrivateEMSRoute";
import SearchDonor from "./pages/SearchDonor";
import DonorDetails from "./pages/DonorDetails";
import AcceptDonation from "./pages/AcceptDonation";
import Sidebar from "./pages/Sidebar";
import AddANewDonor from "./pages/AddANewDonor";
import CreateParentUser from "./pages/ams/createParentUser";
import ParentDetails from "./pages/ams/ParentDetails";
import AdmissionPanel from "./pages/ams/AdmissionPanel";
import timeSlotsInternal from "./pages/ams/timeSlotsInternal";
import staffViewTimeSlots from "./pages/ams/staffViewTimeSlots";
import AdmissionDashboard from "./pages/ams/AdmissionDashboard";

import PrivateAdminRoute from "./route/PrivateAdminRoute";
import SearchUser from "./pages/SearchUser";
import UpdateStudentData from "./pages/ams/updateStudentData";
import ExpenseReport from "./pages/ems/ExpenseReport";
import AddNewItem from "./pages/ems/AddNewItem";
import AcademicCalendar from "./pages/academic-calendar/AcademicCalendar";
import PrivateAcademicCalendarAdminRoute from "./route/PrivateAcademicCalendarAdminRoute";
import AddANewAcademicEvent from "./pages/academic-calendar/AddANewAcademicEvent";
import SearchEventByTitle from "./pages/academic-calendar/SearchEventByTitle";
import EventDetail from "./pages/academic-calendar/EventDetail";
import Footer from "./pages/Footer";
import Classroom from "./pages/g-classroom-dashboard/Classroom";
import PrivateGClassroomDashboardRoute from "./route/PrivateGClassroomDashboardRoute";
import PrivateDMSReportsRoute from "./route/PrivateDMSReportsRoute";
import DonorReportHome from "./pages/donation-reports/DonorReportHome";
import DateRangeDonationReport from "./pages/donation-reports/DateRangeDonationReport";
import AddCommitment from "./pages/AddCommitment";
import EditCommitment from "./pages/EditCommitment";
import UpdateOnRecord from "./pages/oneTimeJob/UpdateOnRecord";
import SearchDonorByName from "./pages/SearchDonorByName";
import AllDonorDetailReport from "./pages/donation-reports/AllDonorDetailReport";
import MissedCommmittmentReport from "./pages/donation-reports/MissedCommittmentReport";
import { firestore } from "./firebase/config";

function App() {
  const user = useSession();

  const [isParentPortal, setIsParentPortal] = useState(false);
  const [showMaintenenceMessage, setShowMaintenenceMessage] = useState(false);
  const [maintenenceMessage, setMaintenenceMessage] = useState("");
  const [windowDimensions, setWindowDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const handleWindowResize = () => {
    setWindowDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };
  window.addEventListener("resize", handleWindowResize);
  useEffect(() => {
    firestore
      .collection("system-preferences")
      .where("name", "==", "showMaintenanceMessage")
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          let value = snapshot.docs[0].data().value;
          let message = snapshot.docs[0].data().message;

          setShowMaintenenceMessage(value);
          setMaintenenceMessage(message);
        }
      });

    window.location.href.indexOf("int") < 0
      ? setIsParentPortal(true)
      : setIsParentPortal(false);
  }, []);

  const appClass = `${isParentPortal ? "app-div" : ""}`;
  const mainContentClass = `${isParentPortal ? "main-content-div" : ""}`;
  const alignContent = `${isParentPortal ? "alignContent" : ""}`;

  return (
    <>
      {showMaintenenceMessage ? (
        <>
          <h2>
            <div className="text-danger">{maintenenceMessage}</div>
          </h2>
        </>
      ) : (
        <>
          <UserProvider>
            <UserPrivilageProvider>
              <BrowserRouter>
                <div id="app" className={appClass}>
                  <div id="main-content" className={mainContentClass}>
                    <Header />
                    <div className={alignContent}>
                      <div
                        className={user ? `float-right main` : `switchContent`}
                      >
                        {isParentPortal && (
                          <div className="row rectangle d-flex flex-nowrap justify-content-start justify-content-md-end resetMargin ml-2 mr-2">
                            <div className="p-2">
                              <div>
                                <h3>
                                  {windowDimensions.width > 768
                                    ? "Bhaktivedanta Model School"
                                    : "BMS"}
                                </h3>
                              </div>
                            </div>
                            <div className="w-100">
                              <div></div>
                            </div>

                            <div className="p-2">
                              <div>
                                <h3>{"Jharokha"}</h3>
                              </div>
                            </div>
                          </div>
                        )}
                        <div>
                          <Sidebar />
                          <Switch>
                            <PageRoute exact path="/home" component={Home} />
                            <PrivateDMSRoute
                              exact
                              path="/test"
                              component={TestPage}
                            />
                            <PrivateDMSRoute
                              exact
                              path="/donorsearch"
                              component={SearchDonor}
                            />
                            <PrivateDMSRoute
                              exact
                              path="/donorsearchbyname"
                              component={SearchDonorByName}
                            />
                            <PrivateDMSRoute
                              exact
                              path="/alldonordetailreport"
                              component={AllDonorDetailReport}
                            />
                            <PrivateDMSRoute
                              exact
                              path="/addnewdonor"
                              component={AddANewDonor}
                            />
                            <PrivateDMSRoute
                              exact
                              path="/donordetails/:donorId"
                              component={DonorDetails}
                            />
                            <PrivateDMSRoute
                              exact
                              path="/acceptdonation/:type/:donorId"
                              component={AcceptDonation}
                            />
                            <PrivateDMSRoute
                              exact
                              path="/addcommitment/:donorId"
                              component={AddCommitment}
                            />
                            <PrivateDMSRoute
                              exact
                              path="/editcommitment/:donorId/:commitmentId"
                              component={EditCommitment}
                            />
                            <PrivateDMSRoute
                              exact
                              path="/updateOnRecord"
                              component={UpdateOnRecord}
                            />
                            <PrivateAdminRoute
                              exact
                              path="/searchuser"
                              component={SearchUser}
                            />

                            <PrivateStaffRoute
                              exact
                              path="/createparentuser"
                              component={CreateParentUser}
                            />

                            <PrivateStaffRoute
                              exact
                              path="/timeslotsinternal"
                              component={timeSlotsInternal}
                            />

                            <PrivateStaffRoute
                              exact
                              path="/staffviewtimeslots"
                              component={staffViewTimeSlots}
                            />
                            <PrivateParentRoute
                              exact
                              path="/updatestudentdata"
                              component={UpdateStudentData}
                            />
                            <PrivateParentRoute
                              exact
                              path="/parentdetails"
                              component={ParentDetails}
                            />
                            <PrivateParentRoute
                              exact
                              path="/admissionpanel"
                              component={AdmissionPanel}
                            />

                            <PrivateParentRoute
                              exact
                              path="/admissionpanel/:studentId"
                              component={AdmissionDashboard}
                            />

                            <PrivateEMSRoute
                              exact
                              path="/expensereport"
                              component={ExpenseReport}
                            />

                            <PrivateEMSRoute
                              exact
                              path="/expensereport/bill"
                              component={AddNewItem}
                            />
                            <PrivateAcademicCalendarAdminRoute
                              exact
                              path="/addanewacademiccalendarevent"
                              component={AddANewAcademicEvent}
                            />
                            <PrivateAcademicCalendarAdminRoute
                              exact
                              path="/searchacademiccalendareventbytitle"
                              component={SearchEventByTitle}
                            />
                            <PrivateAcademicCalendarAdminRoute
                              exact
                              path="/eventdetail/:eventId"
                              component={EventDetail}
                            />
                            <PrivateGClassroomDashboardRoute
                              exact
                              path="/g-classroom-dashboard"
                              component={Classroom}
                            />
                            <PrivateDMSReportsRoute
                              exact
                              path="/donorreporthome"
                              component={DonorReportHome}
                            />
                            <PrivateDMSReportsRoute
                              exact
                              path="/donationdaterangereport"
                              component={DateRangeDonationReport}
                            />
                            <PrivateDMSReportsRoute
                              exact
                              path="/missedcommittmentreport"
                              component={MissedCommmittmentReport}
                            />
                            {/* <Route exact path="/test" component={TestPage}/> */}
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/">
                              <Redirect to="/login" />
                            </Route>
                            <Route
                              exact
                              path="/academiccalendar/:section"
                              component={AcademicCalendar}
                            />
                            <Default notfound />
                          </Switch>
                        </div>
                      </div>
                    </div>
                    {isParentPortal && <Footer />}
                  </div>
                </div>
              </BrowserRouter>
            </UserPrivilageProvider>
          </UserProvider>
        </>
      )}
    </>
  );
}

export default App;
