import React, { useState, useContext, useEffect } from "react";

const DonationContext = React.createContext();
const DontaionDateContext = React.createContext();
const DonationBankRefDateContext = React.createContext();
const DonationAmountInWordContext = React.createContext();
const DonorDetailsContext = React.createContext();
const UserContext = React.createContext();

export function GetLoggedUserDetails() {
  return useContext(UserContext);
}

export function GetDonorDetails() {
  return useContext(DonorDetailsContext);
}

export function GetDonation() {
  return useContext(DonationContext);
}

export function GetDonationDate() {
  return useContext(DontaionDateContext);
}

export function GetDonationBankRefDate() {
  return useContext(DonationBankRefDateContext);
}

export function GetDonationAmountInWords() {
  return useContext(DonationAmountInWordContext);
}

export function DonationContextProvider(props) {
  const [donationDate, setDonationDate] = useState("");
  const [donationBankRefDate, setDonationBankRefDate] = useState("");
  const [donationAmountInWord, setDonationAmountInWord] = useState("");
  const donation = props.donation;
  const donorDetails = props.donorDetails;
  const user = props.user;

  const getMonthInWord = (iMonth) => {
    switch (iMonth) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";
      default:
        break;
    }
  };

  useEffect(() => {
    //Get donation date as string
    const dDate = new Date(props.donation.date);

    let sDate = dDate.getDate();
    let sMonth = getMonthInWord(dDate.getMonth());
    let sYear = dDate.getFullYear();

    setDonationDate("" + sDate + "-" + sMonth + "-" + sYear);

    //Get Bank ref date as string
    let bankRefDate = props.donation.bankRefDate;
    if (bankRefDate !== "" && bankRefDate !== undefined) {
      const dBankRefDate = new Date(bankRefDate);
      sDate = dBankRefDate.getDate();
      sMonth = getMonthInWord(dBankRefDate.getMonth());
      sYear = dBankRefDate.getFullYear();

      setDonationBankRefDate("" + sDate + "-" + sMonth + "-" + sYear);
    }

    //Get the donation amount wordings
    let num = donation.amount;
    var a = [
      "",
      "One ",
      "Two ",
      "Three ",
      "Four ",
      "Five ",
      "Six ",
      "Seven ",
      "Eight ",
      "Nine ",
      "Ten ",
      "Eleven ",
      "Twelve ",
      "Thirteen ",
      "Fourteen ",
      "Fifteen ",
      "Sixteen ",
      "Seventeen ",
      "Eighteen ",
      "Nineteen ",
    ];
    var b = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          ""
        : "";
    setDonationAmountInWord(str + "only");
  }, []);

  return (
    <DonationContext.Provider value={donation}>
      <DontaionDateContext.Provider value={donationDate}>
        <DonationBankRefDateContext.Provider value={donationBankRefDate}>
          <DonationAmountInWordContext.Provider value={donationAmountInWord}>
            <DonorDetailsContext.Provider value={donorDetails}>
              <UserContext.Provider value={user}>
                {props.children}
              </UserContext.Provider>
            </DonorDetailsContext.Provider>
          </DonationAmountInWordContext.Provider>
        </DonationBankRefDateContext.Provider>
      </DontaionDateContext.Provider>
    </DonationContext.Provider>
  );
}
