import React, { memo } from 'react'

export default memo(function Default() {
    return (
        <div>
            <div id="default">
            <h2 className="text-center text-blue pt-5">Somethig is wrong!</h2>
            </div>
        </div>
    )
})
