import { firestore } from "../../firebase/config";

/**
 *
 * @param {*} sDate Date value in string format
 * @returns
 * The value of the financial year in YYYY-YY format e.g. 2021-22
 */
export function getFYofDate(sDate) {
  const tDate = new Date(sDate);
  let year = tDate.getFullYear();
  let preYear = year - 1;
  let nextYear = year + 1;
  let month = tDate.getMonth();

  if (month < 3) {
    //From Jan to Mar
    return preYear.toString() + "-" + year.toString().substring(2, 4);
  } else {
    //From Apr to Dec
    return year.toString() + "-" + nextYear.toString().substring(2, 4);
  }
}
/**
 * returns date in dd-mon-yyyy format
 */
export function getDateInFormat(sDate) {
  const monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dDate = new Date(sDate);
  return sDate !== undefined && sDate !== ""
    ? dDate.getDate() +
        "-" +
        monthArray[dDate.getMonth()] +
        "-" +
        dDate.getFullYear()
    : "Not Available";
} //End of getDateInFormat

/**
 * Accepts donor refernce id as parameter and returns donor's full name
 * @param {Donor reference Id} params
 */
export function getDonorReference(refId) {
  if (refId) {
    return `Got ${refId}`;
  }
  firestore
    .collection("donors")
    .doc(refId)
    .get()
    .then((snapShot) => {
      if (snapShot.exists) {
        return snapShot.data().fullName;
      } else {
        return "Ref error!";
      }
    });
}
