import React from "react";
import { GetUserPrivilages } from "../../firebase/UserPrivilageProvider";

import { Link } from "react-router-dom";
function DonorReportHome() {
  const privilages = GetUserPrivilages();
  return (
    <>
      <div className="card">
        <div className="justify-content-center d-flex">
          <h1>Donations Report</h1>
        </div>
      </div>

      <div
        className="card border-top-0 rounded-0"
        style={{ position: "inherit" }}
      >
        {privilages.canAccessDMS && (
          <>
            <div className="row">
              <div className="col-md"> Select here to see report's..</div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <div className="mw-100 list-group-item text-center list-group-item-info">
                  <section
                    className="mw-100 btn-group align-self-center pr-1"
                    role="group"
                  >
                    <Link
                      className="font-weight-bold"
                      to="/donationdaterangereport"
                    >
                      <span></span> Donations Report By Date <span></span>
                    </Link>
                  </section>
                </div>
              </div>
              <div className="col-md-3">
                <div className="mw-100 list-group-item text-center list-group-item-info">
                  <section
                    className="mw-100 btn-group align-self-center pr-1"
                    role="group"
                  >
                    <Link
                      className="font-weight-bold"
                      to="/alldonordetailreport"
                    >
                      <span></span> All Donor Detail Report <span></span>
                    </Link>
                  </section>
                </div>
              </div>
              <div className="col-md-3">
                <div className="mw-100 list-group-item text-center list-group-item-info">
                  <section
                    className="mw-100 btn-group align-self-center pr-1"
                    role="group"
                  >
                    <Link
                      className="font-weight-bold"
                      to="/missedcommittmentreport"
                    >
                      <span></span> Missed Committment Report <span></span>
                    </Link>
                  </section>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default DonorReportHome;
