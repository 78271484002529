import React from "react";
import { firestore } from "../../firebase/config";
import delayAdapterEnhancer from "axios-delay";
// import { GetUserPrivilages } from "../../firebase/UserPrivilageProvider";

const axios = require("axios");
const rax = require("retry-axios");

var OAUTHURL = "https://accounts.google.com/o/oauth2/v2/auth?";
// var TOKENURL    =   'https://oauth2.googleapis.com/token';
// const redirectUri = "http://localhost:3000/classroomapp";
const redirectUri = process.env.REACT_APP_GOOGLE_REDIRCT_URL;
// const redirectUri = "http://localhost:3000/g-classroom-dashboard";
const scopes =
  "https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.coursework.me.readonly https://www.googleapis.com/auth/classroom.coursework.students.readonly https://www.googleapis.com/auth/classroom.topics.readonly https://www.googleapis.com/auth/classroom.rosters.readonly"; //For multiple scopes concatenated space delimated string

const assignmentDangerLevel = 12;
const assignmentCautionLevel = 12;
const assingmentDangerPercent = 70;
const assingmentCautionPercent = 85;
const AUTO_UPLOAD_TO_FIREBASE = true;
const validTitle = [
  "English",
  "Hindi",
  "Science",
  "Maths",
  "Sanskrit",
  "History",
  "Geography",
];

const delaySizeInMilliSeconds = 10000;

const api = axios.create({
  adapter: delayAdapterEnhancer(axios.defaults.adapter),
});
/**
 * Gets auth code from redirected URL after successful login
 * @param {*} name the auth code to be fetched
 * @param {*} url redirct url
 * @returns
 */
function gup(name, url) {
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
// const privilages = GetUserPrivilages();
class Classroom extends React.Component {
  pollTimer;
  classroomService;
  isTokenRetrieved = false;
  client;
  assignmentReportData = {};
  topics = {};
  courses = {};
  yearWiseCourses = [];
  yearMenuList = [];

  topicsRow = [];
  topicsSubRow = [];
  assignmentRows = [];

  isLoading = false;

  timestamp;
  selectedYear = "";

  accessToken;

  constructor() {
    super();
    this.clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    this.clientSecret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
  }

  /**
   * fetches cources as per year and prepares the year drop down
   */
  componentDidMount() {
    firestore
      .collection("google-classrooms")
      .orderBy("year", "desc")
      .get()
      .then((querySnapshot) => {
        this.yearWiseCourses = [];
        querySnapshot.forEach((doc) => {
          var temp = doc.data();
          this.yearWiseCourses.push(temp);
        });
        this.selectedYear = this.yearWiseCourses[0]["year"];
        this.updateYearDropdown(); //Updates dropdon
        this.loadData(); //loads data from firebase on dh
      });
  }

  /**
   * Loads courses from Firebase of selected year
   */
  loadData() {
    firestore
      .collection("g-classroom-assignment-report")
      .doc(this.selectedYear)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.exists) {
          let data = querySnapshot.data();
          this.timestamp = data["timestamp"].toDate();

          this.setState({ timestamp: this.timestamp });
          this.courses = JSON.parse(data["reportData"]);
          this.prepareTableData(); //Prepares header rows
          this.getDataRow(); //Prepares rest of data
        } else {
          //this.loadCourseDetail();//If there is no data in firebase, then display message on UI that there is no data
        }
      });
  }

  async loadCourseDetail() {
    firestore
      .collection("google-classrooms")
      .orderBy("year", "desc")
      .get()
      .then((querySnapshot) => {
        this.yearWiseCourses = [];
        querySnapshot.forEach((doc) => {
          var temp = doc.data();
          this.selectedYear = temp["year"];
          this.yearWiseCourses.push(temp);
        });
        this.updateYearDropdown();
        this.selectedYear = this.yearWiseCourses[0]["year"];
        this.fetchFreshDataFromGoogleServer();
      });
  }

  async fetchFreshDataFromGoogleServer() {
    console.log(`redirectUri: ${redirectUri}`);
    if (
      sessionStorage.getItem("refreshToken") &&
      sessionStorage.getItem("refreshToken") !== "undefined"
    ) {
      await this.retrieveAccessToken();
      this.setCourseData(this.getSelectedCourse());
    } else {
      var _url = `${OAUTHURL}redirect_uri=${redirectUri}&prompt=consent&response_type=code&client_id=${this.clientId}&scope=${scopes}&access_type=offline`;
      this.login(_url);
    }
  }

  async setCourseData(temp) {
    this.courses = {};
    // this.targetCntOfSelectedCourses = Object.keys(this.getSelectedCourse()).length-1
    this.isLoading = true;
    for (var course in temp) {
      if (course !== "year") {
        await this.updateCourse(temp[course], course);
        console.log(temp[course]["classroomId"] + " processing completed");
      }
    }
    this.isLoading = false;
    console.log("Processing complete");
    this.setState({ isLoading: false });
    if (AUTO_UPLOAD_TO_FIREBASE) {
      this.uploadReportToFirebase();
    }
  }

  async updateCourse(selectedRawCourse, course) {
    var courseId = selectedRawCourse["classroomId"];
    this.courses[courseId] = { name: course };
    this.courses[courseId]["id"] = courseId;
    this.courses[courseId]["display_name"] = selectedRawCourse["name"];
    this.courses[courseId]["sequence"] = selectedRawCourse["sequence"];
    this.courses[courseId]["students"] = await this.getCourseStudentsCount(
      courseId
    );
    if (
      this.courses[courseId] &&
      this.courses[courseId]["students"] &&
      this.courses[courseId]["students"] > 0
    ) {
      this.courses[courseId]["topics"] = await this.getTopics(courseId);
      await this.getAssignments(courseId);
    } else {
      delete this.courses[courseId];
    }
    sessionStorage.setItem("courses", JSON.stringify(this.courses));
    this.prepareTableData();
    this.getDataRow();
  }

  updateYearDropdown() {
    this.yearMenuList = [];
    this.yearWiseCourses.forEach((ele) => {
      this.yearMenuList.push(
        <option value={ele["year"]} key={ele["year"]}>
          {ele["year"]}
        </option>
      );
    });
    this.setState(this.yearMenuList);
  }

  prepareTableData() {
    this.getHeaderRow();
    this.getSubHeaderRow();
  }

  getHeaderRow() {
    this.topicsRow = [];
    this.topicsRow.push(<th>Class</th>);
    this.topicsRow.push(<th>Students</th>);
    for (var topic in validTitle) {
      this.topicsRow.push(
        <th className="text-center" colSpan="3" key={validTitle[topic]}>
          {validTitle[topic]}
        </th>
      );
    }
    /* for(var topicId in this.topics){
            this.topicsRow.push(<th className="text-center" colSpan="3" key={topicId}>{this.topics[topicId]}</th>)
        } */
    this.setState(this.topicsRow);
  }

  getSubHeaderRow() {
    this.topicsSubRow = [];
    this.topicsSubRow.push(<th key="a"></th>);
    this.topicsSubRow.push(<th key="b"></th>);
    for (var topic in validTitle) {
      this.topicsSubRow.push(<th key={topic + "_ASSIGNED"}>Assigned</th>);
      this.topicsSubRow.push(<th key={topic + "_TURNED_IN"}>Turned In</th>);
      this.topicsSubRow.push(<th key={topic + "_RETURNED"}>Returned</th>);
    }

    this.setState(this.topicsSubRow);
  }

  getDataRow() {
    this.assignmentRows = [];
    var temp = [];
    temp = this.sortCourses();
    temp.forEach((course) => {
      this.assignmentRows.push(
        <tr key={course["id"]}>
          <td>{course.display_name ? course.display_name : course.name}</td>
          <td>{course.students}</td>
          {this.getAssignmentReportCells(course.assignments, course.students)}
        </tr>
      );
    });
    this.setState(this.assignmentRows);
  }

  sortCourses() {
    var sortedCourseList = [];
    for (var courseId in this.courses) {
      sortedCourseList.push(this.courses[courseId]);
    }
    sortedCourseList.sort(function (a, b) {
      return a["sequence"] - b["sequence"];
    });
    return sortedCourseList;
  }

  getAssignmentReportCells(assignments, studCount) {
    const danger = {
      color: "white",
      backgroundColor: "red",
    };
    const caution = {
      backgroundColor: "yellow",
    };
    const normal = {};

    var cells = [];
    for (var topicName of validTitle) {
      // var topicName = this.topics[topicId];
      if (assignments && assignments[topicName]) {
        var assignment = assignments[topicName];
        var assignmentCount = assignment["count"];
        var totalAssignmentsCount = assignmentCount * studCount;
        var totalTurnedIn =
          (assignment["report"]["TURNED_IN"]
            ? assignment["report"]["TURNED_IN"]
            : 0) +
          (assignment["report"]["RETURNED"]
            ? assignment["report"]["RETURNED"]
            : 0);
        var turnedInPercent =
          totalTurnedIn && totalAssignmentsCount !== 0
            ? (totalTurnedIn * 100) / totalAssignmentsCount
            : 0;
        var returnedPercent =
          assignment["report"]["RETURNED"] && totalTurnedIn != 0
            ? (assignment["report"]["RETURNED"] * 100) / totalTurnedIn
            : 0;
        cells.push(
          <td
            style={
              assignmentCount < assignmentCautionLevel
                ? assignmentCount < assignmentDangerLevel
                  ? danger
                  : caution
                : normal
            }
          >
            {assignmentCount}
          </td>
        );
        cells.push(
          <td
            style={
              turnedInPercent > assingmentCautionPercent
                ? normal
                : turnedInPercent > assingmentDangerPercent
                ? caution
                : danger
            }
          >
            {totalTurnedIn}/{totalAssignmentsCount}
          </td>
        );
        cells.push(
          <td
            style={
              returnedPercent > assingmentCautionPercent
                ? normal
                : returnedPercent > assingmentDangerPercent
                ? caution
                : danger
            }
          >
            {assignment["report"]["RETURNED"]
              ? assignment["report"]["RETURNED"]
              : 0}
            /{totalTurnedIn}
          </td>
        );
      } else {
        cells.push(<td colSpan="3" style={{ backgroundColor: "grey" }}></td>);
      }
    }
    return cells;
  }

  async getCourseStudentsCount(courseId, cnt) {
    let self = this;
    try {
      var response = await api.get(
        `https://classroom.googleapis.com/v1/courses/${courseId}/students`,
        {
          headers: { Authorization: `Bearer ${this.accessToken}` },
          delay: cnt * delaySizeInMilliSeconds,
        }
      );
    } catch (err) {
      return 0;
    }
    return response.data.students ? response.data.students?.length : 0;
  }

  async getAssignments(courseId) {
    let self = this;
    var response = await axios.get(
      `https://classroom.googleapis.com/v1/courses/${courseId}/courseWork`,
      {
        headers: { Authorization: `Bearer ${this.accessToken}` },
        raxConfig: {
          retry: 2,
          noResponseRetries: 2,
          retryDelay: 1000,
          backoffType: "static",
          statusCodesToRetry: [
            [100, 199],
            [429, 429],
            [500, 599],
          ],
          onRetryAttempt: (err) => {
            const cfg = rax.getConfig(err);
            console.log(`Retry attempt #${cfg.currentRetryAttempt}`);
          },
        },
      }
    );
    var assignments = response.data?.courseWork;
    if (assignments) {
      assignments.forEach(async (assignment) => {
        if (
          this.topics[assignment.topicId] &&
          validTitle.includes(this.topics[assignment.topicId])
        ) {
          var response = await this.getSubmissions(
            assignment.courseId,
            assignment.id,
            assignment.topicId
          );
          var topicName = this.topics[response.topicId];
          var submissions = response?.responseData?.studentSubmissions;
          if (submissions) {
            if (this.courses[courseId]["assignments"] === undefined) {
              this.courses[courseId]["assignments"] = {};
            }
            if (
              this.courses[courseId]["assignments"][topicName] === undefined
            ) {
              this.courses[courseId]["assignments"][topicName] = {
                count: 1,
                report: {},
              };
            } else {
              this.courses[courseId]["assignments"][topicName]["count"]++;
            }
            submissions.forEach((submission) => {
              switch (submission.state) {
                case "CREATED":
                // case "NEW":
                case "RECLAIMED_BY_STUDENT":
                  if (
                    this.courses[submission.courseId]["assignments"][topicName][
                      "report"
                    ]["ASSIGNED"]
                  )
                    this.courses[submission.courseId]["assignments"][topicName][
                      "report"
                    ]["ASSIGNED"] =
                      this.courses[submission.courseId]["assignments"][
                        topicName
                      ]["report"]["ASSIGNED"] + 1;
                  else
                    this.courses[submission.courseId]["assignments"][topicName][
                      "report"
                    ]["ASSIGNED"] = 1;
                  break;
                case "TURNED_IN":
                  if (
                    this.courses[submission.courseId]["assignments"][topicName][
                      "report"
                    ]["TURNED_IN"]
                  )
                    this.courses[submission.courseId]["assignments"][topicName][
                      "report"
                    ]["TURNED_IN"] =
                      this.courses[submission.courseId]["assignments"][
                        topicName
                      ]["report"]["TURNED_IN"] + 1;
                  else
                    this.courses[submission.courseId]["assignments"][topicName][
                      "report"
                    ]["TURNED_IN"] = 1;
                  break;
                case "RETURNED":
                  if (
                    this.courses[submission.courseId]["assignments"][topicName][
                      "report"
                    ]["RETURNED"]
                  )
                    this.courses[submission.courseId]["assignments"][topicName][
                      "report"
                    ]["RETURNED"] =
                      this.courses[submission.courseId]["assignments"][
                        topicName
                      ]["report"]["RETURNED"] + 1;
                  else
                    this.courses[submission.courseId]["assignments"][topicName][
                      "report"
                    ]["RETURNED"] = 1;
                  break;
              }
              this.getDataRow();
            });
          }
        }
      });
    } else {
      this.courses[courseId]["totalAssignments"] = 0;
    }
  }

  async getTopics(courseId) {
    let self = this;
    let courseTopics = {};
    var response = await axios.get(
      `https://classroom.googleapis.com/v1/courses/${courseId}/topics`,
      {
        headers: { Authorization: `Bearer ${this.accessToken}` },
      }
    );

    response.data?.topic?.forEach((topic) => {
      if (validTitle.includes(topic.name)) {
        this.topics[topic.topicId] = topic.name;
        courseTopics[topic.topicId] = topic.name;
      }
    });
    this.prepareTableData();
    sessionStorage.setItem("topics", JSON.stringify(this.topics));
    return courseTopics;
  }

  async getSubmissions(courseId, courseWorkId, topicId) {
    let self = this;
    var response = await axios.get(
      `https://classroom.googleapis.com/v1/courses/${courseId}/courseWork/${courseWorkId}/studentSubmissions`,
      {
        headers: { Authorization: `Bearer ${this.accessToken}` },
        raxConfig: {
          retry: 2,
          noResponseRetries: 2,
          retryDelay: 1000,
          backoffType: "static",
          statusCodesToRetry: [
            [100, 199],
            [429, 429],
            [500, 599],
          ],
          onRetryAttempt: (err) => {
            const cfg = rax.getConfig(err);
            console.log(`Retry attempt #${cfg.currentRetryAttempt}`);
          },
        },
      }
    );
    return { topicId: topicId, responseData: response.data };
  }

  login(_url) {
    var win = window.open(_url, "windowname1", "width=800, height=600");

    this.pollTimer = window.setInterval(
      this.fetchAuthCode.bind(this, win),
      500
    );
  }

  fetchAuthCode(win) {
    try {
      if (win === null || win.document === undefined) {
        window.clearInterval(this.pollTimer);
      }
      console.log(`redirectUri: ${redirectUri}`);
      if (
        win.document?.URL.indexOf("about:blank") === -1 &&
        win.document?.URL.indexOf(`redirect_uri=${redirectUri}`) === -1
      ) {
        window.clearInterval(this.pollTimer);
        var url = win.document.URL;
        console.log(`url: ${url}`);
        let authCode = gup("code", url);

        win.close();

        this.retrieveTokens(authCode);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async retrieveTokens(code) {
    let response = await axios.post("https://oauth2.googleapis.com/token", {
      client_id: this.clientId,
      grant_type: "authorization_code",
      client_secret: this.clientSecret,
      code: code,
      redirectUri: redirectUri,
      scope: scopes,
    });

    if (response["data"]["refresh_token"]) {
      window.sessionStorage.setItem(
        "refreshToken",
        response["data"]["refresh_token"]
      );
      firestore
        .collection("google-classrooms")
        .orderBy("year", "desc")
        .get()
        .then((querySnapshot) => {
          this.yearWiseCourses = [];
          querySnapshot.forEach((doc) => {
            var temp = doc.data();
            this.yearWiseCourses.push(temp);
          });
          this.selectedYear = this.yearWiseCourses[0]["year"];
          this.updateYearDropdown();
          this.loadData();
        });
    } else {
      console.log("Refresh token is undefined");
    }
  }

  async retrieveAccessToken() {
    let response = await axios.post(
      "https://www.googleapis.com/oauth2/v4/token",
      {
        client_id: this.clientId,
        client_secret: this.clientSecret,
        refresh_token: window.sessionStorage.getItem("refreshToken"),
        grant_type: "refresh_token",
      }
    );
    if (response["data"]["access_token"]) {
      this.accessToken = response["data"]["access_token"];
    } else {
      console.log("Refresh token is undefined");
    }
  }

  onYearChange = (event) => {
    this.yearWiseCourses.some((ele) => {
      if (ele["year"] === event.target.value) {
        this.selectedYear = ele["year"];

        this.loadData();
        return true;
      }
    });
  };

  reloadFromServer = () => {
    this.courses = {};
    this.prepareTableData();
    this.fetchFreshDataFromGoogleServer();
  };

  fetchMissingClassroomWorks = () => {
    var selectedCourse = this.getSelectedCourse();
    for (var mCourse in selectedCourse) {
      if (mCourse && mCourse != "year") {
        var courseId = selectedCourse[mCourse]["classroomId"];
        if (
          this.courses[courseId] === undefined ||
          this.courses[courseId]["assignments"] === undefined ||
          Object.keys(this.courses[courseId]["assignments"]).length === 0
        ) {
          this.updateCourse(selectedCourse[mCourse], mCourse);
        }
      }
    }
  };

  getSelectedCourse() {
    var course;
    this.yearWiseCourses.some((ele) => {
      if (ele["year"] === this.selectedYear) {
        course = ele;
        return true;
      }
    });
    return course;
  }

  hasAssignments() {
    let assignmentsPresent = false;
    for (var courseId in this.courses) {
      if (
        this.courses[courseId]["assignments"] &&
        Object.keys(this.courses[courseId]["assignments"]).length > 0
      ) {
        return true;
      }
    }
    return false;
  }

  uploadReportToFirebase = () => {
    let currentTime = new Date();
    firestore
      .collection("g-classroom-assignment-report")
      .doc(this.selectedYear)
      .set({
        timestamp: currentTime,
        reportData: JSON.stringify(this.courses),
      })
      .then((result) => {
        this.timestamp = currentTime;
        console.log("Document successfully written!");
      })
      .catch((error) => {
        console.log("Writing to firestore failed!");
      });
  };

  render() {
    let reportMessage = "";
    if (!this.hasAssignments()) {
      reportMessage = "No data present in firebase Fetch from google.";
    }
    return (
      <div style={{ position: "inherit" }}>
        <div className="card p-1 mb-1">
          <div className="justify-content-center d-flex">
            <h2>Classroom Assignments</h2>
          </div>
          <div>
            <select onChange={this.onYearChange} disabled={this.isLoading}>
              {this.yearMenuList}
            </select>
            {
              <>
                <button
                  onClick={this.reloadFromServer}
                  disabled={this.isLoading}
                >
                  Refresh
                </button>
                <button
                  onClick={this.fetchMissingClassroomWorks}
                  disabled={this.isLoading}
                >
                  Fetch missing data
                </button>
                <button
                  onClick={this.uploadReportToFirebase}
                  disabled={this.isLoading}
                >
                  Save report to firebase
                </button>
              </>
            }
            <span style={{ float: "right", fontWeight: "bold" }}>
              Timestamp -{" "}
              {this.timestamp ? this.timestamp.toLocaleString() : ""}
            </span>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>{this.topicsRow}</tr>
                <tr>{this.topicsSubRow}</tr>
              </thead>
              <tbody>{this.assignmentRows}</tbody>
            </table>
            <h3>{reportMessage}</h3>
            {this.isLoading && (
              <div class="text-center">
                <div class="spinner-grow" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div>Loading...</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Classroom;
