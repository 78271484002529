import React, { memo, useState, useEffect } from "react";

import firebase, { firestore } from "../firebase/config";
import "firebase/auth";
import { useSession } from "../firebase/UserProvider";
import { createUserDocument } from "../firebase/Users";

import ParentPortalHomePage from "./parent-portal/ParentPortalHomePage";
export default memo(function Login(props) {
  const [invalidEmail, setInvalidEmail] = useState(false);
  const { user } = useSession();
  const [showMaintenenceMessage, setShowMaintenenceMessage] = useState(false);
  const [maintenenceMessage, setMaintenenceMessage] = useState("");

  const signIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    provider.setCustomParameters({ hd: "bmspune.org" });

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        var loggedInUser = result.user;
        console.log("user email");
        console.log(loggedInUser);
        const userEmail = loggedInUser.email;
        if (userEmail.split("@")[1] !== "bmspune.org") {
          setInvalidEmail(true);
          loggedInUser.delete();
        } else {
          createUserDocument(loggedInUser);
          props.history.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    firestore
      .collection("system-preferences")
      .where("name", "==", "showMaintenanceMessage")
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          let value = snapshot.docs[0].data().value;
          let message = snapshot.docs[0].data().message;

          setShowMaintenenceMessage(value);
          setMaintenenceMessage(message);
        }
      });
    if (user) {
      props.history.push("/home");
    }
  }, [user, props.history]);
  return (
    <>
      {showMaintenenceMessage ? (
        <>
          <h2>
            <div className="text-danger">{maintenenceMessage}</div>
          </h2>
        </>
      ) : (
        <div id="login">
          {
            // !window.location.href.match(int) ? (
            //For deployment keep <
            window.location.href.indexOf("int") < 0 ? (
              <>
                <ParentPortalHomePage />
              </>
            ) : (
              <>
                <h2 className="text-center text-blue pt-5">
                  Welcome to BMS Intranet
                </h2>

                <div
                  id="login-row"
                  className="row justify-content-center d-flex"
                >
                  {!user ? (
                    <div
                      id="login-box"
                      className="col-md-12 justify-content-center d-flex"
                    >
                      <div>
                        <button
                          className="btn btn-primary text-center"
                          onClick={signIn}
                        >
                          Sign in with bmspune.org account
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      You have already logged in as {user.displayName} with
                      email as {user.email}
                    </div>
                  )}

                  {invalidEmail && <div>The email id is not allowed</div>}
                </div>
              </>
            )
          }
        </div>
      )}
    </>
  );
});
