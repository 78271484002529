import React, { memo } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import LogoHeader from "./LogoHeader";
import ReceiptMainBody from "./ReceiptMainBody";
import { DonationContextProvider } from "./DonationContextProvider";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  topSection: {
    marginTop: 5,
    marginBottom: 1,
  },
  bottomSection: {
    marginTop: 1,
    marginBottom: 5,
  },
  section: {
    marginLeft: 5,
    marginRight: 5,
    padding: 2,
    border: "1px solid #1E79F4",
    textAlign: "center",
  },

  centralSectionDivider: {
    textAlign: "center",
  },
});
export default memo(function DonationReceipt(props) {
  const donation = props.donation;
  const donorDetails = props.donor;
  const user = props.user;

  return (
    <DonationContextProvider
      donation={donation}
      donorDetails={donorDetails}
      user={user}
    >
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={[styles.section, styles.topSection]}>
            <LogoHeader />
            <ReceiptMainBody />
          </View>

          <Text style={styles.centralSectionDivider}>
            --------------------------------------------------------------------------------------------
          </Text>
          <View style={[styles.section, styles.bottomSection]}>
            <LogoHeader />
            <ReceiptMainBody />
          </View>
        </Page>
      </Document>
    </DonationContextProvider>
  );
});
