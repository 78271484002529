import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { firestore } from "../../firebase/config";
import { useSession } from "../../firebase/UserProvider";
import * as AiReactIcon from "react-icons/ai";

function CreateTimeSlots() {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [appointmentType, setappointmentType] = useState("Academic"); //Store donor ref's id
  const [statusType, setStatusType] = useState("Vacant"); //Store donor ref's id
  const [sdate, setsdate] = useState(new Date());
  const [isLoading, setisLoading] = useState(false);
  const status =false;
  const history = useHistory();
  const { user } = useSession();
  


const [queryResult, setQueryResult] = useState(null);
  
  
  // const handleUserSearch = async (e) => {
  //     e.preventDefault();

  //     const query = firestore
  //       .collection(`timeSlots`)
  //       .where("AppointmentStatus", ">=", "Vacant");
  //     await query.get({ source: "server" }).then((QuerySnapshot) => {
  //       let userList = [];
  //       QuerySnapshot.forEach((user) => {
  //         userList.push({
  //           email: user.data().email,
  //           name: user.data().uid,


  //         });
  //       }); //End of QuerySnapshot.forEach
  //       setQueryResult(userList);
  //     });
  //   }


  
  const addTimeSlot = async (timeSlotData) => {
    /*
     there can be restrictions for now there are none .
     */
     var e1 = document.getElementById("dropdown");
     var standard = e1.value;
    setisLoading(true);
     

          await firestore
            .collection("timeSlots")
            .add({
              staffAllocatedName: timeSlotData.name.toUpperCase(),
              startDateTime: new Date(timeSlotData.startdatetime),
              endDateTime: new Date(timeSlotData.enddatetime),

              staffAllocatedType: appointmentType,
              updatedBy: user.email,
              updatedAt: new Date(),
              createdBy: user.email,
              createdAt: new Date(),
              parentId:"NA",
              type:statusType,
              studentId:"NA",
              class: standard,
              staffAllocatedEmail:timeSlotData.email.toUpperCase(),
            })
            .then((docRef) => {
              //Id donor record is addded successfully then
              //show the success message and
              //navigate the user to the new donor record
              alert("The record has been added successfully");
              setisLoading(false);
              history.push(`/home`);
            });
         
      
    setisLoading(false);
  }; //End of addDonorDetails

// function to handle the teacher management radio button 
  
  const handleCollectedByOptions = (e) => {
    
    e.target.value === "Academic" ? setappointmentType("Academic") : setappointmentType("Management");
    // donorRefError === true ? setDonorRefError(false) : " ";
    setappointmentType(e.target.value);
      
  }; 
  const handleCollectedByOptions1 = (e) => {
    
    e.target.value === "Vacant" ? setStatusType("Vacant") : setStatusType("Booked");
    // donorRefError === true ? setDonorRefError(false) : " ";
    setStatusType(e.target.value);
      
  }; 
// no past date for start date 
  const checkDate = (value) => {
    //If date value is in the past then return false else true.
     // save the start date as we need to use it later
    let dValue = new Date(value); // date entered by the user
    setsdate(dValue);
    let today = new Date();
    today.setFullYear(today.getFullYear()); //Get date 18 years ago

    if (dValue.getTime() > today.getTime()) // the time value should not be before today
    {
      return true;
    } else 
    {
      alert("cannot set appointment date in the past");
      return false;
    }
  };

// end date has to be minimum 30 minutes after start date and on the same day.
  const checkDateEnd = (value) => {
    //If date value is in the past then return false else true.

    let dValue = new Date(value); // date entered by the user as endate
    
    let newDateObj = new Date(sdate.getTime() + 30*60000); // offset by 30 mins for min duration of 30 mins
    let newDateObj1 = new Date(sdate.getTime() + 300*60000); // offset by 5 hours for max duration of 5 hours

    if ((dValue.getTime() > newDateObj.getTime() )&& (dValue.getTime() < newDateObj1.getTime()) ) {
      return true;
    } else {
      alert("appointment duration has to be between 30 mins to 5 hours ");
      return false;
    }
  };

  const getInitialState = (checked) => {
    return {checked: true}
    };

  const handleCheck = (checked) => {
   
    this.setState({checked: !getInitialState});

  };


  const formClass = `${isLoading ? "ui form loading" : ""}`;
  return (
    <div>
      <div className="card p-1 mb-1">
        <div className="justify-content-center d-flex">
          <h2>Create a new time slot </h2>
        </div>
      </div>

      <div className="card p-1">
        <form className={formClass} onSubmit={handleSubmit(addTimeSlot)}>
          <label className="d-flex">
            <p className="text-danger">Fields marked * are mandatory</p>
          </label>
          <label className="d-flex">
            <h4 className="p-2">create meeting slots :</h4>
          </label>


{ /* name of the staff who is going to take the meeting  */ }
          <div className="row">
            <div className="col-md-6">
              <label className="m-2">
                Staff Name:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                placeholder="Please enter the teacher/management name who is responsible for attending to the parent"
                className="form-control ml-2"
                id="name"
                name="name"
                {...register("name", {
                  required: "Please enter teacher/management name",
                  minLength: {
                    value: 3,
                    message: "Please enter at least 3 characters for name",
                  },
                })}
              />
              {errors.name?.message && (
                <p className="text-danger">{errors.name?.message}</p>
              )}
            </div>
            
          </div>
          
{ /* email of the staff who is going to take the meeting  */ }

          <div className="row mb-3">
            <div className="col-md-6">
              <label className="m-2">Email:</label>
              <input
                placeholder="Please enter email of the staff member responsible for attending to the parent"
                type="email"
                className="form-control ml-2"
                id="email"
                name="email"
                {...register("email")}
              />
            </div>
            
          </div>

{ /* start time  */ }


          <div className="row mb-3">                      
            <div className="col-md-6">
              <label className="m-2">
                Slot Start Time:<span className="text-danger">*</span>
              </label>
              <input
                type="datetime-local"
                className="form-control ml-2"
                name="startdatetime"
                {...register("startdatetime", {
                  required: "Please enter date and start time of the slot ",
                  validate: { validDate: (value) => checkDate(value) },
                })}
              />
              {errors.dob?.message && (
                <p className="text-danger">{errors.dob?.message}</p>
              )}
              {errors.dob?.type === "validDate" && (
                <p className="text-danger">
                  The date selected should not be in the past or today.
                </p>
              )}
            </div>

{ /* end time  */ }

            <div className="col-md-6">
              <label className="m-2">
                Slot End Time:<span className="text-danger">*</span>
              </label>
              <input
                type="datetime-local"
                className="form-control ml-2"
                name="enddatetime"
                {...register("enddatetime", {
                  required: "Please enter date and start time of the slot ",
                  validate: { validDate: (value) => checkDateEnd(value) },
                })}
              />
              {errors.dob?.message && (
                <p className="text-danger">{errors.dob?.message}</p>
              )}
              {errors.dob?.type === "validDate" && (
                <p className="text-danger">
                  The date selected should not be in the past or today.
                </p>
              )}
            </div>
          </div>
 { /* type of appointment radio button   */ }

          <div className="row mb-3">
            <div className="col">
              <label className="m-2">
                Type of appointment:<span className="text-danger">*</span>
              </label>
              <br />
              <input
                type="radio"
                id="typeAcademic"
                name="TimeSlot"
                value="Academic"
                checked={appointmentType === "Academic"}
                onChange={handleCollectedByOptions}
              />{" "}
              <label htmlFor="typeAcademic">Academic</label>
              <br />
              <input
                type="radio"
                id="typeManagement"
                name="TimeSlot"
                value="Management"
                checked={appointmentType === "Management"}
                onChange={handleCollectedByOptions}
              />{" "}
              <label htmlFor="typeManagement">Management</label>
            </div>

          </div>
          
{ /*  radio button with search to book a slot   */ }

{/* 
          <div className="row mb-3">
            <div className="col">
              <label className="m-2">
                Status  of appointment:<span className="text-danger">*</span>
              </label>
              <br />
              <input
                type="radio"
                id="typeVacant"
                name="Status"
                value="Vacant"
                checked={statusType === "Vacant"}
                onChange={handleCollectedByOptions1}
              />{" "}
              <label htmlFor="typeVacant">Vacant</label>
              <br />
              <input
                type="radio"
                id="typeBooked"
                name="Status"
                value="Booked"
                checked={statusType === "Booked"}
                onChange={handleCollectedByOptions1}
              />{" "}
              <label htmlFor="typeManagement">Booked</label>
            </div>

          </div> */}
{ /* class of the staff/academic evaluation slot  */ }

          <div className="row mb-6 ">
            <div className="col">
               <label className="m-2"> Class/Standard : <span className="text-danger">*</span>
               </label>
               <br />
               <label htmlFor="dropdown">select child's current class       :</label>

               <select id = "dropdown">
                  <option value="kg1">kg1</option>
                  <option value="kg2">kg2</option>
                  <option value="I">1</option>
                  <option value="II">2</option>
                  <option value="III">3</option>
                  <option value="IV">4</option>
                  <option value="V">5</option>
                  <option value="VI">6</option>
                  <option value="VII">7</option>
                  <option value="VIII">8</option>
                  <option value="IX">9</option>
                  <option value="X">10</option>

                </select>

            </div>
          </div>

{ /* button  */ }

          <div className="row mb-3 ">
            <div className="col justify-content-center d-flex">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary ml-2"
                onClick={(e) => {
                  this.context.history.push('/home');
                }}
              >
                Cancel
              </button>
            </div>
          </div>

          

        </form>
      </div>
    </div>
  );}        
export default CreateTimeSlots;