//This is admissionpanel/:studentid


import { firestore } from "../../firebase/config";
import React, { memo, useEffect, useState, Fragment } from "react";
import { useSession } from "../../firebase/UserProvider";
import { useForm } from "react-hook-form";

import {useHistory, useParams,Link} from "react-router-dom";
import {
    CountryDropdown,
    RegionDropdown,
  } from "react-country-region-selector";


function AdmissionDashboard(){
    const {
        register,
        unregister,
        setValue,
        handleSubmit,   
        formState: { errors },
      } = useForm(); 

    const [admissionToClass, setAdmissionToClass] = useState("notSelected"); 
    const {user} = useSession();
    const [dataRecieved, setDataRecieved] = useState("");
    const history = useHistory();
    const [country, setCountry] = useState("");
    const [region, setRegion] = useState("");
    const [declaration, setDeclaration] = useState(false);
    let parentId;
    const [dataAdmTimeslot, setdataAdmTimeslot] = useState([]);
    const [selectedTimeslotAcademic, setSelectedTimeslotAcademic] = useState(false);
    const params = useParams();
    useEffect(async() => {
       
        await firestore
            .collection("students")
            .where("studentId", "==", params.studentId)
            .get({source: "server"})
            .then(async (QuerySnapshot) => {
                QuerySnapshot.forEach((doc) => {
                
                setDataRecieved(doc.data());
                
                });
            });

    });
    useEffect(async() => {
       
        await firestore
            .collection("timeSlots")
            .where("AppointmentStatus", "==", "Vacant")
            .where("staffAllocatedType","==","Teacher")
            .get({source: "server"})
            .then(async (QuerySnapshot) => {
                QuerySnapshot.forEach((doc) => {
                
                setdataAdmTimeslot(prevState => [...prevState, doc.data()]);
                });
            });

    }, []);
    const admissionStepSpecifier =() => {
        switch(dataRecieved.admissionStep) {
            case 0 : return "Update Student Data";
            case 1 : return "Select time-slot for academic evaluation";
            case 2 : return "Select time-slot for managerial evaluation";
            case 3 : return "Upload Student Documents";
            case 888 : return "Admission Accepted";
            case 999 : return "Admission Rejected";
            default : return "Something is wrong!";
        }
    };

 

    const updateStudentData = async(e) => {
        console.log(e);
        if(declaration == true){
            await firestore.collection("students")
            .doc(params.studentId)
            .set({
                birthPlace:e.birthPlace, religion:e.religion, caste:e.caste, nationality:e.nationality,
                lastSchool:e.lastSchool, contactLandline:e.contactLandline, motherMobileNo:e.motherMobileNo,
                fatherMobileNo:e.fatherMobileNo, officeContactNo:e.officeContactNo, motherEmail:e.motherEmail,
                fatherEmail:e.fatherEmail,
                address: {
                    addressLine1:e.addressLine1, addressLine2:e.addressLine2, city:e.city, pinCode:e.pin,
                    country:country, state:region,
                },
                detailedInfo: {
                    nickName:e.nickName, bloodGroup:e.bloodGroup, allergy:e.allergy, commonAilments:e.commonAilments,
                    careGiver:e.careGiver, languagesStudent:e.languagesStudent, languagesFather:e.languagesFather,
                    languagesMother:e.languagesMother, languagesCareGiver:e.languagesCareGiver, favFood:e.favFood, 
                    favToysAndActivities:e.favToysAndActivities, upsetBehaviour:e.upsetBehaviour, 
                    jointOrNuclearFam:e.jointOrNuclearFam, newPlaygroupSituation:e.newPlaygroupSituation,
                    tvInfo:e.tvInfo, playTime:e.playTime, extracurricularActivities:e.extracurricularActivities,
                    motherRoutine:e.motherRoutine, studentRoutine:e.studentRoutine,
                },
                spiritualQuotientAnalysis : {
                    spiritualFoundation:e.spiritualFoundation, spiritualPractice:e.spiritualPractice,
                    spiritualGuide:e.spiritualGuide, spiritualProgram:e.spiritualProgram,
                    familyPrayerInfo:e.familyPrayerInfo,
                },
                admissionToClass:admissionToClass,
                updatedAt: new Date(),
                updatedBy: user.email,
                selfDeclaration: declaration,
                admissionStep: 1,
                studentDob:dataRecieved.studentDob,
                studentName: dataRecieved.studentName,
                studentId: dataRecieved.studentId,
            })
            .then(() => {
                alert("The record has been added successfully");
                window.location.reload(false);
            });
        }
        else {
            alert("Please check the self declaration box");
        }
        
    };

    const displayUpdateStudentData = () => {
        return (<div className="card p-2 mb-1">
        <label className="d-flex">
        <h4 className="p-2 text-danger">*Please fill up the form below for {dataRecieved.studentName}</h4>    
      </label>

        <form onSubmit={handleSubmit(updateStudentData)}>
            
            <div className="row mb-3">
                <div className="col-md-6">
                    <label className="m-2">Admission Sought for Class<span className="text-danger">*</span></label>
                    <select value = {admissionToClass} onChange={(e)=> setAdmissionToClass(e.target.value)}>
                        <option value="notSelected">--select option--</option>
                        <option value="I">I</option>
                        <option value="II">II</option>
                        <option value="III">III</option>
                        <option value="IV">IV</option>
                    </select>
                </div>
            </div>
            <label className="d-flex">
                <h4 className="p-2">Part I : <span className="font-italic">Basic Information</span></h4>    
            </label> 
            <div className = "row mb-3">
                <div className="col-md-6">
                    <label className="m-2">Place of birth:<span className = "text-danger">*</span></label>
                    <input
                        type="text"
                        id="birthPlace"
                        name="birthPlace"
                        className = "form-control ml-2 mr-4"
                        {...register("birthPlace", {
                            required: "Please enter Birth Place",
                        })}
                        // value = {studentFields.birthPlace}
                        // onChange = {handleStudentFieldsInputChange}

                    />
                </div>
                <div className="col-md-6">
                    <label className="m-2">Religion:<span className = "text-danger">*</span></label>
                    <input
                        type="text"
                        id="religion"
                        name="religion"
                        className = "form-control ml-2 mr-4"
                        {...register("religion", {
                            required: "Please enter religion",
                        })}
                        // value = {studentFields.religion}
                        // onChange = {handleStudentFieldsInputChange}

                    />
                </div>
            </div>
            <div className = "row mb-3">
                <div className = "col-md-6">
                    <label className="m-2">Caste:<span className = "text-danger">*</span></label>
                    <input
                        type="text"
                        id="caste"
                        name="caste"
                        className = "form-control ml-2 mr-4"
                        {...register("caste", {
                            required: "Please enter caste",
                        })}
                        // value = {studentFields.caste}
                        // onChange = {handleStudentFieldsInputChange}

                    />
                </div>
                <div className = "col-md-6">
                    <label className="m-2">Nationality:<span className = "text-danger">*</span></label>
                    <input
                        type="text"
                        id="nationality"
                        name="nationality"
                        className = "form-control ml-2 mr-4"
                        {...register("nationality", {
                            required: "Please enter nationality",
                        })}
                        // value = {studentFields.nationality}
                        // onChange = {handleStudentFieldsInputChange}

                    />
                </div>
            </div>
            <div className = "row mb-3">
                <div className = "col-md-6">
                    <label className="m-2">Last School Attended:<span className = "text-danger">*</span></label>
                    <input
                        type="text"
                        id="lastSchool"
                        name="lastSchool"
                        className = "form-control ml-2 mr-4"
                        {...register("lastSchool", {
                            required: "Please enter last attended school",
                        })}
                        // value = {studentFields.lastSchool}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
                <div className = "col-md-6">
                    <label className="m-2">
                        Contact No:<span className = "text-danger">*</span>
                        <span className="text-secondary"> (Residence Landline)</span></label>
                    <input
                        type="tel"
                        id="contactLandline"
                        name="contactLandline"
                        className = "form-control ml-2 mr-4" 
                        {...register("contactLandline", {
                            required: "Please enter Birth Place",
                        })}                 
                        // value = {studentFields.contactLandline}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className = "row mb-3">
                <div className = "col-md-6">
                    <label className="m-2">
                        Mobile:<span className = "text-danger">*</span>
                        <span className="text-secondary"> (Mother)</span></label>
                    <input
                        type="tel"
                        id="motherMobileNo"
                        name="motherMobileNo"
                        className = "form-control ml-2 mr-4"
                        {...register("motherMobileNo", {
                            required: "Please enter mother's mobile number",
                        })}
                        // value = {studentFields.motherMobileNo}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
                <div className = "col-md-6">
                    <label className="m-2">
                        Mobile:<span className = "text-danger">*</span>
                        <span className="text-secondary"> (Father)</span></label>
                    <input
                        type="tel"
                        id="fatherMobileNo"
                        name="fatherMobileNo"
                        className = "form-control ml-2 mr-4"  
                        {...register("fatherMobileNo", {
                            required: "Please enter father's mobile number",
                        })}                
                        // value = {studentFields.fatherMobileNo}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className = "row mb-3">
                <div className = "col-md-6">
                    <label className="m-2">
                        Office Contact No:<span className = "text-danger">*</span></label>
                    <input
                        type="tel"
                        id="officeContactNo"
                        name="officeContactNo"
                        className = "form-control ml-2 mr-4"
                        {...register("officeContactNo", {
                            required: "Please enter contact number of office",
                        })}
                        // value = {studentFields.officeContactNo}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
                <div className = "col-md-6">
                    <label className="m-2"> 
                        Mother's Email ID:<span className = "text-danger">*</span></label>
                    <input
                        type="email"
                        id="motherEmail"
                        name="motherEmail"
                        className = "form-control ml-2 mr-4"     
                        {...register("motherEmail", {
                            required: "Please enter email address of mother",
                        })}             
                        // value = {studentFields.motherEmail}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className = "row mb-3">
                <div className = "col-md-6">
                    <label className="m-2">
                        Father's Email ID:<span className = "text-danger">*</span></label>
                    <input
                        type="email"
                        id="fatherEmail"
                        name="fatherEmail"
                        className = "form-control ml-2 mr-4"
                        {...register("fatherEmail", {
                            required: "Please enter email address of father",
                        })}
                        // value = {studentFields.fatherEmail}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
                
            </div>
            <label className="d-flex">
                <h4 className="p-2">Home Address: </h4>    
            </label> 
            <div className="row mb-3">
                <div className="col-md-6">
                    <label className="m-2">
                        Address Line 1:<span className = "text-danger">*</span></label>
                    <input
                        type="text"
                        id="addressLine1"
                        name="addressLine1"
                        className = "form-control ml-2 mr-4"
                        {...register("addressLine1", {
                            required: "Please enter address line 1",
                        })}
                        // value = {studentFields.addressLine1}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
                <div className="col-md-6">
                    <label className="m-2">
                        Address Line 2:<span className = "text-danger">*</span></label>
                    <input
                        type="text"
                        id="addressLine2"
                        name="addressLine2"
                        className = "form-control ml-2 mr-4"
                        {...register("addressLine2", {
                            required: "Please enter address line 2",
                        })}
                        // value = {studentFields.addressLine2}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    <label className="m-2">
                        City:<span className = "text-danger">*</span></label>
                    <br />
                    <input
                        type="text"
                        id="city"
                        name="city"
                        className = "form-control ml-2 mr-4"
                        {...register("city", {
                            required: "Please enter city",
                        })}
                        // value = {studentFields.city}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
                <div className="col-md-6">
                    <label className="m-2">
                        Pin Code:<span className = "text-danger">*</span></label>
                    <br />
                    <input
                        type="number"
                        id="pinCode"
                        name="pinCode"
                        className = "form-control ml-2 mr-4"
                        {...register("pin", {
                            required: "Please enter Pin code",
                            minLength: {
                              value: 6,
                              message: "Pin code should be at least 6 digits",
                            },
                            maxLength: {
                              value: 6,
                              message: "Pin code should not be more than 6 digits",
                            },
                          })}
                        // value = {studentFields.pinCode}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    <label className="m-2">Country:<span className = "text-danger">*</span></label>
                    <br />
                    <CountryDropdown
                        defaultOptionLabel="Select a country"
                        value={country}
                        required
                        onChange={(val) => setCountry(val)}
                        className="form-control ml-2 mr-4"
                    />
                </div>
                <div className="col-md-6">
                    <label className="m-2">
                        State:<span className="text-danger">*</span>
                    </label>
                    <br />
                    <RegionDropdown
                        country={country}
                        required
                        blankOptionLabel="No country selected"
                        defaultOptionLabel="Select a state/region"
                        value={region}
                        onChange={(val) => setRegion(val)}
                        className="form-control ml-2"
                    />
                </div>
            </div>
            <br />
            <label className="d-flex">
                <h4 className="p-2">Part II : <span className="font-italic">Detailed Information of {dataRecieved.studentName}</span></h4>    
            </label> 
            <div className="row mb-3">
                <div className="col-md-6">
                    <label className="m-2">
                        Nickname:<span className = "text-secondary"> (If any)</span>    
                    </label>
                    <input
                        type="text"
                        id="nickName"
                        name="nickName"
                        className = "form-control ml-2 mr-4"
                        {...register("nickName", {
                            required: "Please enter nickname",
                        })}
                        // value = {studentFields.detailedInfo.nickName}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
                <div className="col-md-6">
                    <label className="m-2">
                        Blood Group:<span className = "text-danger">*</span>    
                    </label>
                    <input
                        type="text"
                        id="bloodGroup"
                        name="bloodGroup"
                        className = "form-control ml-2 mr-4"
                        {...register("bloodGroup", {
                            required: "Please enter blood group",
                        })}
                        // value = {studentFields.detailedInfo.bloodGroup}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    <label className="m-2">
                        Allergy:<span className = "text-secondary"> (If any)</span>    
                    </label>
                    <input
                        type="text"
                        id="allergy"
                        name="allergy"
                        className = "form-control ml-2 mr-4"
                        {...register("allergy", {
                            required: "Please enter allergy",
                        })}
                        // value = {studentFields.detailedInfo.allergy}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
                <div className="col-md-6">
                    <label className="m-2">
                        Common Ailments:<span className = "text-danger">*</span>    
                    </label>
                    <textarea
                        
                        id="commonAilments"
                        name="commonAilments"
                        className = "form-control ml-2 mr-4"
                        {...register("commonAilments", {
                            required: "Please enter common ailments",
                        })}
                        // value = {studentFields.detailedInfo.commonAilments}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    <label className="m-2">
                        Principal Care Giver:<span className = "text-danger">*</span>
                        <span className = "text-secondary"> (Father/Mother/Other-Please Specify)</span>    
                    </label>
                    <input
                        type="text"
                        id="careGiver"
                        name="careGiver"
                        className = "form-control ml-2 mr-4"
                        {...register("careGiver", {
                            required: "Please enter principal care giver",
                        })}
                        // value = {studentFields.detailedInfo.careGiver}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
                <div className="col-md-6">
                    <label className="m-2">
                        Languages Spoken:<span className = "text-danger">*</span> 
                        <span className = "text-secondary"> (by {dataRecieved.studentName})</span>   
                    </label>
                    <input
                        type="text"
                        id="languagesStudent"
                        name="languagesStudent"
                        className = "form-control ml-2 mr-4"
                        {...register("languagesStudent", {
                            required: "Please enter the languages spoken by student",
                        })}
                        // value = {studentFields.detailedInfo.languagesStudent}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    <label className="m-2">
                        Languages Spoken:<span className = "text-danger">*</span> 
                        <span className = "text-secondary"> (by Father)</span>   
                    </label>
                    <input
                        type="text"
                        id="languagesFather"
                        name="languagesFather"
                        className = "form-control ml-2 mr-4"
                        {...register("languagesFather", {
                            required: "Please enter the languages spoken by father",
                        })}
                        // value = {studentFields.detailedInfo.languagesFather}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
                <div className="col-md-6">
                    <label className="m-2">
                        Languages Spoken:<span className = "text-danger">*</span> 
                        <span className = "text-secondary"> (by Mother)</span>   
                    </label>
                    <input
                        type="text"
                        id="languagesMother"
                        name="languagesMother"
                        className = "form-control ml-2 mr-4"
                        {...register("languagesMother", {
                            required: "Please enter the languages spoken by mother",
                        })}
                        // value = {studentFields.detailedInfo.languagesMother}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    <label className="m-2">
                        Languages Spoken:<span className = "text-danger">*</span> 
                        <span className = "text-secondary"> (by Care giver)</span>   
                    </label>
                    <input
                        type="text"
                        id="languagesCareGiver"
                        name="languagesCareGiver"
                        className = "form-control ml-2 mr-4"
                        {...register("languagesCareGiver", {
                            required: "Please enter the languages spoken by care giver",
                        })}
                        // value = {studentFields.detailedInfo.languagesCareGiver}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
                
            </div>
            <br />
            <label className="text-danger font-italic">*Please give as much details as possible for following questions.</label>
            <div className="row mb-3">
                <div className="col-md-7">
                    <label className="m-2">
                        What are {dataRecieved.studentName}'s favourite foods?<span className = "text-danger">*</span> 
                    </label>
                    <textarea
                        type="text"
                        id="favFood"
                        name="favFood"
                        className = "form-control ml-2 mr-4"
                        {...register("favFood", {
                            required: "Please enter favourite foods of student",
                        })}
                        // value = {studentFields.detailedInfo.favFood}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-7">
                    <label className="m-2">
                        What are {dataRecieved.studentName}'s favourite toys and activities?<span className = "text-danger">*</span> 
                    </label>
                    <textarea
                        type="text"
                        id="favToysAndActivities"
                        name="favToysAndActivities"
                        className = "form-control ml-2 mr-4"
                        {...register("favToysAndActivities", {
                            required: "Please enter favourite Toys and Activities of student",
                        })}
                        // value = {studentFields.detailedInfo.favToysAndActivities}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
                
            </div>
            <div className="row mb-3">
                <div className="col-md-7">
                    <label className="m-2">
                        How does {dataRecieved.studentName} react when upset? What helps reassure him/her?<span className = "text-danger">*</span> 
                    </label>
                    <textarea
                        type="text"
                        id="upsetBehaviour"
                        name="upsetBehaviour"
                        className = "form-control ml-2 mr-4"
                        {...register("upsetBehaviour", {
                            required: "Please answer the question",
                        })}
                        // value = {studentFields.detailedInfo.upsetBehavior}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-7">
                    <label className="m-2">
                        Is {dataRecieved.studentName} staying in a joint family or a nuclear family?<span className = "text-danger">*</span> 
                    </label>
                    <textarea
                        type="text"
                        id="jointOrNuclearFam"
                        name="jointOrNuclearFam"
                        className = "form-control ml-2 mr-4"
                        {...register("jointOrNuclearFam", {
                            required: "Please answer the question",
                        })}
                        // value = {studentFields.jointOrNuclearFam}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
                
            </div>
            <div className="row mb-3">
                <div className="col-md-7">
                    <label className="m-2">
                        How do you anticipate {dataRecieved.studentName} will react to the new playgroup situation? 
                        Do you have any general or specific concern?<span className = "text-danger">*</span> 
                    </label>
                    <textarea
                        type="text"
                        id="newPlaygroupSituation"
                        name="newPlaygroupSituation"
                        className = "form-control ml-2 mr-4"
                        {...register("newPlaygroupSituation", {
                            required: "Please answer the question",
                        })}
                        // value = {studentFields.upsetBehavior}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            
            </div>
            <div className="row mb-3">
                <div className="col-md-7">
                    <label className="m-2">
                        Do you have a TV in the house? If Yes, please specify the location of the TV set.<span className = "text-danger">*</span> 
                    </label>
                    <textarea
                        type="text"
                        id="tvInfo"
                        name="tvInfo"
                        className = "form-control ml-2 mr-4"
                        {...register("tvInfo", {
                            required: "Please answer the question",
                        })}
                        // value = {studentFields.tvInfo}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-7">
                    <label className="m-2">
                        What time does {dataRecieved.studentName} go out to play?<span className = "text-danger">*</span> 
                    </label>
                    <textarea
                        type="text"
                        id="playTime"
                        name="playTime"
                        className = "form-control ml-2 mr-4"
                        {...register("playTime", {
                            required: "Please answer the question",
                        })}
                        // value = {studentFields.playTime}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-7">
                    <label className="m-2">
                       Please specify extracurricular activities attended by {dataRecieved.studentName}.<span className = "text-danger">*</span> 
                    </label>
                    <textarea
                        type="text"
                        id="extracurricularActivities"
                        name="extracurricularActivities"
                        className = "form-control ml-2 mr-4"
                        {...register("extracurricularActivities", {
                            required: "Please answer the question",
                        })}
                        // value = {studentFields.extracurricularActivities}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-7">
                    <label className="m-2">
                       Please specify Mother's daily routine.<span className = "text-danger">*</span> 
                    </label>
                    <textarea
                        type="text"
                        id="motherRoutine"
                        name="motherRoutine"
                        className = "form-control ml-2 mr-4"
                        {...register("motherRoutine", {
                            required: "Please answer the question",
                        })} 
                        // value = {studentFields.motherRoutine}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-7">
                    <label className="m-2">
                       Please specify {dataRecieved.studentName}'s daily routine.<span className = "text-danger">*</span> 
                    </label>
                    <textarea
                        type="text"
                        id="studentRoutine"
                        name="studentRoutine"
                        className = "form-control ml-2 mr-4"
                        {...register("studentRoutine", {
                            required: "Please answer the question",
                        })} 
                        // value = {studentFields.studentRoutine}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>

            <br />

            <label className="d-flex">
                <h4 className="p-2">Part III : <span className="font-italic">Spiritual Quotient Analysis</span></h4>    
            </label> 
            <div className="row mb-3">
                <div className="col-md-7">
                    <label className="m-2">
                       Member of any spiritual foundation? If Yes, please mention name.<span className = "text-danger">*</span> 
                    </label>
                    <textarea
                        type="text"
                        id="spiritualFoundation"
                        name="spiritualFoundation"
                        className = "form-control ml-2 mr-4"
                        {...register("spiritualFoundation", {
                            required: "Please answer the question",
                        })}
                        // value = {studentFields.spiritualFoundation}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-7">
                    <label className="m-2">
                       Spiritual practice at home.<span className = "text-danger">*</span> 
                    </label>
                    <textarea
                        type="text"
                        id="spiritualPractice"
                        name="spiritualPractice"
                        className = "form-control ml-2 mr-4"
                        {...register("spiritualPractice", {
                            required: "Please answer the question",
                        })}
                        // value = {studentFields.spiritualPractice}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-7">
                    <label className="m-2">
                       Who is your spiritual guide and instructor?<span className = "text-danger">*</span> 
                    </label>
                    <textarea
                        type="text"
                        id="spiritualGuide"
                        name="spiritualGuide"
                        className = "form-control ml-2 mr-4"
                        {...register("spiritualGuide", {
                            required: "Please answer the question",
                        })}
                        // value = {studentFields.spiritualGuide}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-7">
                    <label className="m-2">
                        Does the whole family pray together? If Yes, how many times a day?<span className = "text-danger">*</span> 
                    </label>
                    <textarea
                        type="text"
                        id="familyPrayerInfo"
                        name="familyPrayerInfo"
                        className = "form-control ml-2 mr-4"
                        {...register("familyPrayerInfo", {
                            required: "Please answer the question",
                        })}
                        // value = {studentFields.familyPrayerInfo}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-7">
                    <label className="m-2">
                        Details of spiritual program you attend on a weekly/monthly/quarterly basis.<span className = "text-danger">*</span> 
                    </label>
                    <textarea
                        type="text"
                        id="spiritualProgram"
                        name="spiritualProgram"
                        className = "form-control ml-2 mr-4"
                        {...register("spiritualProgram", {
                            required: "Please answer the question",
                        })}
                        // value = {studentFields.spiritualProgram}
                        // onChange = {handleStudentFieldsInputChange}
                    />
                </div>
            </div>

            <br />
            <br />
            <div className="row mb-3">
                <div className="col-md-7">
                    
                    <input
                        type="checkbox"
                        id="declaration"
                        name="declaration"
                        
                        checked = {declaration}
                        onChange = {(e)=>setDeclaration(!declaration)}
                    />
                    <label className="m-2 text-danger font-italic">
                        *I hereby declare that the information given above is true and correct. 
                    </label>
                </div>
            </div>

            <br />
            <br />
            <div className="row mb-3 ">
                <div className="col justify-content-center d-flex">
                    <button type="submit" className="btn btn-primary">
                    Save
                    </button>
                
                </div>
            </div>
        </form>




    </div>);
    };

    const displayChooseTimeSlotAcademic = () => {
        return (<div className="card p-2 mb-1">
        <label className="d-flex">
            <h4 className="p-2 text-danger">*Please select a time-slot for academic evaluation of {dataRecieved.studentName}</h4>    
        </label>
        <div style={{ position: "inherit" }}>
            
          
            <div className="card p-3">
                <div className="row">
                {dataAdmTimeslot !== [""] ? (
                
                dataAdmTimeslot.map((data,index) => (
                    <Fragment key={`${data}~${index}`}>
                         <div className = "col-md-3">
                            <div className="card border-dark mb-3 ">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="font-weight-bold col-md-9">Time Slot: {index+1}</div>
                                        <div className="col-md-1">
                                            <input 
                                                type="checkbox"
                                                id = "selectedTimeslotAcademic"
                                                name="selectedTimeslotAcademic"
                                                onChange={(e)=>setSelectedTimeslotAcademic(!selectedTimeslotAcademic)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body text-dark">
                                    <h5 className="text-success font-italic">Time: {data.appointmentDatetime.toDate().toString()}</h5>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                ) )
          ) : <div className="text-danger ">
                    <h6>***There is no time slot available right now***</h6>
                </div>}
                       
                </div> 
                
                <div className="row mb-3">
                    <div className="col-md-7">
                        
                        <input
                            type="checkbox"
                            id="declaration"
                            name="declaration"
                            
                            checked = {declaration}
                            onChange = {(e)=>setDeclaration(!declaration)}
                        />
                        <label className="m-2 text-danger font-italic">
                            *I have confirmed the choosen time slot. 
                        </label>
                    </div>
                </div>

                <div className="row mb-3 ">
                <div className="col justify-content-center d-flex">
                    <button type="submit" className="btn btn-primary">
                    Submit
                    </button>
                
                </div>
            </div>

                
            </div>
            
        </div>



    </div>);
    };

    const displayChooseTimeSlotManagerial = () => {
        return (<div className="card p-2 mb-1">
        <label className="d-flex">
        <h4 className="p-2 text-danger">*Please select a time-slot for managerial evaluation of {dataRecieved.studentName}</h4>    
      </label>


    </div>);
    };

    const displayUploadDocuments = () => {
        return (<div className="card p-2 mb-1">
        <label className="d-flex">
        <h4 className="p-2 text-danger">*Please upload all the required documents of {dataRecieved.studentName}</h4>    
      </label>


    </div>);
    };

    const displayAdmissionRejected = () => {
        return (<div className="card p-2 mb-1">
        <label className="d-flex">
        <h4 className="p-2 text-danger">*We are sorry to announce that the admission of {dataRecieved.studentName} has been rejected.</h4>    
      </label>


    </div>);
    };

    

    const displayAdmissionAccepted = () => {
        return (<div className="card p-2 mb-1">
        <label className="d-flex">
        <h4 className="p-2 text-success">*Heartiest Congratulations!! Admission of {dataRecieved.studentName} has been accepted!</h4>    
      </label>


    </div>);
    };

    return(
        <div style={{ position: "inherit" }}>
            <div className="card p-2 mb-1">
                <div className="justify-content-center d-flex">
                    <h2>Welcome to admission dashboard of {dataRecieved.studentName}</h2>
                </div>
                <div className="justify-content-center d-flex p-2 text-secondary">
                    <h4>Current Admission Step is : {admissionStepSpecifier()}</h4>
                </div>
            </div>

            { dataRecieved.admissionStep === 0 && displayUpdateStudentData()}
            { dataRecieved.admissionStep === 1 && displayChooseTimeSlotAcademic()}
            { dataRecieved.admissionStep === 2 && displayChooseTimeSlotManagerial()}
            { dataRecieved.admissionStep === 3 && displayUploadDocuments()}
            { dataRecieved.admissionStep === 888 && displayAdmissionRejected()}
            { dataRecieved.admissionStep === 999 && displayAdmissionAccepted()}

         
        </div>
    );
}



export default AdmissionDashboard;