import React, { memo } from "react";

function AcademicCalendarListView(props) {
  const academicevents = props.academicevents;
  const section = props.section;
  const type = props.type;
  const academicYear = props.academicYear;
  const getDayOfWeek = (dayNo) => {
    switch (dayNo) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        break;
    }
  };
  const events = academicevents.map((event) => {
    let showSection = false;

    switch (section) {
      case "prePrimary":
        showSection = event.isPrePrimary;
        break;

      case "Primary":
        showSection = event.isPrimary;
        break;
      case "upperPrimary":
        showSection = event.isUpperPrimary;
        break;
      case "secondary":
        showSection = event.isSecondary;
        break;
      default:
        break;
    }
    let dStart = new Date(event.start);
    let sDay = getDayOfWeek(dStart.getDay());

    let dEnd = new Date(event.end);
    let eDay = getDayOfWeek(dEnd.getDay());
    return (
      <tr key={event.id}>
        {showSection &&
          type === event.type &&
          academicYear === event.academicYear && (
            <>
              <td>{event.title}</td>
              <td>{sDay + " " + event.start}</td>
              <td>{eDay + " " + event.end}</td>
            </>
          )}
      </tr>
    );
  });

  return <>{events}</>;
}

export default AcademicCalendarListView;
