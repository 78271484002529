import React, { useState } from "react";
import DatePicker from "react-date-picker";
import { firestore } from "../../firebase/config";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getDateInFormat } from "../utilities/Utils";
import GetReferenceDetails from "../GetReferenceDetails";
import DonationReportDetails from "./ReportDetails";
import { useHistory } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import "./DateRangeDonationReport.css";
const columns = [
  {
    id: "donorId",
    label: "Donor Id",
    show: true,
  },
  {
    id: "donorName",
    label: "Donor Name",
    show: true,
  },
  {
    id: "donationId",
    label: "Donation Id",
    show: true,
  },
  {
    id: "donationReceiptNo",
    label: "Receipt No",
    show: true,
  },
  {
    id: "donationDate",
    label: "Donation Date",
    show: true,
  },
  {
    id: "amount",
    label: "Amount",
    show: true,
  },
  {
    id: "bank",
    label: "Bank",
    show: true,
  },
  {
    id: "bankRef",
    label: "Bank Reference",
    show: true,
  },
  {
    id: "bankRefDate",
    label: "Bank Reference Date",
    show: true,
  },
  {
    id: "mode",
    label: "Mode",
    show: true,
  },
  {
    id: "onRecord",
    label: "On Record",
    show: true,
  },
  {
    id: "receivedThroughWebsite",
    label: "Received Through Website",
    show: true,
  },
  {
    id: "csrDonation",
    label: "CSR Donation",
    show: true,
  },
  {
    id: "collectedBy",
    label: "Reference",
    show: true,
  },
  {
    id: "trust",
    label: "To Trust",
    show: true,
  },
];
function DateRangeDonationReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [donationDetails, setDonationDetails] = useState(null);
  const [foundNoRecord, setFoundNoRecord] = useState(false);
  const [totalDonationAmount, setTotalDonationAmount] = useState(0);
  const history = useHistory();
  const [columnData, setCoulmnData] = useState(columns);
  const updateFromDate = (date) => {
    setFromDate(date);
    if (toDate !== null) setToDate(null);
  };
  const searchDonations = async () => {
    setIsLoading(true);
    setTotalDonationAmount(0); //Reset to zero
    setDonationDetails(null);
    setFoundNoRecord(false);

    toDate.setHours(23);

    await firestore
      .collectionGroup("donations")
      .where("date", ">=", fromDate)
      .where("date", "<=", toDate)
      .where("type", "==", "Donation")
      .get({ source: "server" })
      .then((querySnapShot) => {
        if (!querySnapShot.empty) {
          let donationArray = [];
          querySnapShot.forEach(async (donation) => {
            let donationReceiptNo = donation.data().receiptNo;
            let donorId = donation.ref.parent.parent.id;

            setTotalDonationAmount(
              (prevAmount) =>
                parseInt(prevAmount) + parseInt(donation.data().amount)
            );

            donationArray.push({
              donationId: donation.id,

              donorName: (
                <GetReferenceDetails refId={donorId} needLink={true} />
              ),
              amount: donation.data().amount,
              donationDate: getDateInFormat(
                donation.data().date.toDate().toString()
              ),
              bank: donation.data().bank,
              bankRef: donation.data().bankRef,
              bankRefDate:
                donation.data().bankRefDate !== undefined &&
                (donation.data().bankRefDate !== null) &
                  (donation.data().bankRefDate !== "")
                  ? getDateInFormat(
                      donation.data().bankRefDate.toDate().toString()
                    )
                  : "",
              trust: donation.data().trust,
              mode: donation.data().mode,
              donationReceiptNo:
                donationReceiptNo !== undefined && donationReceiptNo !== null
                  ? donationReceiptNo
                  : `Not Available`,
              donorId: donorId,
              onRecord: donation.data().onRecord ? "Yes" : "No",
              csrDonation: donation.data().csrDonation ? "Yes" : "No",
              receivedThroughWebsite: donation.data().receivedThroughWebsite
                ? "Yes"
                : "No",
              collectedBy:
                donation.data().collectedBy === "Self" ? (
                  donation.data().collectedBy
                ) : (
                  <GetReferenceDetails refId={donation.data().collectedBy} />
                ),
            });
          }); //End of forEach function
          setDonationDetails(donationArray);
        } else {
          setFoundNoRecord(true);
        } // end of if (!querySnapShot.empty) - else
      }); //End of querying donation data

    setIsLoading(false);
  }; //End of searchDonations

  const handleBackButton = (e) => {
    history.push("/donorreporthome");
    e.preventDefault();
  };
  const formClass = `${
    isLoading
      ? "ui form-inline loading justify-content-center"
      : "ui form-inline justify-content-center"
  }`;
  return (
    <>
      <div className="card p-1 mb-1">
        <button
          className="btn btn-link"
          id="backButton"
          onClick={handleBackButton}
        >
          <TiArrowBack />
          Back to Report Home Page
        </button>
        <div className="justify-content-center d-flex">
          <h2>Donations Report</h2>
        </div>
      </div>
      <form
        className={formClass}
        onSubmit={(e) => {
          e.preventDefault();
          searchDonations();
        }}
      >
        <label className="mr-2" htmlFor="fromDate">
          From:
        </label>
        <DatePicker
          value={fromDate}
          id="fromDate"
          name="fromDate"
          onChange={(date) => updateFromDate(date)}
          format="dd-MMM-yyyy"
          maxDate={new Date()}
          className="ml-1 mr-1"
        />
        <label className="ml-4 mr-2" htmlFor="toDate">
          To:
        </label>
        <DatePicker
          value={toDate}
          id="toDate"
          name="toDate"
          onChange={(date) => setToDate(date)}
          format="dd-MMM-yyyy"
          disabled={fromDate === null}
          minDate={fromDate}
          maxDate={new Date()}
          className="ml-1 mr-1"
        />
        <label className="ml-4 mr-2" htmlFor="trust">
          Trust:
        </label>
        <button
          type="submit"
          className="btn btn-primary ml-4"
          disabled={toDate === null}
        >
          Search
        </button>
      </form>
      <div className="card border-top-0 rounded-0">
        <div className="card-body py-2 justify-content-center d-flex">
          <div className="card-title font-weight-bold m-0">
            {donationDetails !== null
              ? `Report`
              : foundNoRecord
              ? `No Record Found`
              : ``}
          </div>
        </div>

        {donationDetails !== null && donationDetails.length > 0 && (
          <>
            <div className="card-body py-2 justify-content-center d-flex">
              No of records: {donationDetails.length}
            </div>
            <div className="card-body py-2 justify-content-center d-flex">
              Total Donation Amount: {totalDonationAmount}
            </div>
            <div className="card-body py-2 justify-content-center d-flex">
              <ReactHTMLTableToExcel
                id="table-xls-success"
                className="btn btn-info"
                table="DateRangeDonationReport"
                filename="DateRangeDonationReport"
                sheet="DateRangeDonationReport"
                buttonText="Download as XLS"
              />
            </div>
            <DonationReportDetails
              data={donationDetails}
              columns={columnData}
              tableId="DateRangeDonationReport"
            />
          </>
        )}
      </div>
    </>
  );
}

export default DateRangeDonationReport;
