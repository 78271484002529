import React, { memo, useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { FaBullseye } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { firestore } from "../../firebase/config";
import { GetUserPrivilages } from "../../firebase/UserPrivilageProvider";
import { useSession } from "../../firebase/UserProvider";


export default memo(function ParentDetails(){
    const {
        register,
        unregister,
        setValue,
        handleSubmit,
        formState: { errors },
      } = useForm();    

      const history = useHistory();
      const [isLoading, setisLoading] = useState(false);
      
      const [childFields, setChildFields] = useState([
        {childName:'' , childDob:'' , isSchoolBms:null , isInAdmissionProcess:null , otherSchoolName:''}
      ]);

    
      const [fatherDetails, setFatherDetails] = useState({
        fullName: "",
        dob: "",
        qualifications: "",
        occupation: "",
        companyName: "",
        designation: "",
        typeOfBusiness: "",
      });

      const [motherDetails, setMotherDetails] = useState({
        fullName: "",
        dob: "",
        qualifications: "",
        occupation: "",
        companyName: "",
        designation: "",
        typeOfBusiness: "",
      });

      const [parentFields, setParentFields] = useState({
        fatherDetails: {
          fullName: "",
          dob: "",
          qualifications: "",
          occupation: "service",
          companyName: "",
          designation: "",
          typeOfBusiness: "", 
        },
        motherDetails: {
          fullName: "",
          dob: "",
          qualifications: "",
          occupation: "",
          companyName: "",
          designation: "",
          typeOfBusiness: "",
        },
      });
      

      const {user} = useSession();
      const {privilages} = GetUserPrivilages();

      const handleParentFieldsInputChange = (event) => {
        const value = {...parentFields};
        switch (event.target.name) {
          case ("fatherFullName") : value.fatherDetails.fullName = event.target.value;
          break;
          case ("fatherDob") : value.fatherDetails.dob = event.target.value;
          break;
          case ("fatherQualifications") : value.fatherDetails.qualifications = event.target.value;
          break;
          case ("fatherCompanyName") : value.fatherDetails.companyName = event.target.value;
          break;
          case ("fatherDesignation") : value.fatherDetails.designation = event.target.value;
          break;
          case ("fatherTypeOfBusiness") : value.fatherDetails.typeOfBusiness = event.target.value;
          break;
          case ("fatherSelfEmployed") : {
            value.fatherDetails.occupation = "selfEmployed";
           
          }
          break;
          case ("fatherService") : {
            value.fatherDetails.occupation = "service";
            value.fatherDetails.typeOfBusiness = "";
          }
          break;
          case ("motherFullName") : value.motherDetails.fullName = event.target.value;
          break;
          case ("motherDob") : value.motherDetails.dob = event.target.value;
          break;
          case ("motherQualifications") : value.motherDetails.qualifications = event.target.value;
          break;
          case ("motherCompanyName") : value.motherDetails.companyName = event.target.value;
          break;
          case ("motherDesignation") : value.motherDetails.designation = event.target.value;
          break;
          case ("motherTypeOfBusiness") : value.motherDetails.typeOfBusiness = event.target.value;
          break;
          case ("motherSelfEmployed") : {
            value.motherDetails.occupation = "selfEmployed";
            value.motherDetails.companyName = "";
            value.motherDetails.designation = "";
          }
          break;
          case ("motherService") : {
            value.motherDetails.occupation = "service";
            value.motherDetails.typeOfBusiness = "";
          }
          break;
        }
        if(value.fatherDetails.occupation === "selfEmployed"){
          value.fatherDetails.companyName = null;
          value.fatherDetails.designation = null;
        }
        setParentFields(value);
      };

      const handleChildFieldsInputChange = (index, event) => {
        const values = [...childFields];
        switch (event.target.name) {
          case ("childName"+index) : values[index].childName = event.target.value;
          break;
          case ("childDob"+index) : values[index].childDob = event.target.value;
          break;
          case ("bmsSchool"+index) : {
            values[index].isSchoolBms = true;
            values[index].otherSchoolName = null;
          }
          break;
          case ("otherSchool"+index) : values[index].isSchoolBms = false; 
          break;
          case ("otherSchoolName"+index) : values[index].otherSchoolName = event.target.value;
          break;
          case ("inAdmissionProcess"+index) : {
            values[index].isInAdmissionProcess = true;
            values[index].isSchoolBms = null;
            values[index].otherSchoolName = null;
          }  
          break;
          case ("notInAdmissionProcess"+index) : {
            values[index].isInAdmissionProcess = false;
          }
          break;
          
        }

        setChildFields(values);
        
     
      };

      const handleAddChildFields = () => {
        const values = [...childFields];
        values.push({ childName:'' , childDob:'' , isSchoolBms:null , isInAdmissionProcess:null , otherSchoolName:'' });
        setChildFields(values);
        
      };


      const handleRemoveChildFields = index => {
        const values = [...childFields];
        values.splice(index, 1);
        setChildFields(values);
      };
      
      useEffect(async () => {

        let parentId = "";
        await firestore
          .collection("parents")
          .where("updatedBy", "==", user.email)
          .get({source: "server"})
          .then(async (QuerySnapshot) => {
            QuerySnapshot.forEach((doc) => {
              parentId = doc.id;
            });
          });
        
        if(parentId !== ""){
          
        const docRef = firestore.collection("parents").doc(parentId);
        docRef.onSnapshot((snapshot) => {
          if (snapshot.exists) {  
            const data = snapshot.data();
            // setParentFields({
            //   fatherDetails: {
            //     fullName: data.fullName,
            //   },
            // });

            // setParentFields({
            //   fatherDetails: {
            //     fullName: "data.fatherDetails.fullName",
            //     dob: "",
            //     qualifications: "", 
            //     occupation: "",
            //     companyName: "",
            //     designation: "",
            //     typeOfBusiness: "", 
            //   },
              // motherDetails: {
              //   fullName: "",
              //   dob: "",
              //   qualifications: "",
              // //  occupation: "",
              //   companyName: "",
              //   designation: "",
              //   typeOfBusiness: "",
              // },
            //});
            // setFatherDetails({
            //   fullName: data.fatherDetails !== "" && data.fatherDetails !== undefined
            //   ? data.fatherDetails.fullName
            //   : "",
            //   dob: data.fatherDetails !== "" && data.fatherDetails !== undefined
            //   ? data.fatherDetails.dob
            //   : "",
            //   qualifications: data.fatherDetails !== "" && data.fatherDetails !== undefined
            //   ? data.fatherDetails.qualifications
            //   : "",
            //   occupation: data.fatherDetails !== "" && data.fatherDetails !== undefined
            //   ? data.fatherDetails.occupation
            //   : "",
            //   companyName: data.fatherDetails !== "" && data.fatherDetails !== undefined
            //   ? data.fatherDetails.companyName
            //   : "",
            //   designation: data.fatherDetails !== "" && data.fatherDetails !== undefined
            //   ? data.fatherDetails.designation
            //   : "",
            //   typeOfBusiness: data.fatherDetails !== "" && data.fatherDetails !== undefined
            //   ? data.fatherDetails.typeOfBusiness
            //   : "",
            // });
            
            // setMotherDetails({
            //   fullName: data.motherDetails !== "" && data.motherDetails !== undefined
            //   ? data.motherDetails.fullName
            //   : "",
            //   dob: data.motherDetails !== "" && data.motherDetails !== undefined
            //   ? data.motherDetails.dob
            //   : "",
            //   qualifications: data.motherDetails !== "" && data.motherDetails !== undefined
            //   ? data.motherDetails.qualifications
            //   : "",
            //   occupation: data.motherDetails !== "" && data.motherDetails !== undefined
            //   ? data.motherDetails.occupation
            //   : "",
            //   companyName: data.motherDetails !== "" && data.motherDetails !== undefined
            //   ? data.motherDetails.companyName
            //   : "",
            //   designation: data.motherDetails !== "" && data.motherDetails !== undefined
            //   ? data.motherDetails.designation
            //   : "",
            //   typeOfBusiness: data.motherDetails !== "" && data.motherDetails !== undefined
            //   ? data.motherDetails.typeOfBusiness
            //   : "",
            // });
    
          
          }
        });

        }
 
      
      }, []); 

     

      //Function for adding the data for the first time 
      const addParentDetails = async (e) => {
        e.preventDefault();
        setisLoading(true);
        await firestore
          .collection("parents")
          .where("updatedBy", "==", user.email)
          .get({source: "server"})
          .then(async (QuerySnapshot) => {
            if(QuerySnapshot.empty){
              let newDocRef = firestore.collection("parents").doc();
              await newDocRef
                .set({
                  parentFields,
                  childFields,
                  updatedAt: new Date(),
                  updatedBy: user.email,
                  parentId: newDocRef.id,
                })
                .then(() => {
                  
                  alert("The record has been added successfully");
                  setisLoading(false);
                });

              childFields.map(async (data)=>{
                if(data.isInAdmissionProcess === true){
                let studentDocRef = firestore.collection("students").doc();
                await studentDocRef.set({
                  studentName : data.childName,
                  studentDob : data.childDob,
                  updatedBy : user.email,
                  studentId : studentDocRef.id,
                  admissionStep : 0,
                  parentId : newDocRef.id,
                }); 
                }
              });
              
              history.push(`/home`);
            
            }
            else{

              let docId = "";
              QuerySnapshot.forEach((doc) => {
                docId = doc.id;
              });
              await firestore
                .collection("parents")
                .doc(docId)
                .update({
                  parentFields,
                  childFields,
                  updatedAt: new Date(),
                })
                .then(() => {
                  alert("Update successful");
                  setisLoading(false);

                });

                
                childFields.map(async (data)=>{

                if(data.isInAdmissionProcess === true){
                let studentDocId="";
                await firestore
                  .collection("students")
                  .where("updatedBy", "==", user.email)
                  .where("studentName", "==", data.childName)
                  .get({source: "server"})
                  .then(async (QuerySnapshot) => {
                    QuerySnapshot.forEach(async (doc) => {
                      studentDocId = doc.id;
                      await firestore.collection("students").doc(studentDocId).update({
                        studentName : data.childName,
                        studentDob : data.childDob,
                        updatedBy : user.email,
                        admissionStep : 0,
                      });
                    });
                  });    
                }
                });

                history.push(`/home`);
            }
          })
             

      };



      return(
        <div style={{ position: "inherit" }}>
          <div className="card p-1 mb-1">
            <div className="justify-content-center d-flex">
              <h2>Parents Details</h2>
            </div>
          </div>

          <div className="card p-1">
            <form  onSubmit = {addParentDetails}>
{/* Father's Details Started */}
              <label className="d-flex">
                <h4 className="p-2">Father Details:</h4>    
              </label>  
              <div className = "row mb-3">
                <div className = "col-md-6">
                  <label className="m-2">
                    Full Name:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control ml-2 mr-4"
                    id="fatherFullName"
                    name="fatherFullName"
                    value = {parentFields.fatherDetails.fullName}
                    onChange = {handleParentFieldsInputChange}
                  />
                </div>
                <div className="col-md-6">
                <label className="m-2">Date of Birth: <span className="text-danger">*</span></label>
                <input
                  type="date"
                  className="form-control ml-2 mr-4"
                  id="fatherDob"
                  name="fatherDob"
                  value = {parentFields.fatherDetails.dob}
                  onChange = {handleParentFieldsInputChange}
                />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="m-2">Qualifications:<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    className="form-control ml-2 mr-4"
                    id="fatherQualifications"
                    name="fatherQualifications"
                    value = {parentFields.fatherDetails.qualifications}
                    onChange = {handleParentFieldsInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label className="m-2">Occupation: <span className="text-danger">*</span></label>
                  <br />
                  <div className="row">
                    <div className="col-md-3">
                      <input 
                        type= "radio"

                        id= "fatherService"
                        name= "fatherService"
                        value = "service"
                        checked={parentFields.fatherDetails.occupation === "service"}
                        onChange={handleParentFieldsInputChange}
                      />
                      <label htmlFor="fatherService" className="m-2">Service</label>
                    </div>
                    
                    <div className="col-md-6">
                      <input 
                        type= "radio"

                        id= "fatherSelfEmployed"
                        name= "fatherSelfEmployed"
                        value = "selfEmployed"
                        checked={parentFields.fatherDetails.occupation === "selfEmployed"}
                        onChange={handleParentFieldsInputChange}
                      />
                      <label htmlFor="fatherSelfEmployed" className="m-2">Self Employed</label>
                    </div>
                    
                  </div>
                </div>
              </div>
                

              {parentFields.fatherDetails.occupation === "service" ? (
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="m-2">Company Name: <span className="text-danger">*</span></label>
                    <input 
                      type="text"
                      className="form-control ml-2 mr-4"
                      id="fatherCompanyName"
                      name="fatherCompanyName"
                      value = {parentFields.fatherDetails.companyName}
                      onChange = {handleParentFieldsInputChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="m-2">Designation: <span className="text-danger">*</span></label>
                    <input 
                      type="text"
                      className="form-control ml-2 mr-4"
                      id="fatherDesignation"
                      name="fatherDesignation"
                      value = {parentFields.fatherDetails.designation}
                      onChange = {handleParentFieldsInputChange}
                    />
                  </div>
                </div>
                
              ) : (
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="m-2">Type of Business: <span className="text-danger">*</span></label>
                    <input 
                      type="text"
                      className="form-control ml-2 mr-4"
                      id="fatherTypeOfBusiness"
                      name="fatherTypeOfBusiness"
                      value = {parentFields.fatherDetails.typeOfBusiness}
                      onChange = {handleParentFieldsInputChange}
                    />
                    
                  </div>
                  
                </div>

              )}

              <br />
              <br />
              
{/* Father's Details End */}
{/* Mother's Details Started */}
              <label className="d-flex">
                <h4 className="p-2">Mother Details:</h4>
              </label>
              <div className = "row mb-3">
                <div className = "col-md-6">
                  <label className="m-2">
                    Full Name:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control ml-2 mr-4"
                    id="motherFullName"
                    name="motherFullName"
                    value = {""}
                    onChange = {handleParentFieldsInputChange}
                  />
                </div>
                <div className="col-md-6">
                <label className="m-2">Date of Birth: <span className="text-danger">*</span></label>
                <input
                  type="date"
                  className="form-control ml-2 mr-4"
                  id="motherDob"
                  name="motherDob"
                  value = {"parentFields.motherDetails.dob"}
                  onChange = {handleParentFieldsInputChange}
                />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="m-2">Qualifications:<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    className="form-control ml-2 mr-4"
                    id="motherQualifications"
                    name="motherQualifications"
                    value = {""}
                    onChange = {handleParentFieldsInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label className="m-2">Occupation: <span className="text-danger">*</span></label>
                  <br />
                  <div className="row">
                    <div className="col-md-3">
                      <input 
                        type= "radio"
                      
                        id= "motherService"
                        name= "motherService"
                        value = "service"
                        checked={"service"}
                        onChange={handleParentFieldsInputChange}
                      />{" "}
                      <label htmlFor="motherService" className="m-2">Service</label>
                    </div>
                    
                  
                    <div className="col-md-6">
                      <input 
                        type= "radio"

                        id= "motherSelfEmployed"
                        name= "motherSelfEmployed"
                        value = "selfEmployed"
                        checked={'parentFields.motherDetails.occupation'}
                        onChange={handleParentFieldsInputChange}
                      />{" "}
                      <label htmlFor="motherSelfEmployed" className="m-2">Self Employed</label>
                    </div>
                    
                  </div>
                </div>
              </div>
                

              {parentFields.motherDetails.occupation === "service" ? (
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="m-2">Company Name: <span className="text-danger">*</span></label>
                    <input 
                      type="text"
                      className="form-control ml-2 mr-4"
                      id="motherCompanyName"
                      name="motherCompanyName"
                      value = {"parentFields.motherDetails.companyName"}
                      onChange = {handleParentFieldsInputChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="m-2">Designation: <span className="text-danger">*</span></label>
                    <input 
                      type="text"
                      className="form-control ml-2 mr-4"
                      id="motherDesignation"
                      name="motherDesignation"
                      value = {"parentFields.motherDetails.designation"}
                      onChange = {handleParentFieldsInputChange}
                    />
                  </div>
                </div>
                
              ) : (
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="m-2">Type of Business: <span className="text-danger">*</span></label>
 
                    <input 
                      type="text"
                      className="form-control ml-2 mr-4"
                      id="motherTypeOfBusiness"
                      name="motherTypeOfBusiness"
                      value = {"parentFields.motherDetails.typeOfBusiness"}
                      onChange = {handleParentFieldsInputChange}
                    />
                  </div>
                  
                </div>

              )}
              <br />
              <br />
{/* Mother Details Ended */}
{/* Children Details Started*/}
              
              <label className="d-flex">
                <h4 className="p-2">Children Details:</h4>
              </label>
              
              
              

              
                {childFields.map((childField, index) => (
                  
                    <Fragment key={`${childField}~${index}`}> 
                       <label className="d-flex">
                         <h5 className="p-2">Child  {(index+1)} :</h5>
                       </label>
                       <div className="row mb-3">
                         <div className="col-md-6">
                           <label className="m-2">Name: <span className="text-danger">*</span></label>
                           <input 
                             type = "text"
                             id = {"childName"+index}
                             name = {"childName"+index}
                             className = "form-control ml-2 mr-4"
                             value = {childField.childName}
                             onChange = {event => handleChildFieldsInputChange(index, event)}
                           />
                         </div>
                         <div className="col-md-6">
                           <label className="m-2">Date of Birth: <span className="text-danger">*</span></label>
                           <input 
                             type = "date"
                              id = {`childDob${index}`}
                             name = {"childDob"+index}
                             value = {childField.childDob}
                             className = "form-control ml-2 mr-4"
                             onChange = {event => handleChildFieldsInputChange(index, event)}
                           />
                         </div>
                       </div>   
                       <br />
         
                       <div className = "row mb-3">
                          <div className = "col-md-6">
                            <label className="m-2">Are you currently seeking admission in Bhaktivedanta Model School for this child?: <span className="text-danger">*</span></label>
                            <div className = "row">
                              <div className = "col-md-6">
                                <input 
                                  type="radio"
                                  id = {"inAdmissionProcess" +index}
                                  name = {"inAdmissionProcess" +index}
                                  value = {true}
                                  checked={childField.isInAdmissionProcess === true}
                                  onChange = {event => handleChildFieldsInputChange(index, event)}
                                />
                                <label htmlFor={"inAdmissionProcess" +index} className="m-2">Yes</label>

                              </div>
                              <div className = "col-md-6">
                                <input 
                                  type="radio"
                                  id = {"notInAdmissionProcess" +index}
                                  name = {"notInAdmissionProcess" +index}
                                  value = {false}
                                  checked={childField.isInAdmissionProcess === false}
                                  onChange = {event => handleChildFieldsInputChange(index, event)}
                                />
                                <label htmlFor={"notInAdmissionProcess" +index} className="m-2">No</label>

                              </div>
                            </div>
                          </div>

                       </div>
                       
                      {childField.isInAdmissionProcess === false ? (
                         
                       
                       <div className="row mb-3">
                       <div className="col-md-6">
                         <label className="m-2">
                           Current School Name:
                           <span className="text-danger">*</span>
                           <span className="text-secondary"> (If child does not attend school, please select 'other')</span>
                         </label>

                         <div className="row">
                           <div className="col-md-6">
                             
                             <input 
                               type = "radio"
                              
                               id= {"bmsSchool"+index}
                               name = {"bmsSchool"+index}
                               value = {true}
                               checked={childField.isSchoolBms === true}
                               onClick = {() => unregister("schoolName")}
                               onChange = {event => handleChildFieldsInputChange(index, event)}
                             
                               
                             />
                             <label htmlFor={"bmsSchool"+index} className="m-2">Bhaktivedanta Model School</label>
                           </div>
       
                           <div className="col-md-6">
                             
                             <input 
                               type = "radio"
                               
                               id= {"otherSchool"+index}
                               name = {"otherSchool"+index}
                               value = {false}
                               checked={childField.isSchoolBms === false}
                               onChange = {event => handleChildFieldsInputChange(index, event)}
                             />
                             <label htmlFor={"otherSchool"+index} className="m-2">Other</label>
                           </div>
                         </div>
                       </div>
       
                       {childField.isSchoolBms === false ? 
                        ( <div className="col-md-6">
                           <label className="m-2">
                             Please specify School Name
                             <span className="text-danger">*</span>
                             <span className="text-secondary"> (If child does not attend school, write 'NA')</span>
                           </label>
                           <input 
                             type="text"
                             id={"otherSchoolName"+index}
                             name={"otherSchoolName"+index}
                             className="form-contrparentFields.motherDetails.typeOfBusinessol ml-2 mr-4"
                             value = {childField.otherSchoolName}
                             onChange = {event => handleChildFieldsInputChange(index, event)}
                        
                           />
                         </div> )
                         : ""
                       }
       
                     </div>


                      ) : ""}



                       <br />
                       <br />
                   

                      <div className="row mb-3 ">
                        <div className="col justify-content-left d-flex">
                          <button 
                            type="button"  
                            className="btn btn-link text-danger"
                            onClick={() => handleRemoveChildFields(index)}
                          >
                            Delete above child details
                          </button>
                        </div>
                      </div>
                      
                      
                       

                    </Fragment>
                  
         
                ))}

                
              



            
              <div className="row mb-3">
                <div className="col justify-content-left d-flex">
                  <button 
                    type="button"  
                    className="btn btn-link"
                    onClick={() => handleAddChildFields()}
                  >
                    Add child
                  </button>
              
                </div>
              </div>
 
              

              <br/>
              

              <br />
              <br />
              <div className="row mb-3 ">
                <div className="col justify-content-center d-flex">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>{" "}
              
                </div>
              </div>
 
            </form>
          </div>
        </div>

      );

});