import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { GetDonation, GetDonationDate } from "./DonationContextProvider";

function ReceiptMetaData() {
  const styles = StyleSheet.create({
    textStyle: {
      fontSize: 7,
      width: "100%",
    },
    textReceiptNo: {
      textAlign: "left",
    },
    textReceiptDate: {
      textAlign: "right",
      //   alignSelf: "flex-end",
      //   width: "100%",
    },
    outerContainerStyle: {
      display: "flex",
      flexDirection: "row",
      paddingTop: "1vh",
      paddingBottom: "1vh",
      alignItems: "center",
      alignContent: "center",
      flexWrap: "nowrap",
    },
  });

  const donation = GetDonation();
  const donationDate = GetDonationDate();
  return (
    <>
      <View style={styles.outerContainerStyle}>
        <View style={[styles.textStyle, styles.textReceiptNo]}>
          <Text>{`Receipt No: ${donation.receiptNo}`}</Text>
        </View>
        <View style={[styles.textStyle, styles.textReceiptDate]}>
          <Text>{`Receipt Date: ${donationDate}`}</Text>
        </View>
      </View>
    </>
  );
}

export default ReceiptMetaData;
