import { firestore, storage } from "./config";

export const createUserDocument = async (user) => {
  const docRef = firestore.collection("users").doc(user.uid);
  const doc = await docRef.get();

  //Create user object
  const userProfile = {
    uid: user.uid,
    email: user.email,
    name: user.displayName,
    language: "English",
    isAdmin: false,
    canAccessDMS: false,
    canAccessFMS: false,
    photoURL: user.photoURL,
    dms: {
      canAcceptDonation: false,
      canAccess: false,
      canCreateDonor: false,
      canUpdateDonor: false,
      canAccessReports: false,
    },
    ams: {
      isStaff: false,
      isAdmissionAdmin: false,
      isManagement: false,
      isParent: false,
    },
    ems: {
      isExpenseStaff: false,
      isExpenseAuditor: false,
      isExpenseApproverL1: false,
      isExpenseApproverL2: false,
    },
    academic_calendar: {
      isCalendarAdmin: false,
    },
    websiteAccess: {
      canAccessAdmin: false,
      canAccessEvent: false,
      canAccessGallery: false,
      canAccessNews: false,
    },
    g_classroom_dashboard: {
      canAccess: false,
      isAdmin: false,
    },
  };

  if (!doc.exists) {
    //write to cloud firesore
    return docRef.set(userProfile);
  } else {
    //Update the existing user profile
    return docRef.update({
      uid: user.uid,
      email: user.email,
      name: user.displayName,
      photoURL: user.photoURL,
    });
  }
};

export const getImageUrl = async (path) => {
  return storage.ref().child(path).getDownloadURL();
};

export const listStorage = async (path) => {
  return storage.ref().child(path).list();
};
