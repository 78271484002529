import React from "react";

export default React.memo(function ReportDetails(props) {
  const data = props.data;
  const columns = props.columns;
  let dataList = data.map((donation) => {
    return (
      <tr key={donation.id}>
        {columns.map((column) => {
          return column.show && <td key={column.id}>{donation[column.id]}</td>;
        })}
      </tr>
    );
  });
  //End of dataList

  const columnList = columns.map((column) => {
    return column.show && <th key={column.id}>{column.label}</th>;
  });
  return (
    <>
      <div className="table-responsive">
        <table
          className="table table-bordered"
          id={props.tableId ? props.tableId : "reportDetailTableId"}
        >
          <thead>
            <tr>{columnList}</tr>
          </thead>
          <tbody>{dataList}</tbody>
        </table>
      </div>
    </>
  );
});
