import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase/config";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./DateRangeDonationReport.css";
import { useHistory, Link } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";

import { getDateInFormat } from "../utilities/Utils";
import GetReferenceDetails from "../GetReferenceDetails";
import ReportDetails from "./ReportDetails";
const columns = [
  {
    id: "id",
    label: "Donor Id",
    show: true,
  },
  {
    id: "fullNameWithLink",
    label: "Donor Name",
    show: true,
  },
  {
    id: "spiritualName",
    label: "Spiritual Name",
    show: true,
  },
  {
    id: "pan",
    label: "PAN",
    show: true,
  },
  {
    id: "email",
    label: "Email",
    show: true,
  },
  {
    id: "phone",
    label: "Phone",
    show: true,
  },
  {
    id: "formatedDOB",
    label: "Date of Birth",
    show: true,
  },
  {
    id: "totalDonation",
    label: "Individual Donation",
    show: true,
  },
  {
    id: "totalCommitment",
    label: "Individual commitment",
    show: true,
  },
  {
    id: "totalIndividualContribution",
    label: "Total Individual Contribution",
    show: true,
  },
  {
    id: "totalCommitmentCollection",
    label: "Collection received from others",
    show: true,
  },
  {
    id: "totalCollectionFromOthers",
    label: "Total collection from others",
    show: true,
  },
  {
    id: "formattedRef",
    label: "Reference",
    show: true,
  },
];

function AllDonorDetailReport() {
  const [donorData, setDonorData] = useState(null);
  const history = useHistory();
  const handleBackButton = (e) => {
    history.push("/donorreporthome");
    e.preventDefault();
  };

  const [columnData, setCoulmnData] = useState(columns);

  const handleColumnShow = (columnId) => {
    setCoulmnData(prevCol => {
      
    });
  }
  useEffect(() => {
    firestore
      .collection("donors")
      .get({ source: "server" })
      .then((querySnapShot) => {
        let donorArray = [];
        if (!querySnapShot.empty) {
          querySnapShot.forEach((donor) => {
            donorArray.push({
              id: donor.id,
              formatedDOB: donor.data().dob
                ? getDateInFormat(donor.data().dob.toDate().toString())
                : "",
              totalIndividualContribution:
                donor.data().totalDonation + donor.data().totalCommitment,
              totalCollectionFromOthers:
                donor.data().totalCollection +
                donor.data().totalCommitmentCollection,
              formattedRef:
                donor.data().reference === "Self" ? (
                  "Self"
                ) : (
                  <GetReferenceDetails
                    refId={donor.data().reference}
                    needLink={false}
                  />
                ),
              fullNameWithLink: (
                <Link to={`/donordetails/${donor.id}`}>
                  {donor.data().fullName}
                </Link>
              ),
              ...donor.data(),
            });
          }); //End of querySnapShot.forEach

          setDonorData(donorArray);
        }
      });
  }, []);
  return (
    <>
      <div className="card p-1 mb-1">
        <button
          className="btn btn-link"
          id="backButton"
          onClick={handleBackButton}
        >
          <TiArrowBack />
          Back to Report Home Page
        </button>
        <div className="justify-content-center d-flex">
          <h2>All Donor Report</h2>
        </div>
      </div>
      {donorData && donorData.length > 0 && (
        <div className="card border-top-0 rounded-0">
          <div className="card-body py-2 justify-content-center d-flex">
            <div>
              <ReactHTMLTableToExcel
                id="table-xls-success"
                className="btn btn-info"
                table="AllDonorDetailReport"
                filename="AllDonorDetailReport"
                sheet="AllDonorDetailReport"
                buttonText="Download as XLS"
              />
            </div>
          </div>
          <ReportDetails
            columns={columnData}
            data={donorData}
            tableId="AllDonorDetailReport"
          />
        </div>
      )}
    </>
  );
}

export default AllDonorDetailReport;
