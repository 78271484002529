import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { GetLoggedUserDetails } from "./DonationContextProvider";

function ReceiptFooter() {
  const user = GetLoggedUserDetails();
  return (
    <>
      <View>
        <Text style={{ textAlign: "center", fontSize: ".75vh" }}>
          {user.email}
        </Text>
      </View>
    </>
  );
}

export default ReceiptFooter;
