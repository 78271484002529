import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import "../styles/Sidebar.css";
import { useSession } from "../firebase/UserProvider";
import { GetUserPrivilages } from "../firebase/UserPrivilageProvider";

function Sidebar() {
  const [navbar, setNavBar] = useState(false);

  const [donorNavMenuDropdown, setDonorNavMenuDropdown] = useState(false);
  const [academicCalendarMenuDD, setAcademicCalendarMenuDD] = useState(false);
  const [admissionNavMenuDropdown, setAdmissionNavMenuDropdown] =
    useState(false);

  const history = useHistory();

  const [expenseNavMenuDropdown, setExpenseNavMenuDropdown] = useState(false);
  const [isParentPortal, setIsParentPortal] = useState(false);
  const showSidebar = () => setNavBar(!navbar);

  const { user } = useSession();
  const privilages = GetUserPrivilages();
  const toggleNavigation = () => {
    setNavBar(!navbar);
  };

  const toggleDonorNavMenuDropdown = () => {
    setDonorNavMenuDropdown(!donorNavMenuDropdown);
  };

  const toggleAdmissionNavMenuDropdown = () => {
    setAdmissionNavMenuDropdown(!admissionNavMenuDropdown);
  };

  const toggleExpenseNavMenuDropdown = () => {
    setExpenseNavMenuDropdown(!expenseNavMenuDropdown);
  };

  useEffect(() => {
    window.location.href.indexOf("int") < 0
      ? setIsParentPortal(true)
      : setIsParentPortal(false);

    window.location.href.indexOf("int") < 0
      ? setNavBar(true)
      : setNavBar(false);
  }, []);
  return (
    <>
      {(user || isParentPortal) && (
        <>
          {!isParentPortal ? (
            <button
              type="button"
              aria-label="Toggle Navigation"
              className="navbar-toggler position-absolute d-md-none collapsed pb-2"
              onClick={toggleNavigation}
            >
              <FaIcons.FaBars onClick={showSidebar} />
            </button>
          ) : (
            ""
          )}

          <div className="float-left sidebar">
            <nav
              id="sideMenu"
              className={`d-md-block bg-light collapse ${navbar ? "show" : ""}`}
            >
              <div className="sidebar-sticky pt-3">
                <ul className="nav flex-column">
                  {isParentPortal && (
                    <li className="nav-item text-center">
                      <button
                        className="btn btn-link sidebar-menu-item"
                        onClick={(e) => {
                          e.preventDefault();
                          setAcademicCalendarMenuDD(!academicCalendarMenuDD);
                        }}
                      >
                        Academic Calendar
                      </button>
                      <nav
                        className={`col bg-light ${
                          academicCalendarMenuDD ? "showMenu" : "hideMenu"
                        }`}
                      >
                        <ul className="nav flex-column pl-2">
                          <li className="nav-item pb-4">
                            <Link
                              className=""
                              to="/academiccalendar/prePrimary"
                            >
                              - Pre-Primary
                              <br />
                              (Nursery - SrKg)
                            </Link>
                          </li>
                          <li className="nav-item pb-4">
                            <Link className="" to="/academiccalendar/Primary">
                              - Primary
                              <br />
                              (Std 1 - 4)
                            </Link>
                          </li>
                          <li className="nav-item pb-4">
                            <Link
                              className=""
                              to="/academiccalendar/upperPrimary"
                            >
                              - Upper Primary
                              <br />
                              (Std 5 - 7)
                            </Link>
                          </li>
                          <li className="nav-item pb-4">
                            <Link className="" to="/academiccalendar/secondary">
                              - Secondary <br />
                              (Std 8 - 10)
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </li>
                  )}
                  {user && (
                    <li className="nav-item text-center">
                      <Link className="sidebar-menu-item " to="/">
                        Home
                      </Link>
                    </li>
                  )}
                  {privilages.isAcademicCalendarAdmin && user && (
                    <li className="nav-item text-center">
                      <button
                        className="btn btn-link sidebar-menu-item"
                        onClick={toggleDonorNavMenuDropdown}
                      >
                        Academic Calendar Administration
                      </button>
                      <nav
                        className={`col bg-light ${
                          donorNavMenuDropdown ? "showMenu" : "hideMenu"
                        }`}
                      >
                        <ul className="nav flex-column pl-2">
                          <li className="nav-item pb-4">
                            <Link
                              className=""
                              to="/addanewacademiccalendarevent"
                            >
                              - Add a new academic event
                            </Link>
                          </li>
                          <li className="nav-item pb-4">
                            <Link
                              className=""
                              to="/searchacademiccalendareventbytitle"
                            >
                              - Search event by title
                            </Link>
                          </li>
                          {/* <li className="nav-item pb-4">
                            <Link className="" to="/">
                              - Search event by date
                            </Link>
                          </li> */}
                        </ul>
                      </nav>
                    </li>
                  )}
                  {privilages.canAccessDMS && user && (
                    <li className="nav-item text-center">
                      <button
                        className="btn btn-link sidebar-menu-item font-weight-bold"
                        onClick={toggleDonorNavMenuDropdown}
                      >
                        Donor Management System
                      </button>
                      <nav
                        className={`col bg-light ${
                          donorNavMenuDropdown ? "showMenu" : "hideMenu"
                        }`}
                      >
                        <ul className="nav flex-column pl-2">
                          <li className="nav-item pb-4">
                            <button
                              className="btn btn-link sidebar-menu-item"
                              onClick={() => {
                                history.push("/donorsearchbyname");
                                toggleDonorNavMenuDropdown();
                                setNavBar(!navbar);
                              }}
                            >
                              - Search a Donor By Name{" "}
                            </button>
                          </li>
                          <li className="nav-item pb-4">
                            <button
                              className="btn btn-link sidebar-menu-item"
                              onClick={() => {
                                history.push("/donorsearch");
                                toggleDonorNavMenuDropdown();
                                setNavBar(!navbar);
                              }}
                            >
                              - Search a Donor By PAN{" "}
                            </button>
                          </li>
                          <li className="nav-item pb-4">
                            {/* <Link className="" to="/addnewdonor">
                              - Add a New Donor
                            </Link> */}
                            <button
                              className="btn btn-link sidebar-menu-item"
                              onClick={() => {
                                history.push("/addnewdonor");
                                toggleDonorNavMenuDropdown();
                                setNavBar(!navbar);
                              }}
                            >
                              - Add a New Donor
                            </button>
                          </li>
                          {privilages.canAccessReports && (
                            <button
                              className="btn btn-link sidebar-menu-item"
                              onClick={() => {
                                history.push("/donorreporthome");
                                toggleDonorNavMenuDropdown();
                                setNavBar(!navbar);
                              }}
                            >
                              - Donor Reports
                            </button>
                          )}
                        </ul>
                      </nav>
                    </li>
                  )}
                  {/* Below privilage is for Staff members */}
                  {privilages.isStaff && user && (
                    <li className="nav-item text-center">
                      <button
                        className="btn btn-link sidebar-menu-item"
                        onClick={toggleAdmissionNavMenuDropdown}
                      >
                        Admission Management System
                      </button>
                      {/* <div className="row"> */}
                      <nav
                        className={`col bg-light ${
                          admissionNavMenuDropdown ? "showMenu" : "hideMenu"
                        }`}
                      >
                        {/* <div className="sidebar-sticky pt-3"> */}
                        <ul className="nav flex-column pl-2">
                          <li className="nav-item pb-4">
                            <Link className="" to="/createparentuser">
                              - Create Parent User
                            </Link>
                          </li>
                          <li className="nav-item pb-4">
                            <Link className="" to="/timeslotsinternal">
                              - Create Time Slots
                            </Link>
                          </li>
                          <li className="nav-item pb-4">
                            <Link className="" to="/staffviewtimeslots">
                              - Staff View Time Slots
                            </Link>
                          </li>
                        </ul>
                        {/* </div> */}
                      </nav>
                      {/* </div> */}
                    </li>
                  )}

                  {privilages.canAccessGClassroomDashboard && user && (
                    <li className="nav-item pb-4 text-center">
                      <Link
                        className="sidebar-menu-item"
                        to="/g-classroom-dashboard"
                      >
                        G Classroom Dashboard
                      </Link>
                    </li>
                  )}

                  {/* Below privilage is for parents */}
                  {privilages.isParent && user && (
                    <li className="nav-item text-center">
                      <li className="nav-item pb-4">
                        <Link className="" to="/parentdetails">
                          Parent's Details
                        </Link>
                      </li>

                      <li className="nav-item pb-4">
                        <Link className="" to="/admissionpanel">
                          Admission Panel
                        </Link>
                      </li>
                    </li>
                  )}

                  {privilages.isAdmin && user && (
                    <li className="nav-item pb-4 text-center">
                      <Link className="sidebar-menu-item" to="/searchuser">
                        User Management - {process.env.REACT_APP_IS_PROD}
                      </Link>
                    </li>
                  )}

                  {privilages.isAdmin &&
                    user &&
                    process.env.REACT_APP_IS_PROD === "false" && (
                      <li className="nav-item pb-4 text-center">
                        <Link
                          className="sidebar-menu-item"
                          to="/donordetails/QZPi7jjNQ56JvnaTXqL7"
                        >
                          Test Record
                        </Link>
                      </li>
                    )}

                  {privilages.isAdmin &&
                    user &&
                    process.env.NODE_ENV !== "production" && (
                      <li className="nav-item pb-4 text-center">
                        <Link
                          className="sidebar-menu-item"
                          to="/updateOnRecord"
                        >
                          One Time Job
                        </Link>
                      </li>
                    )}
                  {(privilages.isExpenseStaff ||
                    privilages.isExpenseAuditor ||
                    privilages.isExpenseApproverL1 ||
                    privilages.isExpenseApproverL2) &&
                    user && (
                      <li className="nav-item text-center">
                        <button
                          className="btn btn-link sidebar-menu-item"
                          onClick={toggleExpenseNavMenuDropdown}
                        >
                          Expense Management System
                        </button>
                        <nav
                          className={`col bg-light ${
                            expenseNavMenuDropdown ? "showMenu" : "hideMenu"
                          }`}
                        >
                          <ul className="nav flex-column pl-2">
                            <li className="nav-item pb-4">
                              <Link
                                className="sidebar-menu-item"
                                to="/expensereport"
                              >
                                - Add a Expense Request
                              </Link>
                            </li>
                          </ul>
                        </nav>
                      </li>
                    )}
                </ul>
              </div>
            </nav>
          </div>
        </>
      )}
    </>
  );
}

export default Sidebar;
