import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { RiEditFill } from "react-icons/ri";
import { firestore } from "../../firebase/config";
import MyDialog from "../MyDialog";
function EventList(props) {
  // const eventList = 'Result here';
  // const eventList = !Array.isArray([queryResult]) ? queryResult[0].id : [queryResult].map((event) => {

  // const eventList = "Result";
  const queryResult = props.queryResult
  const [canOpenDeleteDialog, setCanOpenDeleteDialog] = useState(false);
  const [currentEventId, setCurrentEventId] = useState('');
  const cancelDeleteOperation = () => {
    setCanOpenDeleteDialog(false);
  };

  const deleteEvent = (eventId) => {
    setCanOpenDeleteDialog(false);
    firestore
      .collection("academic-calendar-events")
      .doc(currentEventId)
      .delete()
      .then(() => {
        alert("The event has been deleted");
      });
  }; //End of deleteEvent

  const eventList = queryResult.map((event) => {
    
    return (
      <>
        <div
          className="list-group-item d-flex list-group-item-info"
          key={event.id}
        >
          <section
            className="btn-group align-self-center pr-1 text-success font-weight-bold"
            role="group"
          >
            {event.title}:
          </section>
          <section
            className="btn-group align-self-center pr-1 font-italic text-secondary"
            role="group"
          >
            {event.start}
          </section>
          <section
            className="btn-group align-self-center pr-1 font-italic"
            role="group"
          >
            TO
          </section>
          <section
            className="btn-group align-self-center pr-1 font-italic"
            role="group"
          >
            {event.end}
          </section>
          <section className="btn-group align-self-center pr-1" role="group">
            <Link to={`/eventdetail/${event.id}`}>
              <RiEditFill />
            </Link>
          </section>
          <section className="btn-group align-self-center pr-1" role="group">
            <button
              onClick={(e) => {
                e.preventDefault();
                setCurrentEventId(event.id);
                setCanOpenDeleteDialog(true);
              }}
            >
              <AiOutlineDelete />
            </button>
            <MyDialog
              open={canOpenDeleteDialog}
              handleClose={cancelDeleteOperation}
              dialogTitle="Delete Event Confirmation Box"
              dialogText="Are you sure you want to delete this event?"
              handleDefault={deleteEvent}
              defaultBtnText="Yes"
              cancelBtnText="No"
            />
          </section>
        </div>
      </>
    );
  });
  return <>{eventList}</>;
}

export default EventList;
