import React from "react";
import { Text, StyleSheet, Font } from "@react-pdf/renderer";
import { GetDonation } from "./DonationContextProvider";
import BookmanOldStyleBold from "./fonts/BOOKOSB.TTF";

Font.register({
  family: "Bookman Old Style",
  fonts: [{ src: BookmanOldStyleBold, fontWeight: "bold" }],
});

const styles = StyleSheet.create({
  textHeader: {
    width: "100%",
    color: "#1E79F4",
    paddingBottom: "0.5vh",
  },
  viewStyle: {
    flexDirection: "column",
  },
  textHeaderTop: {
    fontFamily: "Bookman Old Style",
    fontWeight: "bold",
    fontSize: "18px",
  },
  textHeaderOther: {
    fontFamily: "Bookman Old Style",
    fontWeight: "bold",
    fontSize: "12px",
  },
});
function LogoHeaderText() {
  const donation = GetDonation();
  const trust = donation.trust;
  return (
    <>
      <view style={styles.viewStyle}>
        <Text style={[styles.textHeader, styles.textHeaderTop]}>
          {trust === "CREST"
            ? "Chaitanya Research & Educational Services Trust"
            : "Shree Chaitanya Shikshan Samstha"}
        </Text>
        <Text style={[styles.textHeader, styles.textHeaderTop]}>
          {trust === "CREST" ? "(CREST)" : "(SCSS)"}
        </Text>
        <Text
          style={[
            styles.textHeader,
            styles.textHeaderOther,
            { flexWrap: "nowrap !important" },
          ]}
        >
          {trust === "CREST"
            ? "Flat No B601, Golok Vrindavan, Tilekar Nagar, Behind ISKCON Temple, Kondhwa Pune - 48"
            : "4, Tarapore Road, Camp, Pune - 01"}
        </Text>
        <Text style={[styles.textHeader, styles.textHeaderOther]}>
          {trust === "CREST"
            ? "Registered under Soc Reg Act 1860 No MH.894/2015/PUNE"
            : "Registered under Soc Reg Act 1860 No MH.1972/2007/PUNE"}
        </Text>
        <Text style={[styles.textHeader, styles.textHeaderOther]}>
          {trust === "CREST"
            ? "Trust PAN: AABTC8218M"
            : "Trust PAN: AAKTS9670C"}
        </Text>
        <Text style={[styles.textHeader, styles.textHeaderOther]}>
          {trust === "CREST"
            ? "80G Unique Reg No: AABTC8218MF20211"
            : "80G Unique Reg No: AAKTS9670CF20217"}
        </Text>
        {process.env.REACT_APP_IS_PROD === "false" && (
          <>
            <Text style={[styles.textHeader, styles.textHeaderOther]}>
              This is a sample receipt and not a valid one. This doesn't have
              any legal stand.
            </Text>
          </>
        )}
      </view>
    </>
  );
}

export default LogoHeaderText;
