import React, { memo, useEffect, useState } from "react";
import { firestore } from "../firebase/config";
import GetReferenceDetails from "./GetReferenceDetails";
import ShowDonationsFromOthers from "./ShowDonationsFromOthers";
import { getDateInFormat } from "./utilities/Utils";

function GetDonationsAndCommitmentsFromOthers(props) {
  const [donations, setDonations] = useState([]);
  const [commitments, setCommitments] = useState([]);
  useEffect(() => {
    let donorId = props.donorId;
    //Get all donations where collectedBy = donorId
    //if type = donations store them in donations state
    //else if type=commitment store them in commitment state

    getDonations(donorId);
  }, []);
  const getDonations = async (donorId) => {
    props.toggleIsGettingDonationsAndCommitmentsFromOthersFlag(true);
    await firestore
      .collectionGroup("donations")
      .where("collectedBy", "==", donorId)
      .onSnapshot((snapShot) => {
        if (!snapShot.empty) {
          let donationData = [];
          let commitmentData = [];

          snapShot.docs.forEach((data) => {
            let dData = data.data();

            let donorRefId = data.ref.parent.parent.id;
            let dataObj = {
              id: data.id,
              amount: dData.amount,
              donorName: (
                <GetReferenceDetails refId={donorRefId} needLink={true} />
              ),
              trust: dData.trust === "" ? "Not Applicable" : dData.trust,
              commissionAmount:
                dData.commissionAmount === "" ||
                dData.commissionAmount === undefined
                  ? "0"
                  : dData.commissionAmount,
              csrDonation: dData.csrDonation ? "Yes" : "No",
              date: getDateInFormat(dData.date.toDate().toString()),
              onRecord: dData.onRecord ? "Yes" : "No",
              raisedThroughPlatform:
                dData.raisedThroughPlatform === "" ||
                dData.raisedThroughPlatform === undefined
                  ? "Not Available"
                  : dData.raisedThroughPlatform,
              mode: dData.mode,
              receivedThroughWebsite: dData.receivedThroughWebsite
                ? "Yes"
                : "No",
            };

            if (dData.type === "Donation") {
              donationData.push(dataObj);
            } else {
              commitmentData.push(dataObj);
            }
          }); //snapShot.docs.forEach
          if (donationData.length > 0) {
            setDonations(donationData);
          }
          if (commitmentData.length > 0) {
            setCommitments(commitmentData);
          }
        } //End of if(!snapShot.empty)
        else {
          console.log("No data found!");
        }
        props.toggleIsGettingDonationsAndCommitmentsFromOthersFlag(false);
      });
  };
  return (
    <>
      <div className="justify-content-center d-flex">
        <h3>Donations</h3>
      </div>
      {donations.length > 0 ? (
        <ShowDonationsFromOthers donations={donations} type="Donation" />
      ) : (
        <>
          <h4 className="justify-content-center d-flex">
            No Donations to show
          </h4>
        </>
      )}
      <div className="justify-content-center d-flex">
        <h3>Commitments</h3>
      </div>
      {commitments.length > 0 ? (
        <ShowDonationsFromOthers donations={commitments} type="Commitment" />
      ) : (
        <>
          <h4 className="justify-content-center d-flex">
            No Commitments to show
          </h4>
        </>
      )}
    </>
  );
}

export default memo(GetDonationsAndCommitmentsFromOthers);
