import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import ReceiptMetaData from "./ReceiptMetaData";
import ReceiptMainData from "./ReceiptMainData";
import ReceiptFooter from "./ReceiptFooter";
import RobotoBold from "./fonts/Roboto-Bold.ttf";

Font.register({
  family: "Roboto",
  fonts: [{ src: RobotoBold, fontWeight: "bold" }],
});
const styles = StyleSheet.create({
  receiptText: {
    fontFamily: "Roboto",
    textDecoration: "underline",
    fontWeight: "bold",
  },
});

function ReceiptRightSection() {
  return (
    <>
      <View>
        <Text style={styles.receiptText}>{"RECEIPT"}</Text>
      </View>
      <View>
        <ReceiptMetaData />
      </View>
      <View>
        <ReceiptMainData />
      </View>
      <View style={{ position: "absolute", bottom: 5 }}>
        <ReceiptFooter />
      </View>
    </>
  );
}

export default ReceiptRightSection;
