import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import scssLogo from "../../img/BMSLogo.jpg";
import crestLogo from "../../img/crestLogo.png";
import LogoHeaderText from "./LogoHeaderText";
import { GetDonation } from "./DonationContextProvider";

const styles = StyleSheet.create({
  viewContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },

  imgStyle: {
    width: "17%",
    height: "auto",
  },
  viewHeader: {
    // flexDirection: "column",
    width: "100%",
    height: "auto",
    // flexGrow: 1,
  },
});

function LogoHeader() {
  const donation = GetDonation();
  const trust = donation.trust;

  return (
    <>
      <View style={styles.viewContainer}>
        <View style={styles.imgStyle}>
          <Image
            src={trust === "CREST" ? crestLogo : scssLogo}
            alt="Trust Logo"
          />
        </View>
        <View style={styles.viewHeader}>
          <LogoHeaderText />
        </View>
      </View>
    </>
  );
}

export default LogoHeader;
