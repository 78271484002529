import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { firestore } from "../firebase/config";

import * as AiReactIcon from "react-icons/ai";

import { ErrorMessage } from "@hookform/error-message";
import DonorRefList from "./DonorRefList";
import { getFYofDate } from "../pages/utilities/Utils";
import { useSession } from "../firebase/UserProvider";
export default memo(function EditCommitment() {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { user } = useSession();
  const params = useParams();
  const history = useHistory();
  const [donorRef, setDonorRef] = useState(""); //Store donor ref's id
  const [isLoading, setisLoading] = useState(false);
  const [totalCommitment, setTotalCommitment] = useState(0); //Total Commitment
  const [donorRefSearchQuery, setDonorRefSearchQuery] = useState(""); //To store the query string of other donor ref
  const [donorRefList, setDonorRefList] = useState(null); //To store the result of donor ref search
  const [donorRefOption, setDonorRefOption] = useState("");
  const [donorRefError, setDonorRefError] = useState(false);
  const [donationMode, setDonationMode] = useState("");
  const [purpose, setPurpose] = useState("");
  const [onRecord, setOnRecord] = useState(true);
  const [type] = useState("Commitment"); //To store donation type
  const [donationDate, setDonationDate] = useState(""); //To store donation date
  const [trust, setTrust] = useState("");
  const [oldCommitmentRefId, setOldCommitmentRefId] = useState("");
  const [raisedThroughPlatform, setRaisedThroughPlatform] = useState("");
  /**
   * Sets the bank ref date as that of donation date
   */
  const setBankRefDate = () => {
    setValue("bankRefDate", donationDate);
  };
  /**
   * Gets the month value in two digit format
   */
  const getMonthValue = (month) => {
    return month + 1 < 10 ? "0" + (month + 1) : month + 1;
  }; //End of getMonthValue

  /**
   * Gets the date value in the two digit format
   */
  const getDateValue = (date) => {
    return date < 10 ? "0" + date : date;
  }; //End of getDateValue
  const handleModeChange = (e) => {
    setDonationMode(e.target.value);
  };

  const updateDonorRefValue = (donorRefId, donorRefFullName) => {
    setDonorRef(donorRefId);
    setDonorRefList(null);
    setDonorRefSearchQuery(donorRefFullName);
  };

  const handleCollectedByOptions = (e) => {
    setDonorRefOption(e.target.value);
  }; //End of handleCollectedByOptions function

  const handleDonorRefSearch = async (e) => {
    e.preventDefault();
    setDonorRefList(null);
    setDonorRefError(false);

    let searchString = donorRefSearchQuery.toUpperCase();

    const query = firestore
      .collection(`donors`)
      .where("fullName", ">=", searchString)
      .orderBy("fullName")
      .limit(3);

    await query.get({ source: "server" }).then((querySnapshot) => {
      let donorRefList = [];

      querySnapshot.forEach((donorRef) => {
        donorRefList.push({
          donorId: donorRef.id,
          fullName: donorRef.data().fullName,
        });
      }); //End of querySnapshot.forEach()
      setDonorRefList(donorRefList);
    }); //End of then(querySnapshot => {})
  }; //end of const handleDonorRefSearch

  useEffect(() => {
    const donorId = params.donorId;
    const commitmentId = params.commitmentId;

    const docRef = firestore.collection("donors").doc(donorId);
    let iTotalCommitment;
    docRef.onSnapshot((snapshot) => {
      if (snapshot.exists) {
        const data = snapshot.data();
        iTotalCommitment =
          data.totalCommitment !== "" && data.totalCommitment !== undefined
            ? data.totalCommitment
            : 0;
        setTotalCommitment();
        setValue("fullName", data.fullName);
        setValue("pan", data.pan);
        setValue("spiritualName", data.spiritualName);

        setValue(
          "dob",
          data.dob !== "" && data.dob !== undefined ? data.dob.toDate() : ""
        );
        setValue("email", data.email);
        setValue("phone", data.phone);

        setValue(
          "addressLine1",
          data.address !== "" && data.address !== undefined
            ? data.address.addressLine1
            : ""
        );
        setValue(
          "addressLine2",
          data.address !== "" && data.address !== undefined
            ? data.address.addressLine2
            : ""
        );
        setValue(
          "city",
          data.address !== "" && data.address !== undefined
            ? data.address.city
            : ""
        );
        setValue(
          "pin",
          data.address !== "" && data.address !== undefined
            ? data.address.pin
            : ""
        );

        setValue(
          "state",
          data.address !== "" && data.address !== undefined
            ? data.address.state
            : ""
        );
        setValue(
          "country",
          data.address !== "" && data.address !== undefined
            ? data.address.country
            : ""
        );

        setValue("reference", data.reference);
      } //End of if(snapshot.exists)
    }); //End of const docRef ...

    docRef
      .collection("donations")
      .doc(commitmentId)
      .onSnapshot((commitmentSnapshot) => {
        if (commitmentSnapshot.exists) {
          //Set commitment data
          const commitmentData = commitmentSnapshot.data();

          setValue("amount", commitmentData.amount);
          let dDate = null;
          let sDate = "";
          dDate = commitmentData.date.toDate();

          if (dDate) {
            sDate =
              dDate.getFullYear() +
              "-" +
              getMonthValue(dDate.getMonth()) +
              "-" +
              getDateValue(dDate.getDate());

            setValue("date", sDate);
            setDonationDate(sDate);
          }

          setDonationMode(commitmentData.mode);
          setValue("bank", commitmentData.bank);
          setValue("bankRef", commitmentData.bankRef);
          let dateBankRefDate =
            commitmentData.bankRefDate !== "" &&
            commitmentData.bankRefDate !== undefined
              ? commitmentData.bankRefDate.toDate()
              : null;
          let strBankRefDate = "";
          if (dateBankRefDate) {
            strBankRefDate =
              dateBankRefDate.getFullYear() +
              "-" +
              getMonthValue(dateBankRefDate.getMonth()) +
              "-" +
              getDateValue(dateBankRefDate.getDate());

            setValue("bankRefDate", strBankRefDate);
          }

          setTrust(
            commitmentData.trust !== undefined ? commitmentData.trust : "CREST"
          );

          setDonorRefOption(
            commitmentData.collectedBy === "Self" ? "selfDonor" : "otherDonor"
          );
          setOnRecord(
            commitmentData.onRecord !== undefined
              ? commitmentData.onRecord
              : true
          );
          setValue(
            "comment",
            commitmentData.comment !== undefined ? commitmentData.comment : ""
          );
          setPurpose(
            commitmentData.purpose !== undefined
              ? commitmentData.purpose
              : "Object Of Trust"
          );
          setValue(
            "commissionAmount",
            commitmentData.commissionAmount !== undefined
              ? commitmentData.commissionAmount
              : ""
          );
          setValue(
            "raisedThroughPlatform",
            commitmentData.raisedThroughPlatform !== undefined
              ? commitmentData.raisedThroughPlatform
              : ""
          );
          setRaisedThroughPlatform(
            commitmentData.raisedThroughPlatform !== undefined
              ? commitmentData.raisedThroughPlatform
              : ""
          );
          setTotalCommitment(iTotalCommitment - commitmentData.amount);
          setOldCommitmentRefId(commitmentData.collectedBy);
          setValue("upiId", commitmentData.upiId);
          setValue("csrDonation", commitmentData.csrDonation);
          setValue(
            "receivedThroughWebsite",
            commitmentData.receivedThroughWebsite
          );
          if (commitmentData.collectedBy !== "Self") {
            //get other donor name
            firestore
              .collection("donors")
              .doc(commitmentData.collectedBy)
              .get()
              .then((resp) => {
                if (resp.exists) {
                  updateDonorRefValue(
                    commitmentData.collectedBy,
                    resp.data().fullName
                  );
                }
              });
          } //End of  if(donorRefOption === "otherDonor")
        } //End of commitmentSmapshotExists
      }); //End of donation snapshot
  }, []); //End of useEffect

  useEffect(() => {
    onRecord ? setDonationMode("Cheque") : setDonationMode("Cash");
  }, [onRecord]);

  const addRecord = async (donationData, donorReference, receiptNo) => {
    //Add the donation data to db

    await firestore
      .collection("donors")
      .doc(params.donorId)
      .collection("donations")
      .doc(params.commitmentId)
      .update({
        receiptNo: receiptNo,
        amount: donationData.amount,
        bank: donationData.bank !== undefined ? donationData.bank : "",
        bankRef: donationData.bankRef !== undefined ? donationData.bankRef : "",
        bankRefDate:
          donationData.bankRefDate !== undefined &&
          donationData.bankRefDate !== ""
            ? new Date(donationData.bankRefDate)
            : "",
        collectedBy: donorReference,
        date: new Date(donationData.date),
        mode: donationMode,
        trust: onRecord ? trust : "",
        created: new Date(),
        purpose: onRecord ? purpose : "",
        onRecord: onRecord,
        comment: donationData.comment,
        raisedThroughPlatform: donationData.raisedThroughPlatform,
        commissionAmount: donationData.commissionAmount,
        type: type,
        upiId: donationData.upiId === undefined ? "" : donationData.upiId,
        receivedThroughWebsite: donationData.receivedThroughWebsite,
        csrDonation: donationData.csrDonation,
        createdBy: user.email,
        updatedBy: user.email,
        updatedAt: new Date(),
      })
      .then(async (res) => {
        //Update the total commitment amount of the donor
        await firestore
          .collection("donors")
          .doc(params.donorId)
          .update({
            totalCommitment:
              parseInt(totalCommitment) + parseInt(donationData.amount),
          });

        //If the collected by other then update that other person's total collection
        if (donorReference !== oldCommitmentRefId) {
          if (donorReference !== "Self") {
            //Get total collection of new commitment reference
            const newDonorRef = firestore
              .collection("donors")
              .doc(donorReference);
            const donorRefDoc = await newDonorRef.get();
            if (donorRefDoc.exists) {
              let donorRefTotalCommitmentCollection =
                donorRefDoc.data().totalCommitmentCollection;
              //Update the total collection
              await firestore
                .collection("donors")
                .doc(donorReference)
                .update({
                  totalCommitmentCollection:
                    parseInt(donorRefTotalCommitmentCollection) +
                    parseInt(donationData.amount),
                });
            } //end of if(donorRefDoc.exists)
          }
          //Reduce the commmitment amount from old refernce donor
          //Get total collecton of old commitment reference
          firestore
            .collection("donors")
            .doc(oldCommitmentRefId)
            .get()
            .then(async (res) => {
              if (res.exists) {
                let oldCommitmentRefTotalCommitmentCollection =
                  res.data().totalCommitmentCollection;
                let newTotalCommitment =
                  parseInt(oldCommitmentRefTotalCommitmentCollection) -
                  parseInt(donationData.amount);
                //Update the total collection
                await firestore
                  .collection("donors")
                  .doc(oldCommitmentRefId)
                  .update({
                    totalCommitmentCollection:
                      newTotalCommitment < 0 ? 0 : newTotalCommitment,
                  });
              }
            }); //End of firestore.collection("donors").doc(oldCommitmentRefId).get().then
        } //end of if(donorRefOption === 'otherDonor' && donorReference !== params.donorId)
      })
      .catch((e) => console.log(`Error in adding ${type} record`)); //End of then(async (res)
  }; //End of addRecord

  /*Creates the donation record */
  const acceptDonation = async (donationData) => {
    let canProcess = false;

    //If the donor ref is self
    let donorReference = "";
    //The other option is selected but ref is not selected yet
    if (donorRefOption === "otherDonor" && donorRef === "") {
      setDonorRefError(true);
    } else {
      canProcess = true;
    } //End of else if(donorRefOption === 'selfDonor')
    if (canProcess) {
      setisLoading(true);

      if (donorRefOption === "otherDonor") {
        //If donor ref is other and selected a valid donor
        donorReference = donorRef;
      } else if (donorRefOption === "selfDonor") {
        //If donor ref is self, set the value as self
        donorReference = "Self";
      }

      //Get donation receipt no
      let receiptNo;
      if (type === "Donation" && onRecord) {
        let currentFY = getFYofDate(donationData.date);
        let seqNo;
        let seqNoId, recNo;
        await firestore
          .collection("donation-receipt-sequence-nos")
          .where("year", "==", currentFY)
          .where("trust", "==", donationData.trust)
          .get({ source: "server" })
          .then((sequenceNoQuery) => {
            if (!sequenceNoQuery.empty) {
              sequenceNoQuery.forEach((sNo) => {
                seqNo = sNo.data().seqNo;
                if (typeof seqNo === "string") {
                  recNo = parseInt(seqNo) + 1;
                } else {
                  recNo = seqNo + 1;
                }
                seqNoId = sNo.id;

                firestore
                  .collection("donation-receipt-sequence-nos")
                  .doc(seqNoId)
                  .update({
                    seqNo: recNo,
                  });
              });
            } else {
              //If receipt no doesn't exist, then add to db with
              recNo = 1;
              firestore.collection(`donation-receipt-sequence-nos`).add({
                seqNo: recNo,
                trust: donationData.trust,
                year: currentFY,
              });
            } //End of If-else  if (!sequenceNoQuery.empty)

            receiptNo = `${donationData.trust}/${currentFY}/${recNo}`;
            // }//End of if (type === "Donation")
            // else{
            addRecord(donationData, donorReference, receiptNo);
            // }
          }); //End of then((sequenceNoQuery)
      } else {
        addRecord(donationData, donorReference, "");
      }

      setisLoading(false);

      history.push(`/donordetails/${params.donorId}`); //Navigate back to donor details
    } //End of if(canProcess)
  }; //End of acceptDonation
  const formClass = `${isLoading ? "ui form loading" : ""}`;
  return (
    <div>
      <div className="card">
        <div className="justify-content-center d-flex">
          <h2>Edit Commitment</h2>
        </div>
      </div>
      <div className="card p-1">
        <form className={formClass} onSubmit={handleSubmit(acceptDonation)}>
          <label className="d-flex">
            <h4>Personal Details:</h4>
          </label>
          <div className="row mb-3">
            <div className="col-md-6">
              <label className="m-2">Donor Name:</label>
              <input
                type="text"
                className="form-control mr-2  p-2"
                id="fullName"
                name="fullName"
                {...register("fullName")}
                readOnly
              />
            </div>
            <div className="col-md-6">
              <label className="m-2">PAN:</label>
              <input
                type="input"
                className="form-control mr-2  p-2"
                {...register("pan")}
                readOnly
                name="pan"
              />
            </div>
          </div>
          <label className="d-flex">
            <h4>Communication Details:</h4>
          </label>
          <div className="row mb-3">
            <div className="col-md-6">
              <label className="m-2">Email:</label>
              <input
                type="text"
                className="form-control mr-2  p-2"
                id="email"
                name="email"
                {...register("email")}
                readOnly
              />
            </div>
            <div className="col-md-6">
              <label className="m-2">Phone:</label>
              <input
                type="text"
                className="form-control mr-2 p-2"
                name="phone"
                readOnly
                {...register("phone")}
              />
            </div>
          </div>
          <label className="d-flex">
            <h4>Address:</h4>
          </label>
          <div className="row mb-3">
            <div className="col-md-6">
              <label className="m-2">Address Line1:</label>
              <input
                type="text"
                className="form-control mr-2 p-2"
                id="addressLine1"
                name="addressLine1"
                readOnly
                {...register("addressLine1")}
              />
            </div>
            <div className="col-md-6">
              <label className="m-2">Address Line2:</label>
              <input
                type="text"
                className="form-control mr-2 p-2"
                name="addressLine2"
                readOnly
                {...register("addressLine2")}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label className="m-2">City:</label>
              <input
                type="text"
                className="form-control mr-2 p-2"
                id="city"
                name="city"
                readOnly
                {...register("city")}
              />
            </div>
            <div className="col-md-6">
              <label className="m-2">Pin Code:</label>
              <input
                type="text"
                className="form-control mr-2 p-2"
                name="pin"
                readOnly
                {...register("pin")}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label className="m-2">State:</label>
              <input
                type="text"
                className="form-control mr-2 p-2"
                id="state"
                name="state"
                readOnly
                {...register("state")}
              />
            </div>
            <div className="col-md-6">
              <label className="m-2">Country:</label>
              <input
                type="text"
                className="form-control mr-2 p-2"
                name="country"
                readOnly
                {...register("country")}
              />
            </div>
          </div>
          <label className="d-flex">
            <h4>Commitment Details:</h4>
          </label>
          <div className="row mb-3">
            <div className="col-md-4">
              <label className="m-2">Amount:</label>
              <input
                type="number"
                className="form-control mr-2 p-2"
                name="amount"
                {...register("amount", {
                  required: `Please enter a ${type} amount`,
                  min: {
                    value: 1,
                    message: `Minimum ${type} amount should be more than zero`,
                  },
                  validate: {
                    cashValidation: (value) =>
                      donationMode === "Cash" && onRecord ? value < 2001 : true,
                  },
                })}
              />

              {errors.amount?.type === "cashValidation" && (
                <p className="text-danger">
                  You cannot accept more than Rs 2000 as cash
                </p>
              )}
              <ErrorMessage
                errors={errors}
                name="amount"
                render={({ message }) => (
                  <p className="text-danger">{message}</p>
                )}
              />
            </div>
            <div className="col-md-4">
              <label className="m-2">{type} Date:</label>
              <input
                type="date"
                value={donationDate}
                className="form-control mr-2 p-2"
                name="date"
                {...register("date", {
                  required: `Please enter ${type} date.`,
                  validate: {
                    isCommitmentWithinAYear: (value) =>
                      Math.round(
                        (new Date(value) - new Date()) / (1000 * 60 * 60 * 24)
                      ) <= 365,
                    // isValidCommitmentDate: (value) =>
                    //   Math.round(
                    //     (new Date() - new Date(value)) * (1000 * 60 * 60 * 24)
                    //   ) <= 0,
                  },
                })}
                onChange={(e) => {
                  setDonationDate(e.target.value);
                }}
              />
              {errors.date?.type === "isCommitmentWithinAYear" && (
                <p className="text-danger">
                  {`Commitment date should be within one year`}
                </p>
              )}
              {errors.date?.type === "isValidCommitmentDate" && (
                <p className="text-danger">
                  {`Commitment date sould be future date`}
                </p>
              )}
              <ErrorMessage
                errors={errors}
                name="date"
                render={({ message }) => (
                  <p className="text-danger">{message}</p>
                )}
              />
            </div>
            <div className="col-md-4">
              <label className="m-2">Mode:</label>
              <br />
              <div className="row">
                {onRecord && (
                  <>
                    <div className="col-md-4">
                      <input
                        type="radio"
                        id="Cheque"
                        name="mode"
                        value="Cheque"
                        {...register("mode", {
                          required: `Please select ${type} mode.`,
                        })}
                        onChange={handleModeChange}
                        checked={donationMode === "Cheque"}
                      />
                      <label htmlFor="Cheque">Cheque</label>
                      <br />

                      <span>
                        <input
                          type="radio"
                          id="DD"
                          name="mode"
                          value="DD"
                          {...register("mode", {
                            required: `Please select ${type} mode.`,
                          })}
                          onChange={handleModeChange}
                          checked={donationMode === "DD"}
                        />{" "}
                        <label htmlFor="DD">DD</label>
                      </span>
                    </div>
                  </>
                )}
                <div className="col-md-4">
                  {onRecord && (
                    <span>
                      <input
                        type="radio"
                        id="NEFT"
                        name="mode"
                        value="NEFT"
                        {...register("mode", {
                          required: `Please select ${type} mode.`,
                        })}
                        onChange={handleModeChange}
                        checked={donationMode === "NEFT"}
                      />{" "}
                      <label htmlFor="NEFT">NEFT</label>
                      <br />
                    </span>
                  )}
                  <input
                    type="radio"
                    id="Cash"
                    name="mode"
                    value="Cash"
                    {...register("mode", {
                      required: `Please select ${type} mode.`,
                    })}
                    onChange={handleModeChange}
                    checked={donationMode === "Cash"}
                  />{" "}
                  <label htmlFor="Cash">Cash</label>
                  <br />
                  <ErrorMessage
                    errors={errors}
                    name="mode"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </div>
                {onRecord && (
                  <div className="col-md-4">
                    <>
                      <span>
                        <input
                          type="radio"
                          id="UPI"
                          name="mode"
                          value="UPI"
                          {...register("mode", {
                            required: `Please select ${type} mode.`,
                          })}
                          onChange={handleModeChange}
                          checked={donationMode === "UPI"}
                        />{" "}
                        <label htmlFor="UPI">UPI</label>
                      </span>
                      <br />
                      <span>
                        <input
                          type="radio"
                          id="ACH"
                          name="mode"
                          value="ACH"
                          {...register("mode", {
                            required: `Please select ${type} mode.`,
                          })}
                          onChange={handleModeChange}
                          checked={donationMode === "ACH"}
                        />{" "}
                        <label htmlFor="ACH">ACH</label>
                      </span>
                    </>
                  </div>
                )}
              </div>
            </div>
          </div>
          {donationMode !== "Cash" && donationMode !== "Other" && (
            <div className="row mb-3">
              <div className="col-md-4">
                <label className="m-2">Bank Name:</label>
                <input
                  type="text"
                  className="form-control mr-2 p-2"
                  id="bank"
                  name="bank"
                  {...register("bank")}
                />
              </div>
              <div className="col-md-4">
                <label className="m-2">{donationMode} Reference:</label>
                <input
                  type="text"
                  className="form-control mr-2 p-2"
                  id="bankRef"
                  name="bankRef"
                  {...register("bankRef")}
                />
              </div>
              <div className="col-md-4">
                <label className="m-2">{donationMode} Date:</label>
                <span>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={setBankRefDate}
                    disabled={donationDate === ""}
                  >
                    Same as of {type} Date
                  </button>
                </span>
                <input
                  type="date"
                  className="form-control mr-2 p-2"
                  id="bankRefDate"
                  name="bankRefDate"
                  {...register("bankRefDate")}
                />
                {errors.bankRefDate?.type === "validateBankRef" && (
                  <p className="text-danger">
                    {(donationMode === "Cheque" || donationMode === "DD") &&
                      `${donationMode} date cannot be 90 days before donation date`}
                  </p>
                )}
                {errors.bankRefDate?.type ===
                  "isBankRefDateBeforeDonationDate" && (
                  <p className="text-danger">
                    {type === "Donation"
                      ? `${donationMode} date cannot be after donation date`
                      : `${donationMode} date cannot be before commitment date`}
                  </p>
                )}
                <ErrorMessage
                  errors={errors}
                  name="bankRefDate"
                  render={({ message }) => (
                    <p className="text-danger">{message}</p>
                  )}
                />
              </div>
            </div>
          )}
          <label className="d-flex">
            <h4>Additional Details:</h4>
          </label>

          <div className="row mb-3">
            {onRecord && (
              <div className="col-md-4">
                <label className="m-2">To Trust:</label>
                <br></br>
                <input
                  type="radio"
                  id="SCSS"
                  name="trust"
                  value="SCSS"
                  checked={trust === "SCSS"}
                  {...register("trust", {
                    required: `Please select the trust to which the ${type} is made.`,
                  })}
                />{" "}
                <label htmlFor="SCSS">Sri Chaitanya Shikshan Sanstha</label>
                <br />
                <input
                  type="radio"
                  id="CREST"
                  name="trust"
                  value="CREST"
                  checked={trust === "CREST"}
                  {...register("trust", {
                    required: `Please select the trust to which the ${type} is made.`,
                  })}
                />{" "}
                <label htmlFor="CREST">
                  Chaitanya Research & Edcational Services Trust
                </label>{" "}
                <br />
                <ErrorMessage
                  errors={errors}
                  name="trust"
                  render={({ message }) => (
                    <p className="text-danger">{message}</p>
                  )}
                />
              </div>
            )}
            <div className="col-md-4">
              <label className="m-2">Collected By:</label>
              <br />
              <input
                type="radio"
                id="selfDonor"
                name="collectedBy"
                value="selfDonor"
                checked={donorRefOption === "selfDonor"}
                onChange={handleCollectedByOptions}
              />{" "}
              <label htmlFor="selfDonor">Self</label>
              <br />
              <input
                type="radio"
                id="otherDonor"
                name="collectedBy"
                value="otherDonor"
                checked={donorRefOption === "otherDonor"}
                onChange={handleCollectedByOptions}
              />{" "}
              <label htmlFor="otherDonor">Other</label>
            </div>
            <div className="col-md-4">
              {donorRefOption === "otherDonor" ? (
                <div>
                  <label className="m-2">
                    If collected by is "Other", please search the collector's
                    name here. Enter the collector name & click on magnifier
                    glass icon.
                  </label>
                  <br />
                  {!donorRef && (
                    <label className="m-2">Collector is not selected yet</label>
                  )}
                  {donorRef && (
                    <label className="m-2">Collector is now selected</label>
                  )}
                  <br />
                  <input
                    placeholder="Search Here"
                    name="searchRef"
                    value={donorRefSearchQuery}
                    onChange={(e) => setDonorRefSearchQuery(e.target.value)}
                  />
                  <button
                    className="btn btn-outline"
                    type="button"
                    onClick={handleDonorRefSearch}
                  >
                    <AiReactIcon.AiOutlineSearch />
                    {/* <AiReactIcon.FaBars /> */}
                  </button>
                  <br />
                  {donorRefList && (
                    <DonorRefList
                      queryList={donorRefList}
                      updateDonorRefValue={updateDonorRefValue}
                    />
                  )}
                </div>
              ) : (
                ""
              )}
              {donorRefError && (
                <div className="text-danger">
                  Please select a reference for the {type}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md">
                    <label className="m-2" htmlFor="onRecord">
                      On Record:
                    </label>
                    <input
                      type="checkbox"
                      name="onRecord"
                      id="onRecord"
                      value={onRecord}
                      onChange={(e) => setOnRecord(!onRecord)}
                      checked={onRecord}
                    ></input>
                  </div>
                </div>
                {onRecord && (
                  <div className="row">
                    <div className="col-md">
                      <label className="m-2" htmlFor="receivedThroughWebsite">
                        Received through Website:
                      </label>
                      <input
                        type="checkbox"
                        name="receivedThroughWebsite"
                        id="receivedThroughWebsite"
                        {...register("receivedThroughWebsite")}
                      ></input>
                    </div>
                  </div>
                )}
                {onRecord && (
                  <div className="row">
                    <div className="col-md">
                      <label className="m-2" htmlFor="csrDonation">
                        CSR Donation:
                      </label>
                      <input
                        type="checkbox"
                        name="csrDonation"
                        id="csrDonation"
                        {...register("csrDonation")}
                      ></input>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <label className="m-2">Comment:</label>
              <br />
              <textarea
                name="comment"
                id="comment"
                {...register("comment", {})}
              ></textarea>
            </div>
            {onRecord && (
              <div className="col-md-4">
                {" "}
                <label className="m-2">Purpose:</label>
                <br></br>
                <input
                  type="radio"
                  id="ObjectOfTrust"
                  name="ObjectOfTrust"
                  value="Object Of Trust"
                  checked={purpose === "Object Of Trust"}
                  onChange={(e) => setPurpose(e.target.value)}
                  {...register("purpose")}
                />{" "}
                <label htmlFor="CREST">Object of the trust</label> <br />
                <input
                  type="radio"
                  id="trustCorpus"
                  name="trustCorpus"
                  value="Trust Corpus"
                  checked={purpose === "Trust Corpus"}
                  onChange={(e) => setPurpose(e.target.value)}
                  {...register("purpose")}
                />{" "}
                <label htmlFor="SCSS">Trust Corpus</label>
              </div>
            )}
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <label className="m-2" htmlFor="Installment">
                Raised through Platform:
              </label>
              <br />
              <select
                className="m-2"
                {...register("raisedThroughPlatform")}
                onChange={(e) => setRaisedThroughPlatform(e.target.value)}
              >
                <option value="" selected></option>
                <option value="Cashfree">Cashfree</option>
                <option value="Facebook">Facebook</option>
                <option value="Give India">Give India</option>
                <option value="Milap">Milap</option>
                <option value="Patron Care">Patron Care</option>
              </select>
            </div>
            <div className="col-md-3">
              <label className="m-2">Commision Amount:</label>
              <br />
              <input
                type="number"
                min="0"
                required={raisedThroughPlatform !== ""}
                readOnly={raisedThroughPlatform === ""}
                id="commissionAmount"
                name="commissionAmount"
                className="form-control mr-2  p-2"
                {...register("commissionAmount")}
              />
            </div>
            <div className="col-md-3">
              {donationMode === "UPI" && (
                <>
                  <label className="m-2" htmlFor="upiId">
                    UPI Id:
                  </label>
                  <br />
                  <input
                    type="text"
                    id="upiId"
                    name="upiId"
                    className="form-control mr-2  p-2"
                    {...register("upiId", {
                      required: "Please enter UPI Id",
                      validate: { isValidUPI: (value) => value.includes("@") },
                    })}
                  />
                  {errors.upiId?.type === "isValidUPI" && (
                    <p className="text-danger">Please enter a valid UPI Id</p>
                  )}
                  <ErrorMessage
                    errors={errors}
                    name="upiId"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </>
              )}
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row mb-3">
            <div className="col justify-content-center d-flex">
              <button type="submit" className="btn btn-primary mr-2">
                Update {type}
              </button>{" "}
              <button
                type="button"
                className="btn btn-secondary ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/donordetails/${params.donorId}`);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
});
