import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { firestore } from "../../firebase/config";
import { useParams } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from "react-router";
import { useSession } from "../../firebase/UserProvider";
function EventDetail() {
  const [isLoading, setisLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [sectionError, setsectionError] = useState(false);
  const [ayOptions, setAyOptions] = useState(null);
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const { user } = useSession();
  const formClass = `${isLoading ? "ui form loading" : ""}`;
  const params = useParams();
  const history = useHistory();
  const [selectedAY, setSelectedAY] = useState(null);
  /**
   * Start date should be greater than or equal to today
   * if yes
   * @param {} value
   */
  const validateStartDate = (value) => {
    let dValue = new Date(value);
    let tDate = new Date();
    return dValue >= tDate;
  }; //end of validateStartDate

  /**
   * End date should be greater than or equal to start date
   * @param {*} value
   */
  const validateEndDate = (value) => {
    let sDate = new Date(startDate);
    let eDate = new Date(value);
    return sDate <= eDate;
  };

  /**
   * Gets the month value in two digit format
   */
  const getMonthValue = (month) => {
    return month + 1 < 10 ? "0" + (month + 1) : month + 1;
  }; //End of getMonthValue

  /**
   * Gets the date value in the two digit format
   */
  const getDateValue = (date) => {
    return date < 10 ? "0" + date : date;
  }; //End of getDateValue

  /**
   * Updates the event details back to Firebase
   */
  const updateEvent = async (data) => {
    setsectionError(false);

    let isPrePrimary = data.isPrePrimary;
    let isPrimary = data.isPrimary;
    let isUpperPrimary = data.isUpperPrimary;
    let isSecondary = data.isSecondary;
    if (isPrePrimary || isPrimary || isUpperPrimary || isSecondary) {
      setisLoading(true);
      console.log(data);
      let id = params.eventId;
      await firestore
        .collection("academic-calendar-events")
        .doc(id)
        .update({
          title: data.eventTitle,
          type: data.eventType,
          start: new Date(data.startDate),
          end: new Date(data.endDate),
          isPrePrimary: data.isPrePrimary,
          isPrimary: data.isPrimary,
          isUpperPrimary: data.isUpperPrimary,
          isSecondary: data.isSecondary,
          updatedAt: new Date(),
          updatedBy: user.email,
          academicYear: data.ay,
        })
        .then((docRef) => {
          setisLoading(false);
          alert("The event has been updated");
          history.push("/");
        });
    } else {
      setsectionError(true);
    }
  }; //End of updateEvent
  useEffect(async () => {
    //Get Event details
    let id = params.eventId;

    await firestore
      .collection("academic-calendar-events")
      .doc(id)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.data();
          let dStart = data.start.toDate();
          let sStart =
            dStart.getFullYear() +
            "-" +
            getMonthValue(dStart.getMonth()) +
            "-" +
            getDateValue(dStart.getDate());

          setStartDate(sStart);

          let dEnd = data.end.toDate();
          let sEnd =
            dEnd.getFullYear() +
            "-" +
            getMonthValue(dEnd.getMonth()) +
            "-" +
            getDateValue(dEnd.getDate());
          setValue("eventTitle", data.title);
          setValue("startDate", sStart);
          setValue("endDate", sEnd);
          setValue(
            "isPrePrimary",
            data.isPrePrimary !== undefined ? data.isPrePrimary : false
          );
          setValue(
            "isPrimary",
            data.isPrimary !== undefined ? data.isPrimary : false
          );
          setValue(
            "isUpperPrimary",
            data.isUpperPrimary !== undefined ? data.isUpperPrimary : false
          );
          setValue(
            "isSecondary",
            data.isSecondary !== undefined ? data.isSecondary : false
          );
          setValue("eventType", data.type);
          setValue("ay", data.academicYear);
          setSelectedAY(data.academicYear);
        }
      });

    //Get list of academic years
    firestore
      .collection("academic-years")
      .orderBy("name", "asc")
      .get({ source: "server" })
      .then((result) => {
        if (!result.empty) {
          let tempAY = [];

          result.forEach((academicYear) => {
            console.log("Result:");
            console.log(academicYear.data());
            tempAY.push({
              id: academicYear.id,
              name: academicYear.data().name,
            }); //End of tempAY.push()
          }); //result.forEach()
          setAyOptions(tempAY);
        } //End of if(!result.empty)
      }); //End of then
  }, []); //End of useEffect

  return (
    <>
      <div className="card p-1 mb-1">
        <div className="justify-content-center d-flex">
          <h2>Event Details</h2>
        </div>
      </div>
      <div className="card p-1">
        <form className={formClass} onSubmit={handleSubmit(updateEvent)}>
          <label className="d-flex">
            <p className="text-danger">Fields marked * are mandatory</p>
          </label>
          <label className="d-flex">
            <h4 className="p-2">Event Details:</h4>
          </label>
          <div className="row">
            <div className="col-md-6">
              <label className="m-2">
                Event Title:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                placeholder="Please enter event title here"
                className="form-control ml-2"
                id="eventTitle"
                name="eventTitle"
                {...register("eventTitle", {
                  required: "Please enter event title",
                  required: true,
                  minLength: {
                    value: 4,
                    message:
                      "Please enter at least 4 characters for event title",
                  },
                })}
              />
              {errors.eventTitle?.message && (
                <p className="text-danger">{errors.eventTitle?.message}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="m-2">
                Type of event:<span className="text-danger">*</span>
              </label>
              <select
                className="form-control ml-2"
                id="eventType"
                name="eventType"
                {...register("eventType", {
                  required: true,
                })}
              >
                <option value="School Event">School Event</option>
                <option value="Exam">Exam</option>
                <option value="Holiday">Holiday</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="m-2">
                Start Date: <span className="text-danger">*</span>
              </label>
              <input
                type="date"
                name="startDate"
                id="startDate"
                className="form-control ml-2"
                {...register("startDate", {
                  required: "Please enter start date",
                  validate: {
                    validStartDate: (value) => validateStartDate(value),
                  },
                })}
                onChange={(e) => {
                  e.preventDefault();
                  setStartDate(e.target.value);
                }}
              />
              {errors.startDate?.type === "validStartDate" && (
                <p className="text-danger">
                  Start date should be either today or of future only.
                </p>
              )}
              <ErrorMessage
                errors={errors}
                name="startDate"
                render={({ message }) => (
                  <p className="text-danger">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="m-2">
                End Date:<span className="text-danger">*</span>
              </label>
              <input
                type="date"
                name="endDate"
                id="endDate"
                className="form-control ml-2"
                {...register("endDate", {
                  required: "Please enter end date",
                  validate: {
                    validEndDate: (value) => validateEndDate(value),
                  },
                })}
              />
              {errors.endDate?.type === "validEndDate" && (
                <p className="text-danger">
                  End date should be either same or after start date.
                </p>
              )}
              <ErrorMessage
                errors={errors}
                name="endDate"
                render={({ message }) => (
                  <p className="text-danger">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="d-flex">
                <h5 className="p-2">
                  Applicable To:<span className="text-danger">*</span>
                </h5>
              </label>
            </div>
          </div>
          <div className="row ml-2">
            <div className="col">
              <input
                type="checkbox"
                name="isPrePrimary"
                id="isPrePrimary"
                {...register("isPrePrimary")}
              />
              <label className="m-2" htmlFor="isPrePrimary">
                Pre-Primary
              </label>
            </div>
          </div>
          <div className="row ml-2">
            <div className="col">
              <input
                type="checkbox"
                name="isPrimary"
                id="isPrimary"
                {...register("isPrimary")}
              />
              <label className="m-2" htmlFor="isPrimary">
                Primary
              </label>
            </div>
          </div>
          <div className="row ml-2">
            <div className="col">
              <input
                type="checkbox"
                name="isUpperPrimary"
                id="isUpperPrimary"
                {...register("isUpperPrimary")}
              />
              <label className="m-2" htmlFor="isUpperPrimary">
                Upper-Primary
              </label>
            </div>
          </div>
          <div className="row ml-2">
            <div className="col">
              <input
                type="checkbox"
                name="isSecondary"
                id="isSecondary"
                {...register("isSecondary")}
              />
              <label className="m-2" htmlFor="isSecondary">
                Secondary
              </label>
            </div>
          </div>
          {sectionError && (
            <div className="row ml-2">
              <div className="col">
                <p className="text-danger">
                  Please select at least one section
                </p>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-6">
              <select
                className="form-control ml-2"
                id="ay"
                name="ay"
                {...register("ay", {
                  required: true,
                })}
              >
                {ayOptions !== null &&
                  ayOptions.map((op) => {
                    return op.name === selectedAY ? (
                      <option selected value={op.name} key={op.id}>
                        {op.name}
                      </option>
                    ) : (
                      <option value={op.name} key={op.id}>
                        {op.name}
                      </option>
                    );
                    // return (
                    // <option value={op.name} key={op.id}>
                    //   {op.name}
                    // </option>
                    // );
                  })}
              </select>
            </div>
          </div>
          <div className="row m-3">
            <div className="col">
              <button
                type="submit"
                id="save"
                name="save"
                className="btn btn-primary m-2"
              >
                Update
              </button>{" "}
              <button
                type="button"
                className="btn btn-secondary m-2"
                onClick={(e) => {
                  e.preventDefault();
                  reset();
                  history.push(`/`);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default EventDetail;
