import React, { memo, useState } from "react";
import GetReferenceDetails from "./GetReferenceDetails";
import { SiConvertio } from "react-icons/si";
import { RiDeleteBin2Fill, RiEdit2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { getDateInFormat, getFYofDate } from "./utilities/Utils";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DonationReceipt from "./donation-receipt/DonationReceipt";
import MyDialog from "./MyDialog";
import { firestore } from "../firebase/config";
import { useSession } from "../firebase/UserProvider";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
export default memo(function DonationDetails({
  donations,
  donorDetails,
  donationType,
}) {
  const { user } = useSession();
  const [canOpenConvertDialog, setCanOpenConvertDialog] = useState(false);
  const [currentCommitment, setCurrentCommitment] = useState(null);
  const [canOpenDeleteDialog, setCanOpenDeleteDialog] = useState(false);

  const handleConvertCommitmentDialogClose = () =>
    setCanOpenConvertDialog(false);

  const handleDeleteCommitmentDialogClose = () => setCanOpenDeleteDialog(false);

  //Delete Commitment
  //Update Commitment amount of the donor
  //If commitment reference is other than the donor then update that donor's totalCollectionCommitment
  const deleteCommitment = async () => {
    setCanOpenDeleteDialog(false);

    await firestore
      .collection("donors")
      .doc(donorDetails.id)
      .collection("donations")
      .doc(currentCommitment.id)
      .delete()
      .then(async () => {
        //Update Commitment amount of the donor
        let donorTotalCommitment = donorDetails.totalCommitment;
        let newDonorTotalCommitment =
          parseInt(donorTotalCommitment) - parseInt(currentCommitment.amount);

        await firestore
          .collection("donors")
          .doc(donorDetails.id)
          .update({ totalCommitment: newDonorTotalCommitment });

        //If commitment reference is other than the donor then update that donor's totalCollectionCommitment
        if (currentCommitment.collectedBy !== "Self") {
          let refDonorId = currentCommitment.collectedBy;
          //Get the values of refernce donors total commitment from others and total donations from otheres
          const refDonorDoc = await firestore
            .collection("donors")
            .doc(refDonorId)
            .get();
          if (refDonorDoc.exists) {
            const refDonorData = refDonorDoc.data();
            parseInt(refDonorData.totalCommitmentCollection);
            let totalRefCommitmentCollection = parseInt(
              refDonorData.totalCommitmentCollection
            );

            await firestore
              .collection("donors")
              .doc(refDonorId)
              .update({
                totalCommitmentCollection:
                  totalRefCommitmentCollection -
                  parseInt(currentCommitment.amount),
              });
          } //End of  if (refDonorDoc.exists)
        } //end of if (currentCommitment.collectedBy !== "Self")

        //End of promise
      })
      .catch((e) => {
        console.log(`Error while deleting commitment: ${e}`);
      });
  }; //End of deleteCommitment

  /**
   * Updates the record in the db
   */
  const updateRecord = async (receiptNo) => {
    //Update the
    //type to "donation"
    //and receipt no

    await firestore
      .collection("donors")
      .doc(donorDetails.id)
      .collection("donations")
      .doc(currentCommitment.id)
      .update({
        type: "Donation",
        receiptNo: receiptNo,
        updatedAt: new Date(),
        updatedBy: user.email,
      })
      .then(async () => {
        //Update total donaiton and total commitment amount of the donor
        let currenttotalDonation = donorDetails.totalDonation;
        let donationAmount = currentCommitment.amount;

        await firestore
          .collection("donors")
          .doc(donorDetails.id)
          .update({
            totalDonation:
              parseInt(currenttotalDonation) + parseInt(donationAmount),
          })
          .then()
          .catch((e) =>
            console.log(
              `Error while updating donor's total donation after convering the commitment to donation: ${e}`
            )
          );

        //If the donation is collected by other donor, update his total commitment from others and total
        if (currentCommitment.collectedBy !== "Self") {
          let refDonorId = currentCommitment.collectedBy;
          //Get the values of refernce donors total commitment from others and total donations from otheres
          const refDonorDoc = await firestore
            .collection("donors")
            .doc(refDonorId)
            .get();
          if (refDonorDoc.exists) {
            const refDonorData = refDonorDoc.data();
            let totalRefCollection = parseInt(refDonorData.totalCollection);

            await firestore
              .collection("donors")
              .doc(refDonorId)
              .update({
                totalCollection: totalRefCollection + parseInt(donationAmount),
              });
          } //End of  if (refDonorDoc.exists)
        } //End oif (currentCommitment.collectedBy !== "Self")
        //End of promise
      })
      .catch((e) => {
        console.log(`Error in converting commitment to donation: ${e}`);
      })
      .finally();
  }; //End if updateRecord
  //Converts commitment to donation
  //Checks: Donation date is today or in past
  //Update total donation and commitment
  const convertCommitmentToDonation = async () => {
    setCanOpenConvertDialog(false);

    let tDate = new Date();
    let dDate = new Date(currentCommitment.date);
    let canConvert = false;
    if (dDate.getTime() > tDate.getTime()) {
      alert("Future commitments cannot be converted to donation.");
    } else if (currentCommitment.onRecord) {
      if (
        currentCommitment.mode !== "Cash" &&
        (currentCommitment.bankRef === undefined ||
          currentCommitment.bankRefDate === "" ||
          currentCommitment.bankRefDate === undefined ||
          currentCommitment.bankRefDate === null)
      ) {
        alert(
          "Please enter all transacton details like transaction reference no and bank reference date"
        );
        canConvert = false;
      } else if (currentCommitment.mode === "UPI") {
        if (
          currentCommitment.upiId === "" ||
          currentCommitment.upiId === undefined
        ) {
          alert("Please update the UPI Id");
          canConvert = false;
        } else {
          canConvert = true;
        }
      } else {
        canConvert = true;
      }
    } else {
      canConvert = true;
    } //End of if else - if(dDate.getTime() <= tDate.getTime())
    if (canConvert) {
      //Get receipt no
      let receiptNo = "";

      if (currentCommitment.onRecord) {
        let currentFY = getFYofDate(currentCommitment.date);
        let seqNo;
        let seqNoId, recNo;
        await firestore
          .collection("donation-receipt-sequence-nos")
          .where("year", "==", currentFY)
          .where("trust", "==", currentCommitment.trust)
          .get({ source: "server" })
          .then((sequenceNoQuery) => {
            if (!sequenceNoQuery.empty) {
              sequenceNoQuery.forEach((sNo) => {
                seqNo = sNo.data().seqNo;
                if (typeof seqNo === "string") {
                  recNo = parseInt(seqNo) + 1;
                } else {
                  recNo = seqNo + 1;
                }
                seqNoId = sNo.id;

                firestore
                  .collection("donation-receipt-sequence-nos")
                  .doc(seqNoId)
                  .update({
                    seqNo: recNo,
                  });
              });
            } else {
              //If receipt no doesn't exist, then add to db with
              recNo = 1;
              firestore.collection(`donation-receipt-sequence-nos`).add({
                seqNo: recNo,
                trust: currentCommitment.trust,
                year: currentFY,
              });
            } //End of If-else  if (!sequenceNoQuery.empty)
            receiptNo = `${currentCommitment.trust}/${currentFY}/${recNo}`;

            updateRecord(receiptNo);
          })
          .catch((e) =>
            console.log(
              `Error in getting receipt no while converting commitment to donation: ${e}`
            )
          );
      } else {
        updateRecord(receiptNo);
      }
    } //End of if(canConvert)
  }; //End of convertCommitmentToDonation

  const pdfFile = React.createRef();
  const donationList = donations.map((donation) => {
    return (
      <>
        {donationType === donation.type ? (
          <tr key={donation.id}>
            <td>{getDateInFormat(donation.date)}</td>
            <td>{donation.amount}</td>
            <td>{donation.bank}</td>
            <td>{donation.bankRef}</td>
            <td>{getDateInFormat(donation.bankRefDate)}</td>
            <td>
              {donation.collectedBy === "Self" ? (
                donorDetails.fullName
              ) : (
                <GetReferenceDetails
                  refId={donation.collectedBy}
                  needLink={true}
                />
              )}
            </td>{" "}
            <td>{donation.trust}</td>
            <td>{donation.mode}</td>
            {donationType === "Donation" && (
              <td>
                {donation.upiId !== "" && donation.upiId !== undefined
                  ? donation.upiId
                  : "N/A"}
              </td>
            )}
            <td>{donationType === "Donation" ? donation.receipt : ""}</td>
            <td>
              {donationType === "Donation" ? (
                donation.onRecord ? (
                  <PDFDownloadLink
                    document={
                      <DonationReceipt
                        donation={donation}
                        donor={donorDetails}
                        user={user}
                      />
                    }
                    fileName={`${donorDetails.fullName.replace(".", "")}_${
                      donation.id
                    }`}
                    ref={pdfFile}
                    className="btn btn-link"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? "Loading document..." : "Download here!"
                    }
                  </PDFDownloadLink>
                ) : (
                  <>
                    <div className="text-center">Not Available</div>
                  </>
                )
              ) : (
                <>
                  <button
                    className="btn btn-outline"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentCommitment(donation);
                      setCanOpenConvertDialog(true);
                    }}
                  >
                    <SiConvertio />
                  </button>
                  <MyDialog
                    open={canOpenConvertDialog}
                    handleClose={handleConvertCommitmentDialogClose}
                    dialogTitle="Commitment Conversion Confirmation"
                    dialogText="Are you sure you want to convert this commitment to donation?"
                    handleDefault={convertCommitmentToDonation}
                    defaultBtnText="Yes"
                    cancelBtnText="No"
                  />

                  <button
                    className="btn btn-outline"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentCommitment(donation);
                      setCanOpenDeleteDialog(true);
                    }}
                  >
                    <RiDeleteBin2Fill />
                  </button>
                  <MyDialog
                    open={canOpenDeleteDialog}
                    handleClose={handleDeleteCommitmentDialogClose}
                    dialogTitle="Commitment Delete Confirmation"
                    dialogText="Are you sure you want to delete this commitment?"
                    handleDefault={deleteCommitment}
                    defaultBtnText="Yes"
                    cancelBtnText="No"
                  />
                  <Link
                    to={`/editcommitment/${donorDetails.id}/${donation.id}`}
                  >
                    <RiEdit2Line />
                  </Link>
                </>
              )}
            </td>
          </tr>
        ) : null}
      </>
    );
  });
  const columns = [
    { dataField: "date", text: "Donation Date", sort: true },
    { dataField: "amount", text: "Amount", sort: true },
    { dataField: "bank", text: "Bank", sort: true },
    { dataField: "bankRef", text: "Bank Reference", sort: true },
    { dataField: "bankRefDate", text: "Bank Refence Date", sort: true },
    { dataField: "mode", text: "Mode", sort: true },
    { dataField: "upiId", text: "UPI Id", sort: true },
    { dataField: "collectedBy", text: "Collected by", sort: true },
    { dataField: "trust", text: "To Trust", sort: true },
    {
      dataField: donationType === "Donation" ? "receipt" : "action",
      text: donationType === "Donation" ? "Receipt" : "Action",
      sort: true,
    },
  ];
  const defaultSorted = [
    {
      dataField: "date", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];
  return (
    <>
      <BootstrapTable
        columns={columns}
        data={donations}
        keyField="id"
        striped
        hover
        condensed
        defaultSorted
        wrapperClasses="table-responsive"
        pagination={paginationFactory({
          sizePerPageList: [
            {
              text: "5",
              value: 5,
            },
            {
              text: "10",
              value: 10,
            },
            {
              text: "All",
              value: donations.length,
            },
          ],
          hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
          paginationSize: 5,
          
          alwaysShowAllBtns: true, // always show the next and previous page button
          firstPageText: "First", // the text of first page button
          prePageText: "Prev", // the text of previous page button
          nextPageText: "Next", // the text of next page button
          lastPageText: "Last", // the text of last page button
          nextPageTitle: "Go to next", // the title of next page button
          prePageTitle: "Go to previous", // the title of previous page button
          firstPageTitle: "Go to first", // the title of first page button
          lastPageTitle: "Go to last", // the title of last page button
        })}
      />
    </>
  );
});
