import React, { memo, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { firestore } from "../firebase/config";
import { ErrorMessage } from "@hookform/error-message";
import DonorRefList from "./DonorRefList";
import * as AiReactIcon from "react-icons/ai";
import { useSession } from "../firebase/UserProvider";
export default memo(function AddCommitment() {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const params = useParams();
  const history = useHistory();

  const [donorRef, setDonorRef] = useState(""); //Store donor ref's id
  const [isLoading, setisLoading] = useState(false);
  const [donorRefSearchQuery, setDonorRefSearchQuery] = useState(""); //To store the query string of other donor ref
  const [donorRefList, setDonorRefList] = useState(null); //To store the result of donor ref search
  const [donorRefOption, setDonorRefOption] = useState("selfDonor");
  const [donorRefError, setDonorRefError] = useState(false);
  const [donationMode, setDonationMode] = useState("Cheque");
  const [type, setType] = useState("One Time");
  const [installmentAmount, setInstallmentAmount] = useState();
  const [noOfInstallments, setNoOfInstallments] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [donationDate, setDonationDate] = useState("");
  const [totalCommitment, setTotalCommitment] = useState(0);
  const [onRecord, setOnRecord] = useState(true);
  const [frequency, setFrequency] = useState("Month");
  const [raisedThroughPlatform, setRaisedThroughPlatform] = useState("");
  const { user } = useSession();

  const handleModeChange = (e) => {
    setDonationMode(e.target.value);
  };

  const updateDonorRefValue = (donorRefId, donorRefFullName) => {
    setDonorRef(donorRefId);
    setDonorRefList(null);
    setDonorRefSearchQuery(donorRefFullName);
  };

  /**
   * Check if the commitment doesn't go beyond 5 years as per the no of installments and installment amount
   * if yes, return true
   * @param {*} e
   */
  const validateNoOfInstallments = () => {
    let noOfYearsAllowed = 5;

    let installmentsPerYear = 0;
    switch (frequency) {
      case "Month":
        installmentsPerYear = 12;
        break;
      case "Quarter":
        installmentsPerYear = 4;
        break;
      case "Half-year":
        installmentsPerYear = 2;
        break;
      case "Year":
        installmentsPerYear = 1;
        break;

      default:
        break;
    }
    return noOfInstallments <= noOfYearsAllowed * installmentsPerYear;
  };
  const handleCollectedByOptions = (e) => {
    setDonorRefOption(e.target.value);
  }; //End of handleCollectedByOptions function

  const handleDonorRefSearch = async (e) => {
    e.preventDefault();
    setDonorRefError(false);

    const query = firestore
      .collection(`donors`)
      .where("fullName", ">=", donorRefSearchQuery)
      .orderBy("fullName")
      .limit(3);

    await query.get({ source: "server" }).then((querySnapshot) => {
      let donorRefList = [];

      querySnapshot.forEach((donorRef) => {
        donorRefList.push({
          donorId: donorRef.id,
          fullName: donorRef.data().fullName,
        });
      }); //End of querySnapshot.forEach()
      setDonorRefList(donorRefList);
    }); //End of then(querySnapshot => {})
  }; //end of const handleDonorRefSearch

  useEffect(() => {
    if (totalAmount > 0 && type === "Installment" && installmentAmount > 0)
      setNoOfInstallments(totalAmount / installmentAmount);
  }, [installmentAmount, totalAmount, type]);
  useEffect(() => {
    if (totalAmount > 0 && type === "Installment" && noOfInstallments > 0)
      setInstallmentAmount(totalAmount / noOfInstallments);
  }, [noOfInstallments, type]);
  useEffect(() => {
    onRecord ? setDonationMode("Cheque") : setDonationMode("Cash");
  }, [onRecord]);
  useEffect(() => {
    const donorId = params.donorId;
    const docRef = firestore.collection("donors").doc(donorId);
    docRef.onSnapshot((snapshot) => {
      if (snapshot.exists) {
        const data = snapshot.data();

        setTotalCommitment(
          data.totalCommitment !== "" && data.totalCommitment !== undefined
            ? data.totalCommitment
            : 0
        );
        setValue("fullName", data.fullName);
        setValue("pan", data.pan);
        setValue("spiritualName", data.spiritualName);

        setValue(
          "dob",
          data.dob !== "" && data.dob !== undefined ? data.dob.toDate() : ""
        );
        setValue("email", data.email);
        setValue("phone", data.phone);

        setValue(
          "addressLine1",
          data.address !== "" && data.address !== undefined
            ? data.address.addressLine1
            : ""
        );
        setValue(
          "addressLine2",
          data.address !== "" && data.address !== undefined
            ? data.address.addressLine2
            : ""
        );
        setValue(
          "city",
          data.address !== "" && data.address !== undefined
            ? data.address.city
            : ""
        );
        setValue(
          "pin",
          data.address !== "" && data.address !== undefined
            ? data.address.pin
            : ""
        );

        setValue(
          "state",
          data.address !== "" && data.address !== undefined
            ? data.address.state
            : ""
        );
        setValue(
          "country",
          data.address !== "" && data.address !== undefined
            ? data.address.country
            : ""
        );

        setValue("reference", data.reference);
        setValue("receivedThroughWebsite", false);
        setValue("csrDonation", false);
      } //End of if(snapshot.exists)
    }); //End of const docRef ...
  }, []); //End of useEffect
  const addRecord = async (donationData, donorReference) => {
    //Add the donation data to db

    await firestore
      .collection("donors")
      .doc(params.donorId)
      .collection("donations")
      .add({
        receiptNo: "",
        amount: donationData.amount,
        bank: "",
        bankRef: "",
        bankRefDate: "",
        collectedBy: donorReference,
        date: new Date(donationData.date),
        mode: donationMode,
        trust: onRecord ? donationData.trust : "",
        created: new Date(),
        purpose: onRecord ? donationData.purpose : "",
        onRecord: onRecord,
        comment: donationData.comment,
        raisedThroughPlatform: donationData.raisedThroughPlatform,
        commissionAmount: donationData.commisionAmount,
        type: "Commitment",
        createdBy: user.email,
        updatedBy: user.email,
        csrDonation: donationData.csrDonation,
        receivedThroughWebsite: donationData.receivedThroughWebsite,
        updatedAt: new Date(),
        upiId: "",
      })
      .catch((e) => console.log(`Error in adding comittment record`)); //End of then(async (res)
  }; //End of addRecord

  const acceptDonation = async (donationData) => {
    setisLoading(true);
    //If the donor ref is self
    let donorReference = "";
    //The other option is selected but ref is not selected yet
    if (donorRefOption === "otherDonor" && donorRef === "") {
      setDonorRefError(true);
      // donorReference = params.donorId;
    } else {
      if (donorRefOption === "otherDonor") {
        //If donor ref is other and selected a valid donor
        donorReference = donorRef;
      } else if (donorRefOption === "selfDonor")
        //If donor ref is self, set his id as ref
        donorReference = "Self";

      //Add commitment records as per below logic
      //If type == one time
      //  Add one commitment record
      //Else if type == installments
      // Add one commitment record for each installment
      //  based on the frequency, starting with commitment start date

      if (type === "One Time") {
        await addRecord(donationData, donorReference);
      } else {
        donationData.amount = installmentAmount;
        let dCommitmentStartDate = new Date(donationData.date);
        for (let i = 0; i < noOfInstallments; i++) {
          donationData.date = dCommitmentStartDate.toString();
          await addRecord(donationData, donorReference);
          //Get the next installment date
          switch (donationData.frequency) {
            case "Month":
              dCommitmentStartDate.setMonth(
                dCommitmentStartDate.getMonth() + 1
              );
              break;
            case "Quarter":
              dCommitmentStartDate.setMonth(
                dCommitmentStartDate.getMonth() + 3
              );
              break;
            case "Half-year":
              dCommitmentStartDate.setMonth(
                dCommitmentStartDate.getMonth() + 6
              );
              break;
            case "Year":
              dCommitmentStartDate.setMonth(
                dCommitmentStartDate.getMonth() + 12
              );
              break;

            default:
              break;
          } //End of switch
        } //End of for
      } //End of else

      //Update the total commitment amount of the donor as per the type value
      await firestore
        .collection("donors")
        .doc(params.donorId)
        .update({
          totalCommitment: parseInt(totalCommitment) + parseInt(totalAmount),
        });

      //If the collected by other then update that other person's total collection
      if (
        donorRefOption === "otherDonor" &&
        donorReference !== params.donorId
      ) {
        //Get his total collection
        const donorRef1 = firestore.collection("donors").doc(donorReference);
        const donorRefDoc = await donorRef1.get();
        if (donorRefDoc.exists) {
          let donorRefTotalCommitmentCollection =
            donorRefDoc.data().totalCommitmentCollection;
          //Update the total collection
          await firestore
            .collection("donors")
            .doc(donorReference)
            .update({
              totalCommitmentCollection:
                parseInt(donorRefTotalCommitmentCollection) +
                parseInt(totalAmount),
            });
        } //end of if(donorRefDoc.exists)
      } //end of if(donorRefOption === 'otherDonor' && donorReference !== params.donorId)
    } //End of else if(donorRefOption === 'selfDonor')
    setisLoading(false);
    history.push(`/donordetails/${params.donorId}`);
  }; //End of acceptDonation

  const formClass = `${isLoading ? "ui form loading" : ""}`;
  return (
    <div>
      <div className="card">
        <div className="justify-content-center d-flex">
          <h2>Accpet Commitment</h2>
        </div>
        <form className={formClass} onSubmit={handleSubmit(acceptDonation)}>
          <label className="d-flex">
            <h4>Personal Details:</h4>
          </label>
          <div className="row">
            <div className="col-md-6">
              <label className="m-2">Donor Name:</label>
              <input
                type="text"
                className="form-control ml-2"
                id="fullName"
                name="fullName"
                {...register("fullName")}
                readOnly
              />
            </div>
            <div className="col-md-6">
              <label className="m-2">PAN:</label>
              <input
                type="input"
                className="form-control mr-2  p-2"
                {...register("pan")}
                readOnly
                name="pan"
              />
            </div>
          </div>
          <label className="d-flex">
            <h4>Communication Details:</h4>
          </label>
          <div className="row mb-3">
            <div className="col-md-6">
              <label className="m-2">Email:</label>
              <input
                type="text"
                className="form-control ml-2"
                id="email"
                name="email"
                {...register("email")}
                readOnly
              />
            </div>
            <div className="col-md-6">
              <label className="m-2">Phone:</label>
              <input
                type="text"
                className="form-control p-2"
                name="phone"
                readOnly
                {...register("phone")}
              />
            </div>
          </div>
          <label className="d-flex">
            <h4>Address:</h4>
          </label>
          <div className="row mb-3">
            <div className="col-md-6">
              <label className="m-2">Address Line1:</label>
              <input
                type="text"
                className="form-control ml-2"
                id="addressLine1"
                name="addressLine1"
                readOnly
                {...register("addressLine1")}
              />
            </div>
            <div className="col-md-6">
              <label className="m-2">Address Line2:</label>
              <input
                type="text"
                className="form-control p-2"
                name="addressLine2"
                readOnly
                {...register("addressLine2")}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label className="m-2">City:</label>
              <input
                type="text"
                className="form-control ml-2"
                id="city"
                name="city"
                readOnly
                {...register("city")}
              />
            </div>
            <div className="col-md-6">
              <label className="m-2">Pin Code:</label>
              <input
                type="text"
                className="form-control p-2"
                name="pin"
                readOnly
                {...register("pin")}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label className="m-2">State:</label>
              <input
                type="text"
                className="form-control ml-2"
                id="state"
                name="state"
                readOnly
                {...register("state")}
              />
            </div>
            <div className="col-md-6">
              <label className="m-2">Country:</label>
              <input
                type="text"
                className="form-control p-2"
                name="country"
                readOnly
                {...register("country")}
              />
            </div>
          </div>
          <label className="d-flex">
            <h4>Individual Commitment Details:</h4>
          </label>
          <div className="row mb-3">
            <div className="col-md-6">
              {" "}
              <label className="m-2">Total Amount:</label>
              <input
                type="number"
                className="form-control p-2"
                name="amount"
                min="0"
                value={totalAmount}
                {...register("amount", {
                  required: "Please enter a Commitment amount",
                  min: {
                    value: 1,
                    message:
                      "Minimum Commitment amount should be more than zero",
                  },
                })}
                onChange={(e) => setTotalAmount(e.target.valueAsNumber)}
              />
              <ErrorMessage
                errors={errors}
                name="amount"
                render={({ message }) => (
                  <p className="text-danger">{message}</p>
                )}
              />
            </div>
            <div className="col-md-6">
              <label className="m-2">Type:</label>
              <div className="row">
                <div className="col-md-6">
                  <input
                    type="radio"
                    id="OneTime"
                    name="type"
                    value="One Time"
                    onClick={(e) => setType(e.target.value)}
                    {...register("type")}
                    checked={type === "One Time"}
                  />
                  <label className="m-2" htmlFor="OneTime">
                    One Time
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    type="radio"
                    id="Installment"
                    name="type"
                    value="Installment"
                    {...register("type")}
                    onClick={(e) => setType(e.target.value)}
                    checked={type === "Installment"}
                  />
                  <label className="m-2" htmlFor="Installment">
                    Installment
                  </label>
                </div>
              </div>
            </div>
          </div>
          {type === "Installment" && totalAmount > 0 && (
            <>
              <div>
                <label className="m-2">
                  Please enter either installment amount or no of installments
                </label>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <label className="m-2" htmlFor="installmentAmount">
                    Installment Amount:
                  </label>
                  <br />
                  <input
                    type="number"
                    className="form-control p-2"
                    name="installmentAmount"
                    min="0"
                    {...register("installmentAmount", {
                      min: {
                        value: 1,
                        message:
                          "Please enter either installment amount or no of installments",
                      },
                      validate: {
                        checkNoOfInstallments: validateNoOfInstallments,
                      },
                    })}
                    value={installmentAmount}
                    onChange={(e) => setInstallmentAmount(e.target.value)}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="installmentAmount"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                  {(errors.noOfInstallments?.type === "checkNoOfInstallments" ||
                    errors.installmentAmount?.type ===
                      "checkNoOfInstallments") && (
                    <p className="text-danger">
                      Installments cannot go beyond 5 years
                    </p>
                  )}
                </div>
                <div className="col-md-4">
                  {" "}
                  <label className="m-2" htmlFor="Installment">
                    Every:
                  </label>
                  <select
                    {...register("frequency")}
                    className="form-control ml-2"
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                  >
                    <option value="Month">Month</option>
                    <option value="Quarter">Quarter</option>
                    <option value="Half-year">Half-year</option>
                    <option value="Year">Year</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label className="m-2" htmlFor="noOfInstallments">
                    No Of Installments:
                  </label>
                  <br />
                  <input
                    type="number"
                    className="form-control p-2"
                    name="noOfInstallments"
                    min="0"
                    value={noOfInstallments}
                    {...register("noOfInstallments", {
                      validate: {
                        checkNoOfInstallments: validateNoOfInstallments,
                      },
                    })}
                    onChange={(e) => setNoOfInstallments(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
          <div className="row mb-3">
            <div className="col-md-3">
              <label className="m-2" htmlFor="installmentAmount">
                Commitment{" "}
                {type === "Installment" && totalAmount > 0 && <>{`Start`}</>}{" "}
                Date:
              </label>
              <br />
              <input
                type="date"
                value={donationDate}
                className="form-control mr-2 p-2"
                name="date"
                {...register("date", {
                  required: `Please enter commitment date.`,
                  validate: {
                    isValidCommitmentDate: (value) =>
                      Math.round(
                        (new Date() - new Date(value)) * (1000 * 60 * 60 * 24)
                      ) <= 0,
                    isCommitmentWithinAYear: (value) =>
                      Math.round(
                        (new Date(value) - new Date()) / (1000 * 60 * 60 * 24)
                      ) <= 365,
                  },
                })}
                onChange={(e) => {
                  setDonationDate(e.target.value);
                }}
              />
              {errors.date?.type === "isValidCommitmentDate" && (
                <p className="text-danger">
                  {`Commitment date sould be future date`}
                </p>
              )}
              {errors.date?.type === "isCommitmentWithinAYear" && (
                <p className="text-danger">
                  {`Commitment date should be within one year`}
                </p>
              )}
              <ErrorMessage
                errors={errors}
                name="date"
                render={({ message }) => (
                  <p className="text-danger">{message}</p>
                )}
              />
            </div>
            <div className="col-md-9">
              <label className="m-2">Mode:</label>
              <br />
              <div className="row">
                {onRecord && (
                  <>
                    <div className="col-md-4">
                      <input
                        type="radio"
                        id="Cheque"
                        name="mode"
                        value="Cheque"
                        {...register("mode", {
                          required: `Please select commitment mode.`,
                        })}
                        onChange={handleModeChange}
                        checked={donationMode === "Cheque"}
                      />
                      <label htmlFor="Cheque">Cheque</label>
                      <br />

                      <span>
                        <input
                          type="radio"
                          id="DD"
                          name="mode"
                          value="DD"
                          {...register("mode", {
                            required: `Please select commitment mode.`,
                          })}
                          onChange={handleModeChange}
                          checked={donationMode === "DD"}
                        />{" "}
                        <label htmlFor="DD">DD</label>
                      </span>
                    </div>
                  </>
                )}
                <div className="col-md-4">
                  {onRecord && (
                    <span>
                      <input
                        type="radio"
                        id="NEFT"
                        name="mode"
                        value="NEFT"
                        {...register("mode", {
                          required: `Please select commitment mode.`,
                        })}
                        onChange={handleModeChange}
                        checked={donationMode === "NEFT"}
                      />{" "}
                      <label htmlFor="NEFT">NEFT</label>
                      <br />
                    </span>
                  )}
                  <input
                    type="radio"
                    id="Cash"
                    name="mode"
                    value="Cash"
                    {...register("mode", {
                      required: `Please select commitment mode.`,
                    })}
                    onChange={handleModeChange}
                    checked={donationMode === "Cash"}
                  />{" "}
                  <label htmlFor="Cash">Cash</label>
                  <br />
                  <ErrorMessage
                    errors={errors}
                    name="mode"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </div>
                {onRecord && (
                  <div className="col-md-4">
                    <span>
                      <input
                        type="radio"
                        id="UPI"
                        name="mode"
                        value="UPI"
                        {...register("mode", {
                          required: `Please select commitment mode.`,
                        })}
                        onChange={handleModeChange}
                        checked={donationMode === "UPI"}
                      />{" "}
                      <label htmlFor="UPI">UPI</label>
                    </span>
                    <br />
                    <span>
                      <input
                        type="radio"
                        id="ACH"
                        name="mode"
                        value="ACH"
                        {...register("mode", {
                          required: `Please select commitment mode.`,
                        })}
                        onChange={handleModeChange}
                        checked={donationMode === "ACH"}
                      />{" "}
                      <label htmlFor="ACH">ACH</label>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <label className="d-flex">
            <h4>Additional Details:</h4>
          </label>
          <div className="row mb-3">
            <div className="col-md-5">
              {onRecord && (
                <>
                  <label className="m-2">To Trust:</label>
                  <br />
                  <input
                    type="radio"
                    id="SCSS"
                    name="trust"
                    value="SCSS"
                    {...register("trust", {
                      required: `Please select the trust to which the commitment is made.`,
                    })}
                  />{" "}
                  <label htmlFor="SCSS">Sri Chaitanya Shikshan Sanstha</label>
                  <br />
                  <input
                    type="radio"
                    id="CREST"
                    name="trust"
                    value="CREST"
                    checked={true}
                    {...register("trust", {
                      required: `Please select the trust to which the commitment is made.`,
                    })}
                  />{" "}
                  <label htmlFor="CREST">
                    Chaitanya Research & Edcational Services Trust
                  </label>{" "}
                  <br />
                  <ErrorMessage
                    errors={errors}
                    name="trust"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </>
              )}
            </div>
            <div className="col-md-2">
              {" "}
              <label className="m-2">Collected By:</label>
              <br />
              <input
                type="radio"
                id="selfDonor"
                name="collectedBy"
                value="selfDonor"
                checked={donorRefOption === "selfDonor"}
                onChange={handleCollectedByOptions}
              />{" "}
              <label htmlFor="selfDonor">Self</label>
              <br />
              <input
                type="radio"
                id="otherDonor"
                name="collectedBy"
                value="otherDonor"
                checked={donorRefOption === "otherDonor"}
                onChange={handleCollectedByOptions}
              />{" "}
              <label htmlFor="otherDonor">Other</label>
            </div>
            <div className="col-md-5">
              {donorRefOption === "otherDonor" ? (
                <>
                  <label className="m-2">
                    If collected by is "Other", please search the collector's
                    name here. Enter the collector name & click on magnifier
                    glass icon.
                  </label>
                  {!donorRef && (
                    <label className="m-2">Collector is not selected yet</label>
                  )}
                  {donorRef && (
                    <label className="m-2">Collector is now selected</label>
                  )}
                  <br />
                  <input
                    placeholder="Search Here"
                    name="searchRef"
                    value={donorRefSearchQuery}
                    onChange={(e) => setDonorRefSearchQuery(e.target.value)}
                  />
                  <button
                    className="btn btn-outline"
                    type="button"
                    onClick={handleDonorRefSearch}
                  >
                    <AiReactIcon.AiOutlineSearch />
                    {/* <AiReactIcon.FaBars /> */}
                  </button>
                  <br />
                  {donorRefList && (
                    <DonorRefList
                      queryList={donorRefList}
                      updateDonorRefValue={updateDonorRefValue}
                    />
                  )}
                </>
              ) : (
                ""
              )}
              {donorRefError && (
                <div className="text-danger">
                  Please select a reference for the commitment
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md">
                    <label className="m-2" htmlFor="onRecord">
                      On Record:
                    </label>
                    <input
                      type="checkbox"
                      name="onRecord"
                      id="onRecord"
                      value={onRecord}
                      onChange={(e) => setOnRecord(!onRecord)}
                      checked={onRecord}
                    ></input>
                  </div>
                </div>
                {onRecord && (
                  <div className="row">
                    <div className="col-md">
                      <label className="m-2" htmlFor="receivedThroughWebsite">
                        Received through Website:
                      </label>
                      <input
                        type="checkbox"
                        name="receivedThroughWebsite"
                        id="receivedThroughWebsite"
                        {...register("receivedThroughWebsite")}
                      ></input>
                    </div>
                  </div>
                )}
                {onRecord && (
                  <div className="row">
                    <div className="col-md">
                      <label className="m-2" htmlFor="csrDonation">
                        CSR Donation:
                      </label>
                      <input
                        type="checkbox"
                        name="csrDonation"
                        id="csrDonation"
                        {...register("csrDonation")}
                      ></input>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <label className="m-2">Comment:</label>
              <br />
              <textarea
                name="comment"
                id="comment"
                {...register("comment", {})}
              ></textarea>
            </div>
            {onRecord && (
              <div className="col-md-4">
                {" "}
                <label className="m-2">Purpose:</label>
                <br></br>
                <input
                  type="radio"
                  id="ObjectOfTrust"
                  name="purpose"
                  value="Object Of Trust"
                  checked={true}
                  {...register("purpose")}
                />{" "}
                <label htmlFor="ObjectOfTrust">Object of the trust</label>{" "}
                <br />
                <input
                  type="radio"
                  id="trustCorpus"
                  name="purpose"
                  value="Trust Corpus"
                  {...register("purpose")}
                />{" "}
                <label htmlFor="trustCorpus">Trust Corpus</label>
              </div>
            )}
          </div>
          {onRecord && (
            <div className="row mb-3">
              <div className="col-md-3">
                <label className="m-2" htmlFor="Installment">
                  Raised through Platform:
                </label>
                <br />
                <select
                  className="form-control ml-2"
                  {...register("raisedThroughPlatform")}
                  onChange={(e) => setRaisedThroughPlatform(e.target.value)}
                >
                  <option value="" selected></option>
                  <option value="Cashfree">Cashfree</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Give India">Give India</option>
                  <option value="Milap">Milap</option>
                  <option value="Patron Care">Patron Care</option>
                </select>
              </div>
              <div className="col-md-3">
                <label className="m-2">Commision Amount:</label>
                <br />
                <input
                  type="number"
                  min="0"
                  required={raisedThroughPlatform !== ""}
                  readOnly={raisedThroughPlatform === ""}
                  id="commisionAmount"
                  name="commisonAmount"
                  className="form-control mr-2  p-2"
                  {...register("commisionAmount")}
                />
              </div>
              <div className="col-md-3"></div>
              <div className="col-md-3"></div>
            </div>
          )}
          <div className="row mb-3">
            <div className="col justify-content-center d-flex">
              <button type="submit" className="btn btn-primary mr-2">
                Accept Commitment
              </button>{" "}
              <button
                type="button"
                className="btn btn-secondary ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/donordetails/${params.donorId}`);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
});
