import React from "react";
import { Text, StyleSheet, View, Font } from "@react-pdf/renderer";
import {
  GetDonation,
  GetDonationAmountInWords,
  GetDonationBankRefDate,
  GetDonorDetails,
} from "./DonationContextProvider";
import RobotoBold from "./fonts/Roboto-Bold.ttf";
import RobotoRegular from "./fonts/Roboto-Regular.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoBold, fontWeight: "bold" },
    { src: RobotoRegular, fontWeight: "normal" },
  ],
});

function ReceiptMainData() {
  const donation = GetDonation();
  const donationAmountInWords = GetDonationAmountInWords();
  const donor = GetDonorDetails();
  const bankRefDate = GetDonationBankRefDate();

  const styles = StyleSheet.create({
    viewStyle: {
      width: "100%",
      textAlign: "left",
      fontSize: 12,
    },
    viewRowStyle: {
      display: "flex",
      flexDirection: "row",
    },
    textStyle: {
      width: "100%",
    },
    textBold: {
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
    textNormal: {
      fontFamily: "Roboto",
      fontWeight: "normal",
    },
  });
  return (
    <>
      <View style={[styles.viewStyle, styles.viewRowStyle]}>
        <Text
          style={styles.textNormal}
        >{`Received with thanks a sum of: `}</Text>
        <Text style={styles.textBold}>{`Rs. ${donation.amount}/-`}</Text>
      </View>
      <View style={styles.viewStyle}>
        <Text style={styles.textBold}>{donationAmountInWords}</Text>
      </View>
      <View style={[styles.viewStyle, styles.viewRowStyle]}>
        <Text style={styles.textNormal}>{`From: `}</Text>
        <Text style={styles.textBold}>{donor.fullName}</Text>
      </View>
      <View style={[styles.viewStyle, styles.viewRowStyle]}>
        <Text style={styles.textNormal}>{`PAN No: `}</Text>
        <Text style={styles.textBold}>
          {donor.pan === "" ? "Not available" : donor.pan}
        </Text>
      </View>
      <View style={[styles.viewStyle, styles.viewRowStyle]}>
        <Text style={[styles.textNormal, { width: "12%" }]}>{`Address: `}</Text>
        <Text style={[styles.textBold, styles.textStyle]}>
          {donor.address.addressLine1}
        </Text>
      </View>
      {donor.address.addressLine2 !== "" && (
        <View style={[styles.viewStyle, styles.viewRowStyle]}>
          <Text style={[styles.textNormal, { width: "12%" }]}>{` `}</Text>
          <Text style={[styles.textBold, styles.textStyle]}>
            {donor.address.addressLine2}
          </Text>
        </View>
      )}
      <View style={[styles.viewStyle, styles.viewRowStyle]}>
        <Text style={[styles.textNormal, { width: "12%" }]}>{``}</Text>
        <Text
          style={[styles.textBold, styles.textStyle]}
        >{`${donor.address.city} ${donor.address.state} ${donor.address.country} ${donor.address.pin}`}</Text>
      </View>
      <View style={[styles.viewStyle, styles.viewRowStyle]}>
        <Text style={styles.textNormal}>{`On Account of: `}</Text>
        <Text style={styles.textBold}>{`Donation`}</Text>
      </View>
      <View style={[styles.viewStyle, styles.viewRowStyle]}>
        <Text style={[styles.textNormal, { width: "4%" }]}>{`By: `}</Text>
        <Text style={[styles.textBold, styles.textStyle]}>
          {donation.mode === "Cash"
            ? "Cash"
            : `${donation.mode} No: ${donation.bankRef} Date: ${bankRefDate} ${
                donation.bank === "" || donation.bank === "Not Available"
                  ? ""
                  : "Bank: " + donation.bank
              }`}
        </Text>
      </View>
      <View style={[styles.viewStyle, styles.viewRowStyle]}>
        <Text style={styles.textNormal}>{`Phone: `}</Text>
        <Text style={styles.textBold}>{donor.phone ? donor.phone : `N/A`}</Text>
      </View>
      <View style={[styles.viewStyle, styles.viewRowStyle]}>
        <Text style={styles.textNormal}>{`Email: `}</Text>
        <Text style={styles.textBold}>{donor.email ? donor.email : `N/A`}</Text>
      </View>
      <View style={styles.viewStyle}>
        <Text style={[{ textAlign: "right", width: "90%" }]}>Yours truly,</Text>
      </View>
      <View
        style={[styles.viewStyle, styles.viewRowStyle, { paddingTop: "2.5vh" }]}
      >
        <Text
          style={[styles.textStyle, { textAlign: "left", fontSize: 7 }]}
        >{`Note: `}</Text>
        <Text style={[styles.textStyle, { textAlign: "right" }]}>
          --------------------------------------------------
        </Text>
      </View>
      <View style={[styles.viewStyle, styles.viewRowStyle]}>
        <Text
          style={[styles.textStyle, { textAlign: "left", fontSize: 7 }]}
        >{`Cheque/Draft subject to realisation`}</Text>
        <Text style={[styles.textStyle, { textAlign: "center", fontSize: 10 }]}>
          {`For ${
            donation.trust === "CREST"
              ? "Chaitanya Research & Educational Services Trust"
              : "Shree Chaitanya Shikshan Samstha"
          }`}
        </Text>
      </View>
    </>
  );
}

export default ReceiptMainData;
