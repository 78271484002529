function UpdateStudentData(){

    return(
        <div id="updatestudentdata">
            <h2 className="text-center text-blue pt-5">Welcome to Update Student Information</h2>
        </div>
    );
}



export default UpdateStudentData;