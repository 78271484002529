import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

function ShowDonationsFromOthers(props) {
  const columns = [
    { dataField: "donorName", text: "Donor Name", sort: true },
    { dataField: "amount", text: "Amount", sort: true },
    { dataField: "date", text: "Date", sort: true },
    { dataField: "mode", text: "Mode", sort: true },
    { dataField: "onRecord", text: "On Record", sort: true },
    {
      dataField: "raisedThroughPlatform",
      text: "Raised Through Platform",
      sort: true,
    },
    { dataField: "commissionAmount", text: "Commission", sort: true },
    { dataField: "csrDonation", text: "CSR Donation?", sort: true },
    {
      dataField: "receivedThroughWebsite",
      text: "Received Through Website?",
      sort: true,
    },
    { dataField: "trust", text: "Trust", sort: true },
  ];
  return (
    <div>
      <BootstrapTable
        columns={columns}
        data={props.donations}
        keyField="id"
        striped
        hover
        condensed
        wrapperClasses="table-responsive"
        pagination={paginationFactory({
          sizePerPageList: [
            {
              text: "5",
              value: 5,
            },
            {
              text: "10",
              value: 10,
            },
            {
              text: "All",
              value: props.donations.length,
            },
          ],
          hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
          paginationSize: 5,
          alwaysShowAllBtns: true, // always show the next and previous page button
          firstPageText: "First", // the text of first page button
          prePageText: "Prev", // the text of previous page button
          nextPageText: "Next", // the text of next page button
          lastPageText: "Last", // the text of last page button
          nextPageTitle: "Go to next", // the title of next page button
          prePageTitle: "Go to previous", // the title of previous page button
          firstPageTitle: "Go to first", // the title of first page button
          lastPageTitle: "Go to last", // the title of last page button
        })}
      />
    </div>
  );
}

export default React.memo(ShowDonationsFromOthers);
