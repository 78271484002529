import React, { useEffect, useState } from "react";

// import { listStorage } from "../../firebase/Users";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import { img1 } from "../../img/homePageImg1.jpeg";

// const img1 = require("../../img/homePageImg1.jpeg");
function ParentPortalHomePage() {
  // const [state, setstate] = useState("initialState");
  const [windowDimensions, setWindowDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const handleWindowResize = () => {
    setWindowDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };
  window.addEventListener("resize", handleWindowResize);

  useEffect(() => {
    console.log("List Storage");
    // console.log(listStorage("/cmsPhotos"));
  }, []);

  return (
    <>
      <div className="container-fluid"></div>
      <div className="row parent-portal-home vertical-horizontal-center-aligned">
        <div className="col-md-6 pr-5 pl-5">
          <form
            className="text-center carousel-div"
            style={{ padding: "11rem" }}
          >
            <fieldset disabled="disabled">
              <div className="row">
                <div className="col">
                  <label htmlFor="userName">User Name:</label>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <input name="userName" id="userName" type="text"></input>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="password">Password:</label>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <input name="password" id="password" type="password"></input>
                </div>
              </div>
              <div className="row">
                <div
                  className={
                    windowDimensions.width > 768
                      ? "col-md-6 p-1 text-right"
                      : "col-md-6 p-1 text-center"
                  }
                >
                  <button
                    name="submit"
                    id="submit"
                    type="submit"
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </div>
                <div
                  className={
                    windowDimensions.width > 768
                      ? "col-md-6 p-1 text-left"
                      : "col-md-6 p-1 text-center"
                  }
                >
                  <button
                    name="reset"
                    id="reset"
                    type="button"
                    className="btn btn-secondary"
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <button className="btn btn-link">Forgot Password?</button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>

        <div className="col-md-6 pr-5 pl-5 pt-3 pb-4">
          <div className="carousel-div">
            <Carousel
              showArrows={false}
              autoPlay={true}
              infiniteLoop={true}
              showThumbs={false}
            >
              <div>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/bms-intranet.appspot.com/o/cmsPhotos%2F1626191070230_WhatsApp%20Image%202021-07-13%20at%208.08.58%20PM.jpeg?alt=media&token=8be135c4-11f1-433a-b17a-42cab799f15d"
                  alt="image1"
                  className="carousel-image"
                />
              </div>
              <div>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/bms-intranet.appspot.com/o/cmsPhotos%2F1626191070231_WhatsApp%20Image%202021-07-13%20at%208.08.57%20PM%20(1).jpeg?alt=media&token=26c924a8-2a5c-4bb5-8de3-dbeb0a96412d"
                  alt="image2"
                  className="carousel-image"
                />
              </div>
              <div>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/bms-intranet.appspot.com/o/cmsPhotos%2F1626191070230_WhatsApp%20Image%202021-07-13%20at%208.08.57%20PM.jpeg?alt=media&token=e644ca2b-c2b1-4edc-829d-1e6453a6c5e8"
                  alt="image3"
                  className="carousel-image"
                />
              </div>
              <div>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/bms-intranet.appspot.com/o/cmsPhotos%2F1626191070229_WhatsApp%20Image%202021-07-13%20at%208.08.57%20PM%20(2).jpeg?alt=media&token=02454f57-49c4-458f-9e6a-90183c3031ec"
                  alt="image4"
                  className="carousel-image"
                />
              </div>
              <div>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/bms-intranet.appspot.com/o/cmsPhotos%2F1626191070230_WhatsApp%20Image%202021-07-13%20at%208.08.58%20PM%20(1).jpeg?alt=media&token=d3f79cdf-c020-440b-a5dd-57c47830fe73"
                  alt="image5"
                  className="carousel-image"
                />
              </div>
              <div>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/bms-intranet.appspot.com/o/cmsPhotos%2F1626915218790_Trial_300_250.png?alt=media&token=07cf1e44-515a-4d19-ab02-bb85d40fb91e"
                  alt="image5"
                  className="carousel-image"
                />
              </div>
              <div>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/bms-intranet.appspot.com/o/cmsPhotos%2F1626915603697_trial_1800_1500.png?alt=media&token=cf646053-8b8e-41c1-8758-6aa9feeb3be9"
                  alt="image5"
                  className="carousel-image"
                />
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
}

export default ParentPortalHomePage;
