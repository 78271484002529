import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { GetDonation } from "./DonationContextProvider";

function ReceiptLeftSection() {
  const styles = StyleSheet.create({
    textStyle: {
      transform: "rotate(90deg)",
      fontSize: "1vw",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    viewStyle: {
      height: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
  });
  const donation = GetDonation();
  return (
    <>
      <View style={styles.viewStyle}>
        <Text style={styles.textStyle}>{donation.id}</Text>
      </View>
    </>
  );
}

export default ReceiptLeftSection;
