import React, { useState } from "react";
import { useForm } from "react-hook-form";

// import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from "react-router";
import { firestore } from "../../firebase/config";
// import { useSession } from "../../firebase/UserProvider";
import EventList from "./EventList";
function SearchEventByTitle() {
  const {
    register,

    formState: { errors },
    handleSubmit,
  } = useForm();

  // const [isLoading] = useState(false);

  // const formClass = `${isLoading ? "ui form loading" : ""}`;
  // const history = useHistory();
  // const [title, setTitle] = useState("");
  const [queryResult, setQueryResult] = useState(null);

  const getMonthOfDate = (month) => {
    switch (month) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";
      default:
        break;
    }
  };
  /**
   * Search event by title
   */
  const searchEvent = (data) => {
    setQueryResult(null);
    if (data.title !== "" || data.title !== null) {
      try {
        firestore
          .collection("academic-calendar-events")
          .where("title", ">=", data.title)
          .onSnapshot((result) => {
            let eventList = [];
            result.forEach((event) => {
              let dStart = event.data().start.toDate();
              let sStart =
                dStart.getDate() +
                "-" +
                getMonthOfDate(dStart.getMonth()) +
                "-" +
                dStart.getFullYear();

              let dEnd = event.data().end.toDate();
              let sEnd =
                dEnd.getDate() +
                "-" +
                getMonthOfDate(dEnd.getMonth()) +
                "-" +
                dEnd.getFullYear();
              eventList.push({
                id: event.id,
                title: event.data().title,
                start: sStart,
                end: sEnd,
                isPrePrimary: event.data().isPrePrimary,
                isPrimary: event.data().isPrimary,
                isUpperPrimary: event.data().isUpperPrimary,
                isSecondary: event.data().isSecondary,
              }); //end of eventList.push()
            }); //End od queryResult.forEach
            setQueryResult(eventList);
          });
      } catch (e) {
        console.log(e);
      }
    } //end of if(title !== '' || title !== null)
  }; //End of searchEvent

  return (
    <>
      <div className="card p-1 mb-1">
        <div className="justify-content-center d-flex">
          <h2>Search Academic Calendar Event by Title</h2>
        </div>
      </div>
      <div className="card p-1 row ">
        <form
          className="form-inline justify-content-center d-flex"
          onSubmit={handleSubmit(searchEvent)}
        >
          <label className="ml-2">Title:</label>
          <input
            type="text"
            className="form-control m-2"
            id="pan"
            placeholder="Search is case sensitive"
            name="title"
            // value={title}
            // onChange={(e) => {
            //   e.preventDefault();
            //   setTitle(e.target.value);
            // }}
            {...register("title", { required: "Title is required" })}
          />

          <button type="submit" className="btn btn-primary m-2">
            Search
          </button>
          {errors.title?.message && (
            <p className="text-danger">{errors.title?.message}</p>
          )}
        </form>
      </div>
      <div className="card-body py-2 justify-content-center d-flex">
        <div className="card-title font-weight-bold m-0">
          {queryResult && (
            <>
              <div>{"Query Results here.."}</div>
              {queryResult.length > 0 ? (
                <>
                  <EventList queryResult={queryResult} />
                </>
              ) : (
                <div> No Event Found </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SearchEventByTitle;
