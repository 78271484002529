/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { firestore } from "../firebase/config";
import { GetUserPrivilages } from "../firebase/UserPrivilageProvider";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DonationDetails from "./DonationDetails";
import { useSession } from "../firebase/UserProvider";
import GetDonationsAndCommitmentsFromOthers from "./GetDonationsAndCommitmentsFromOthers";
import DonationReceipt from "./donation-receipt/DonationReceipt";
import { getDateInFormat } from "./utilities/Utils";
import GetReferenceDetails from "./GetReferenceDetails";
import CommitmentActions from "./CommitmentActions";

export default memo(function DonorDetails(props) {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [donations, setDonations] = useState(null);
  const [country, setCountry] = React.useState(""); //Country of the donor
  const [state, setState] = React.useState(""); //State of the donor
  const [donorRef, setDonorRef] = useState(""); //State to hold the donor referece
  const [doesDonationExist, DonationExists] = useState(false); //state to hold if there is any donation
  const [doesCommitmentExist, CommitmentExists] = useState(false); //state to hold if there is any commitment
  const [isLoading, setisLoading] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);

  const [currentDonorId, setCurrentDonorId] = useState("");
  const [
    showDonationsAndCommitmentsFromOthers,
    setShowDonationsAndCommitmentsFromOthers,
  ] = useState(false);
  const [
    isGettingDonationsAndCommitmentsFromOthers,
    setIsGettingDonationsAndCommitmentsFromOthers,
  ] = useState(false);
  const [commitmenets, setCommitmenets] = useState(null);

  const [donorDetails, setDonorDetails] = useState({
    fullName: "",
    pan: "",
    email: "",
    phone: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      pin: "",
      state: "",
      country: "",
    },
  });
  const { user } = useSession();
  const pdfFile = React.createRef();
  const privilages = GetUserPrivilages();
  const params = useParams();

  const toggleIsGettingDonationsAndCommitmentsFromOthersFlag = (value) => {
    setIsGettingDonationsAndCommitmentsFromOthers(value);
  };
  /**
   * Gets the details of donors donations and commitmets collected from others
   */
  const getDonationsAndCommitmentsFromOthers = () => {
    setShowDonationsAndCommitmentsFromOthers(true);
  };
  /**
   * Gets the month value in two digit format
   */
  const getMonthValue = (month) => {
    return month + 1 < 10 ? "0" + (month + 1) : month + 1;
  }; //End of getMonthValue

  /**
   * Gets the date value in the two digit format
   */
  const getDateValue = (date) => {
    return date < 10 ? "0" + date : date;
  }; //End of getDateValue

  /**
   * Updates the record in the db
   */

  useEffect(async () => {
    const donorId = params.donorId;
    let donorTotalDonation,
      donorTotalCommitment,
      donorPan,
      donorEmail,
      donorPhone;
    let donorAddress = null;
    let donorName = "";
    setCurrentDonorId(donorId);
    setShowDonationsAndCommitmentsFromOthers(false);
    setisLoading(true);
    setDonations([]);
    setIsGettingDonationsAndCommitmentsFromOthers(false);
    setCommitmenets([]);
    const docRef = firestore.collection("donors").doc(donorId);
    await docRef.onSnapshot((snapshot) => {
      if (snapshot.exists) {
        const data = snapshot.data();

        let donorReference = "";
        donorTotalDonation = data.totalDonation;
        donorTotalCommitment = data.totalCommitment;
        donorPan = data.pan;
        donorEmail = data.email;
        donorPhone = data.phone;
        donorAddress = {
          addressLine1:
            data.address !== "" && data.address !== undefined
              ? data.address.addressLine1
              : "",
          addressLine2:
            data.address !== "" && data.address !== undefined
              ? data.address.addressLine2
              : "",
          city:
            data.address !== "" && data.address !== undefined
              ? data.address.city
              : "",
          pin:
            data.address !== "" && data.address !== undefined
              ? data.address.pin
              : "",
          state:
            data.address !== "" && data.address !== undefined
              ? data.address.state
              : "",
          country:
            data.address !== "" && data.address !== undefined
              ? data.address.country
              : "",
        };
        setDonorRef(
          data.reference !== "" || data.reference !== undefined
            ? data.reference
            : ""
        );
        donorReference = data.reference;
        setValue("fullName", data.fullName);
        donorName = data.fullName;
        setValue("pan", data.pan);
        setValue("spiritualName", data.spiritualName);

        let dDob = null;
        let sDob = "";
        data.dob !== undefined && data.dob !== ""
          ? (dDob = data.dob.toDate())
          : (dDob = null);

        if (dDob !== null) {
          sDob =
            dDob.getFullYear() +
            "-" +
            getMonthValue(dDob.getMonth()) +
            "-" +
            getDateValue(dDob.getDate());

          setValue("dob", sDob);
        }
        setValue("email", data.email);
        setValue("phone", data.phone);

        setValue(
          "addressLine1",
          data.address !== "" && data.address !== undefined
            ? data.address.addressLine1
            : ""
        );
        setValue(
          "addressLine2",
          data.address !== "" && data.address !== undefined
            ? data.address.addressLine2
            : ""
        );
        setValue(
          "city",
          data.address !== "" && data.address !== undefined
            ? data.address.city
            : ""
        );
        setValue(
          "pin",
          data.address !== "" && data.address !== undefined
            ? data.address.pin
            : ""
        );

        setValue(
          "state",
          data.address !== "" && data.address !== undefined
            ? data.address.state
            : ""
        );
        setState(
          data.address !== "" && data.address !== undefined
            ? data.address.state
            : ""
        );
        setValue(
          "country",
          data.address !== "" && data.address !== undefined
            ? data.address.country
            : ""
        );

        setCountry(
          data.address !== "" && data.address !== undefined
            ? data.address.country
            : ""
        );
        setValue("totalDonation", data.totalDonation); //Individual Donation Received
        setValue("totalIndividualCommitment", data.totalCommitment); //Individual commitment

        setValue("totalCollection", data.totalCollection); //Donation Received from other
        setValue(
          "totalReceived",
          parseInt(data.totalDonation) + parseInt(data.totalCollection)
        );

        setValue("reference", data.reference);
        setValue("totalCommitmentCollection", data.totalCommitmentCollection); //Commitment from others
        setValue(
          "totalCommitment",
          parseInt(data.totalCommitment) +
            parseInt(data.totalCommitmentCollection)
        ); //Total Commitment

        setValue(
          "totalIndividualContribution",
          parseInt(data.totalDonation) + parseInt(data.totalCommitment)
        );

        setValue(
          "totalCollectionContribution",
          parseInt(data.totalCollection) +
            parseInt(data.totalCommitmentCollection)
        );

        setValue(
          "totalContribution",
          parseInt(data.totalDonation) +
            parseInt(data.totalCommitment) +
            parseInt(data.totalCollection) +
            parseInt(data.totalCommitmentCollection)
        );
        //Update Donor Details in the context provider
        setDonorDetails({
          id: donorId,
          fullName: data.fullName,
          pan: data.pan,
          email: data.email,
          phone: data.phone,
          address: {
            addressLine1:
              data.address !== "" && data.address !== undefined
                ? data.address.addressLine1
                : "",
            addressLine2:
              data.address !== "" && data.address !== undefined
                ? data.address.addressLine2
                : "",
            city:
              data.address !== "" && data.address !== undefined
                ? data.address.city
                : "",
            pin:
              data.address !== "" && data.address !== undefined
                ? data.address.pin
                : "",
            state:
              data.address !== "" && data.address !== undefined
                ? data.address.state
                : "",
            country:
              data.address !== "" && data.address !== undefined
                ? data.address.country
                : "",
          },
          totalCollection: data.totalCollection,
          totalCommitment: data.totalCommitment,
          totalCommitmentCollection: data.totalCommitmentCollection,
          totalDonation: data.totalDonation,
        });

        //Get Donor Reference name

        if (
          donorReference !== "Self" &&
          donorReference !== "" &&
          donorReference !== null
        ) {
          firestore
            .collection("donors")
            .doc(donorReference)
            .onSnapshot((snapshot) => {
              if (snapshot.exists) {
                let refFullName = snapshot.data().fullName;
                setDonorRef(refFullName);
              }
            }); //End of await firestore.collection('donors').
        }
      } //End of if(snapshot.exists)
      setisLoading(false);
    }); //End of const docRef ...

    //Get donations
    await docRef
      .collection("donations")
      .orderBy("date", "desc")
      .onSnapshot((snapshot) => {
        setisLoading(true);

        if (!snapshot.empty) {
          let donationDataArray = [];
          let commitmentDataArray = [];
          snapshot.docs.map((donation) => {
            const donationData = donation.data();

            let donationObj = {
              id: donation.id,
              amount: donationData.amount,
              bank:
                donationData.bank === "" || donationData.bank === undefined
                  ? "Not Available"
                  : donationData.bank,
              bankRef: donationData.bankRef,
              bankRefDate:
                donationData.bankRefDate !== "" &&
                donationData.bankRefDate !== undefined
                  ? getDateInFormat(
                      donationData.bankRefDate.toDate().toString()
                    )
                  : "",
              collectedBy:
                donationData.collectedBy === "Self" ? (
                  donorName
                ) : (
                  <GetReferenceDetails
                    refId={donationData.collectedBy}
                    needLink={true}
                  />
                ),
              date:
                donationData.date !== "" && donationData.date !== undefined
                  ? getDateInFormat(donationData.date.toDate().toString())
                  : "",
              trust: donationData.trust,
              mode: donationData.mode,
              type: donationData.type,
              receiptNo: donationData.receiptNo,
              upiId:
                donationData.upiId == "" || donationData.upiId === undefined
                  ? "Not Available"
                  : donationData.upiId,
              onRecord:
                donationData.onRecord !== undefined
                  ? donationData.onRecord
                  : false,
            };

            //Check if type is a donation, if yes set doesDonationExist to true
            //else set doesCommitment to true
            let donationObjCopy = Object.assign({}, donationObj);
            if (donationData.type === "Donation") {
              DonationExists(true);
              donationObj.receipt = donationData.onRecord ? (
                <PDFDownloadLink
                  document={
                    <DonationReceipt
                      donation={donationObjCopy}
                      donor={{
                        fullName: donorName,
                        pan: donorPan,
                        email: donorEmail,
                        address: donorAddress,
                        phone: donorPhone,
                      }}
                      user={user}
                    />
                  }
                  fileName={`${donorName.replace(".", "")}_${donation.id}`}
                  ref={pdfFile}
                  className="btn btn-link"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download here!"
                  }
                </PDFDownloadLink>
              ) : (
                <>
                  <div className="text-center">Not Available</div>
                </>
              );
              donationDataArray.push(donationObj);
            } else {
              CommitmentExists(true);

              donationObj.action = (
                <CommitmentActions
                  donation={donationObjCopy}
                  donorDetails={{
                    id: donorId,
                    totalCommitment: donorTotalCommitment,
                    totalDonation: donorTotalDonation,
                  }}
                />
              );

              commitmentDataArray.push(donationObj);
            }
          }); //End of snapshot.docs.map
          setDonations(donationDataArray);
          setCommitmenets(commitmentDataArray);
        }
        setisLoading(false);
      }); //end of await docRef.collection('donations').onSnapshot(snapshot => {
  }, [params.donorId]); //End of useEffect

  /*Function to updte the donor details */
  const updateDonorDetails = async (donorData) => {
    setUpdateInProgress(true);
    await firestore
      .collection("donors")
      .doc(params.donorId)
      .update({
        fullName: donorData.fullName.toUpperCase(),
        spiritualName: donorData.spiritualName.toUpperCase(),
        email: donorData.email.toUpperCase(),
        phone: donorData.phone,
        address: {
          addressLine1: donorData.addressLine1.toUpperCase(),
          addressLine2: donorData.addressLine2.toUpperCase(),
          city: donorData.city.toUpperCase(),
          pin: donorData.pin,
          state: state,
          country: country,
        },
        totalCommitmentCollection: donorData.totalCommitmentCollection,
        updatedBy: user.email,
        updatedAt: new Date(),
        dob: donorData.dob ? new Date(donorData.dob) : "",
      })
      .then(() => {
        alert("Update successful");
        setUpdateInProgress(false);
      }); //End of update
  }; //End of updateDonorDetails
  const formClass = `${isLoading ? "ui form loading" : "ui form"}`;
  return (
    <div style={{ position: "inherit" }}>
      <div className="card p-1 mb-1">
        <div className="justify-content-center d-flex">
          <h2>Donor Details</h2>
        </div>
      </div>
      <div className="card p-1">
        <form className={formClass} onSubmit={handleSubmit(updateDonorDetails)}>
          <label className="d-flex">
            <h4 className="p-2">Personal Details:</h4>
          </label>
          <div className="row">
            <div className="col-md-6">
              <label className="m-2">
                Donor Name:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control p-2"
                id="fullName"
                {...register("fullName", {
                  required: "Please enter donor name",
                  minLength: {
                    value: 3,
                    message: "Please enter at least 3 characters for name",
                  },
                })}
                {...(privilages.canUpdateDonor ? "" : "readOnly")}
              />
            </div>
            <div className="col-md-6">
              <label className="m-2">PAN:</label>
              <input
                type="input"
                className="form-control p-2"
                readOnly
                {...register("pan")}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label className="m-2">Spiritual Name:</label>
              <input
                type="text"
                className="form-control p-2"
                id="spiritualName"
                name="spiritualName"
                {...register("spiritualName", {
                  pattern: {
                    value: /\D/,
                    message: "Please enter a only characters",
                  },
                })}
              />
            </div>
            <div className="col-md-6">
              <label className="m-2">Date of Birth:</label>
              <input
                type="date"
                className="form-control p-2"
                name="dob"
                {...register("dob")}
              />
            </div>
          </div>
          <label className="d-flex">
            <h4 className="p-2">Communication Details:</h4>
          </label>
          <div className="row mb-3">
            <div className="col-md-6">
              <label className="m-2">Email:</label>
              <input
                type="email"
                className="form-control p-2"
                id="email"
                name="email"
                {...register("email")}
              />
            </div>
            <div className="col-md-6">
              <label className="m-2">Phone:</label>
              <input
                placeholder="Please enter phone number in 3-3-4 format"
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                className="form-control p-2"
                name="phone"
                {...register("phone")}
              />
            </div>
          </div>
          <label className="d-flex">
            <h4 className="p-2">Address:</h4>
          </label>
          <div className="row mb-3">
            <div className="col-md-6">
              <label className="m-2">
                Address Line1:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                placeholder="Please enter address line 1 here"
                className="form-control p-2"
                id="addressLine1"
                name="addressLine1"
                {...register("addressLine1", {
                  required: "Please enter address line 1",
                  minLength: {
                    value: 4,
                    message:
                      "The address line 1 should have at least 4 characers",
                  },
                })}
              />
              {errors.addressLine1?.message && (
                <p className="text-danger">{errors.addressLine1?.message}</p>
              )}
            </div>
            <div className="col-md-6">
              <label className="m-2">Address Line2:</label>
              <input
                placeholder="Please enter address line 2 here"
                type="text"
                className="form-control p-2"
                name="addressLine2"
                {...register("addressLine2")}
              />
              {errors.addressLine2?.message && (
                <p className="text-danger">{errors.addressLine2?.message}</p>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label className="m-2">
                City:<span className="text-danger">*</span>
              </label>
              <input
                placeholder="Please enter city here"
                type="text"
                className="form-control p-2"
                id="city"
                name="city"
                {...register("city", {
                  required: "Please enter city",
                })}
              />
              {errors.city?.message && (
                <p className="text-danger">{errors.city?.message}</p>
              )}
            </div>
            <div className="col-md-6">
              <label className="m-2">
                Pin Code:<span className="text-danger">*</span>
              </label>
              <input
                type="number"
                placeholder="Please enter pin code here"
                className="form-control p-2"
                name="pin"
                {...register("pin", {
                  required: "Please enter Pin code",
                  minLength: {
                    value: 6,
                    message: "Pin code should be at least 6 digits",
                  },
                  maxLength: {
                    value: 6,
                    message: "Pin code should not be more than 6 digits",
                  },
                })}
              />
              {errors.pin?.message && (
                <p className="text-danger">{errors.pin?.message}</p>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label className="m-2">
                Country:<span className="text-danger">*</span>
              </label>
              <br />
              <CountryDropdown
                defaultOptionLabel="Select a country"
                value={country}
                required
                onChange={(val) => setCountry(val)}
                className="form-control p-2"
              />
            </div>
            <div className="col-md-6">
              <label className="m-2">
                State:<span className="text-danger">*</span>
              </label>
              <br />
              <RegionDropdown
                country={country}
                required
                blankOptionLabel="No country selected"
                defaultOptionLabel="Now select a state/region"
                value={state}
                onChange={(val) => setState(val)}
                className="form-control p-2"
              />
            </div>
          </div>

          <label className="d-flex">
            <h4 className="p-2">Total Donations:</h4>
          </label>
          <div className="row mb-3">
            <div className="col-md-4">
              <label className="m-2">Individual Donation Received:</label>
              <input
                type="input"
                className="form-control p-2 rightAlignedInputText"
                id="totalDonation"
                name="totalDonation"
                readOnly
                {...register("totalDonation")}
              />
            </div>
            <div className="col-md-4">
              <label className="m-2">Collection received:</label>

              <input
                type="input"
                className="form-control p-2 rightAlignedInputText"
                id="totalCollection"
                name="totalCollection"
                readOnly
                {...register("totalCollection")}
              />
            </div>
            <div className="col-md-4">
              <label className="m-2">Total received:</label>
              <input
                type="input"
                className="form-control p-2 rightAlignedInputText"
                readOnly
                id="totalReceived"
                name="totalReceived"
                {...register("totalReceived")}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-4">
              <label className="m-2">Individual commitment:</label>
              <input
                type="input"
                className="form-control p-2 rightAlignedInputText"
                readOnly
                id="totalIndividualCommitment"
                name="totalIndividualCommitment"
                {...register("totalIndividualCommitment")}
              />
            </div>
            <div className="col-md-4">
              <label className="m-2">Commitment from others:</label>
              <input
                type="number"
                className="form-control p-2 rightAlignedInputText"
                id="totalCommitmentCollection"
                name="totalCommitmentCollection"
                min="0"
                {...register("totalCommitmentCollection", {
                  min: {
                    value: 0,
                    message: "The commitment amount cannot be negative",
                  },
                })}
              />
              {errors.totalCommitmentCollection?.message && (
                <p className="text-danger">
                  {errors.totalCommitmentCollection?.message}
                </p>
              )}
            </div>
            <div className="col-md-4">
              <label className="m-2">Total commitment:</label>
              <input
                type="input"
                className="form-control p-2 rightAlignedInputText"
                id="totalCommitment"
                name="totalCommitment"
                readOnly
                {...register("totalCommitment")}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col">
              <label className="m-2">Reference:</label>

              <div>{donorRef}</div>
            </div>
          </div>
          <div className="row mb-3 ">
            <div className="col justify-content-center d-flex">
              {updateInProgress ? (
                <button class="btn btn-primary" disabled>
                  <span class="spinner-grow spinner-grow-sm"></span>
                  Updating...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Update Details
                </button>
              )}
            </div>
          </div>
        </form>
      </div>

      <div className="mt-3">
        <div className="justify-content-center d-flex">
          <h2>Donation Details</h2>
        </div>
        <div className="justify-content-center d-flex">
          <Link to={`/acceptdonation/Donation/${params.donorId}`}>
            Accept New Donation
          </Link>
          {/* <button className="btn btn-link ">Accept New Donation</button> */}
        </div>
        {doesDonationExist && donations ? (
          <DonationDetails
            donations={donations}
            donorDetails={donorDetails}
            donationType="Donation"
          />
        ) : (
          // <div className="table-responsive">
          //   <table className="table table-bordered">
          //     <thead>
          //       <tr>
          //         <th>Donation Date</th>
          //         <th>Amount</th>
          //         <th>Bank</th>
          //         <th>Bank Reference</th>
          //         <th>Bank Refence Date</th>
          //         <th>Collected by</th>
          //         <th>To Trust</th>
          //         <th>Mode</th>
          //         <th>UPI Id</th>
          //         <th>Receipt</th>
          //       </tr>
          //     </thead>
          //     <tbody>
          //       <DonationDetails
          //         donations={donations}
          //         donorDetails={donorDetails}
          //         donationType="Donation"
          //       />
          //     </tbody>
          //   </table>
          // </div>
          <div className="justify-content-center d-flex">
            <h3>No donations</h3>
          </div>
        )}
      </div>

      <div className="mt-3">
        <div className="justify-content-center d-flex">
          <h2>Commitment Details</h2>
        </div>
        <div className="justify-content-center d-flex">
          <Link to={`/acceptdonation/Commitment/${params.donorId}`}>
            Accept New PDC
          </Link>
        </div>
        <div className="justify-content-center d-flex">
          <Link to={`/addcommitment/${params.donorId}`}>
            Add a new commitment
          </Link>
          {/* <button className="btn btn-link ">Accept New Donation</button> */}
        </div>
        {doesCommitmentExist && donations ? (
          <DonationDetails
            donations={commitmenets}
            donorDetails={donorDetails}
            donationType="Commitment"
          />
        ) : (
          // <div className="table-responsive">
          //   <table className="table table-bordered">
          //     <thead>
          //       <tr>
          //         <th>Commitment Date</th>
          //         <th>Amount</th>
          //         <th>Bank</th>
          //         <th>Bank Reference</th>
          //         <th>Bank Refence Date</th>
          //         <th>Collected by</th>
          //         <th>To Trust</th>
          //         <th>Mode</th>
          //         <th>Action</th>
          //       </tr>
          //     </thead>
          //     {/* <tbody>
          //       <CommitmentDetails commitments={commitments} />
          //     </tbody> */}
          //     <tbody>
          //       <DonationDetails
          //         donations={donations}
          //         donorDetails={donorDetails}
          //         donationType="Commitment"
          //       />
          //     </tbody>
          //   </table>
          // </div>
          <div className="justify-content-center d-flex">
            <h3>No commitments</h3>
          </div>
        )}
      </div>
      <div className="mt-3">
        <div className="justify-content-center d-flex">
          <h2>Donations and Commitments from others</h2>
        </div>
        <div className="justify-content-center d-flex">
          {!isGettingDonationsAndCommitmentsFromOthers ? (
            <button
              type="button"
              className="btn btn-link"
              disabled={showDonationsAndCommitmentsFromOthers}
              onClick={getDonationsAndCommitmentsFromOthers}
            >
              Get Details
            </button>
          ) : (
            <button type="button" className="btn btn-link" disabled={true}>
              Getting Details...
            </button>
          )}
        </div>
        {showDonationsAndCommitmentsFromOthers && (
          <div>
            <GetDonationsAndCommitmentsFromOthers
              donorId={currentDonorId}
              toggleIsGettingDonationsAndCommitmentsFromOthersFlag={
                toggleIsGettingDonationsAndCommitmentsFromOthersFlag
              }
            />
          </div>
        )}
      </div>
    </div>
  );
});
