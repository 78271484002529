import React, { useState } from "react";
import { SiConvertio } from "react-icons/si";
import { RiDeleteBin2Fill, RiEdit2Line } from "react-icons/ri";
import MyDialog from "./MyDialog";
import { firestore } from "../firebase/config";
import { useSession } from "../firebase/UserProvider";
import { Link } from "react-router-dom";
import { getFYofDate } from "./utilities/Utils";
function CommitmentActions(props) {
  const [currentCommitment, setCurrentCommitment] = useState(null);
  const [canOpenConvertDialog, setCanOpenConvertDialog] = useState(false);
  const [canOpenDeleteDialog, setCanOpenDeleteDialog] = useState(false);

  const { user } = useSession();
  const donation = props.donation;
  const donorDetails = props.donorDetails;
  const handleConvertCommitmentDialogClose = () =>
    setCanOpenConvertDialog(false);
  const handleDeleteCommitmentDialogClose = () => setCanOpenDeleteDialog(false);

  //Delete Commitment
  //Update Commitment amount of the donor
  //If commitment reference is other than the donor then update that donor's totalCollectionCommitment
  const deleteCommitment = async () => {
    setCanOpenDeleteDialog(false);

    await firestore
      .collection("donors")
      .doc(donorDetails.id)
      .collection("donations")
      .doc(currentCommitment.id)
      .delete()
      .then(async () => {
        //Update Commitment amount of the donor
        let donorTotalCommitment = donorDetails.totalCommitment;
        let newDonorTotalCommitment =
          parseInt(donorTotalCommitment) - parseInt(currentCommitment.amount);

        await firestore
          .collection("donors")
          .doc(donorDetails.id)
          .update({ totalCommitment: newDonorTotalCommitment });

        //If commitment reference is other than the donor then update that donor's totalCollectionCommitment
        if (currentCommitment.collectedBy !== "Self") {
          let refDonorId = currentCommitment.collectedBy;
          //Get the values of refernce donors total commitment from others and total donations from otheres
          const refDonorDoc = await firestore
            .collection("donors")
            .doc(refDonorId)
            .get();
          if (refDonorDoc.exists) {
            const refDonorData = refDonorDoc.data();
            parseInt(refDonorData.totalCommitmentCollection);
            let totalRefCommitmentCollection = parseInt(
              refDonorData.totalCommitmentCollection
            );

            await firestore
              .collection("donors")
              .doc(refDonorId)
              .update({
                totalCommitmentCollection:
                  totalRefCommitmentCollection -
                  parseInt(currentCommitment.amount),
              });
          } //End of  if (refDonorDoc.exists)
        } //end of if (currentCommitment.collectedBy !== "Self")

        //End of promise
      })
      .catch((e) => {
        console.log(`Error while deleting commitment: ${e}`);
      });
  }; //End of deleteCommitment

  const updateRecord = async (receiptNo) => {
    //Update the
    //type to "donation"
    //and receipt no

    await firestore
      .collection("donors")
      .doc(donorDetails.id)
      .collection("donations")
      .doc(currentCommitment.id)
      .update({
        type: "Donation",
        receiptNo: receiptNo,
        updatedAt: new Date(),
        updatedBy: user.email,
      })
      .then(async () => {
        //Update total donaiton and total commitment amount of the donor
        let currenttotalDonation = donorDetails.totalDonation;
        let donationAmount = currentCommitment.amount;

        await firestore
          .collection("donors")
          .doc(donorDetails.id)
          .update({
            totalDonation:
              parseInt(currenttotalDonation) + parseInt(donationAmount),
          })
          .then()
          .catch((e) =>
            console.log(
              `Error while updating donor's total donation after convering the commitment to donation: ${e}`
            )
          );

        //If the donation is collected by other donor, update his total commitment from others and total
        if (currentCommitment.collectedBy !== "Self") {
          let refDonorId = currentCommitment.collectedBy;
          //Get the values of refernce donors total commitment from others and total donations from otheres
          const refDonorDoc = await firestore
            .collection("donors")
            .doc(refDonorId)
            .get();
          if (refDonorDoc.exists) {
            const refDonorData = refDonorDoc.data();
            let totalRefCollection = parseInt(refDonorData.totalCollection);

            await firestore
              .collection("donors")
              .doc(refDonorId)
              .update({
                totalCollection: totalRefCollection + parseInt(donationAmount),
              });
          } //End of  if (refDonorDoc.exists)
        } //End oif (currentCommitment.collectedBy !== "Self")
        //End of promise
      })
      .catch((e) => {
        console.log(`Error in converting commitment to donation: ${e}`);
      })
      .finally();
  }; //End if updateRecord
  //Converts commitment to donation
  //Checks: Donation date is today or in past
  //Update total donation and commitment
  const convertCommitmentToDonation = async () => {
    setCanOpenConvertDialog(false);

    let tDate = new Date();
    let dDate = new Date(currentCommitment.date);
    let canConvert = false;
    if (dDate.getTime() > tDate.getTime()) {
      alert("Future commitments cannot be converted to donation.");
    } else if (currentCommitment.onRecord) {
      if (
        currentCommitment.mode !== "Cash" &&
        (currentCommitment.bankRef === undefined ||
          currentCommitment.bankRefDate === "" ||
          currentCommitment.bankRefDate === undefined ||
          currentCommitment.bankRefDate === null)
      ) {
        alert(
          "Please enter all transacton details like transaction reference no and bank reference date"
        );
        canConvert = false;
      } else if (currentCommitment.mode === "UPI") {
        if (
          currentCommitment.upiId === "" ||
          currentCommitment.upiId === undefined
        ) {
          alert("Please update the UPI Id");
          canConvert = false;
        } else {
          canConvert = true;
        }
      } else {
        canConvert = true;
      }
    } else {
      canConvert = true;
    } //End of if else - if(dDate.getTime() <= tDate.getTime())
    if (canConvert) {
      //Get receipt no
      let receiptNo = "";

      if (currentCommitment.onRecord) {
        let currentFY = getFYofDate(currentCommitment.date);
        let seqNo;
        let seqNoId, recNo;
        await firestore
          .collection("donation-receipt-sequence-nos")
          .where("year", "==", currentFY)
          .where("trust", "==", currentCommitment.trust)
          .get({ source: "server" })
          .then((sequenceNoQuery) => {
            if (!sequenceNoQuery.empty) {
              sequenceNoQuery.forEach((sNo) => {
                seqNo = sNo.data().seqNo;
                if (typeof seqNo === "string") {
                  recNo = parseInt(seqNo) + 1;
                } else {
                  recNo = seqNo + 1;
                }
                seqNoId = sNo.id;

                firestore
                  .collection("donation-receipt-sequence-nos")
                  .doc(seqNoId)
                  .update({
                    seqNo: recNo,
                  });
              });
            } else {
              //If receipt no doesn't exist, then add to db with
              recNo = 1;
              firestore.collection(`donation-receipt-sequence-nos`).add({
                seqNo: recNo,
                trust: currentCommitment.trust,
                year: currentFY,
              });
            } //End of If-else  if (!sequenceNoQuery.empty)
            receiptNo = `${currentCommitment.trust}/${currentFY}/${recNo}`;

            updateRecord(receiptNo);
          })
          .catch((e) =>
            console.log(
              `Error in getting receipt no while converting commitment to donation: ${e}`
            )
          );
      } else {
        updateRecord(receiptNo);
      }
    } //End of if(canConvert)
  }; //End of convertCommitmentToDonation

  return (
    <>
      {props.donorDetails.id ? (
        <>
          <button
            type="button"
            className="btn btn-outline"
            onClick={(e) => {
              e.preventDefault();
              setCurrentCommitment(donation);
              setCanOpenConvertDialog(true);
            }}
          >
            <SiConvertio />
          </button>
          <MyDialog
            open={canOpenConvertDialog}
            handleClose={handleConvertCommitmentDialogClose}
            dialogTitle="Commitment Conversion Confirmation"
            dialogText="Are you sure you want to convert this commitment to donation?"
            handleDefault={convertCommitmentToDonation}
            defaultBtnText="Yes"
            cancelBtnText="No"
          />

          <button
            className="btn btn-outline"
            onClick={(e) => {
              e.preventDefault();
              setCurrentCommitment(donation);
              setCanOpenDeleteDialog(true);
            }}
          >
            <RiDeleteBin2Fill />
          </button>
          <MyDialog
            open={canOpenDeleteDialog}
            handleClose={handleDeleteCommitmentDialogClose}
            dialogTitle="Commitment Delete Confirmation"
            dialogText="Are you sure you want to delete this commitment?"
            handleDefault={deleteCommitment}
            defaultBtnText="Yes"
            cancelBtnText="No"
          />

          <Link to={`/editcommitment/${donorDetails.id}/${donation.id}`}>
            <RiEdit2Line />
          </Link>
        </>
      ) : (
        "Not Available"
      )}
    </>
  );
}

export default React.memo(CommitmentActions);
