import React, { useState, useEffect } from "react";

import { firestore } from "../../firebase/config";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getDateInFormat } from "../utilities/Utils";
import GetReferenceDetails from "../GetReferenceDetails";
import DonationReportDetails from "./ReportDetails";
import { useHistory } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import "./DateRangeDonationReport.css";
const columns = [
  {
    id: "donorId",
    label: "Donor Id",
    show: true,
  },
  {
    id: "donorName",
    label: "Donor Name",
    show: true,
  },
  {
    id: "donationId",
    label: "Committment Id",
    show: true,
  },
  {
    id: "donationReceiptNo",
    label: "Receipt No",
    show: false,
  },
  {
    id: "donationDate",
    label: "Committment Date",
    show: true,
  },
  {
    id: "amount",
    label: "Amount",
    show: true,
  },
  {
    id: "bank",
    label: "Bank",
    show: false,
  },
  {
    id: "bankRef",
    label: "Bank Reference",
    show: false,
  },
  {
    id: "bankRefDate",
    label: "Bank Reference Date",
    show: false,
  },
  {
    id: "mode",
    label: "Mode",
    show: true,
  },
  {
    id: "onRecord",
    label: "On Record",
    show: true,
  },
  {
    id: "collectedBy",
    label: "Reference",
    show: true,
  },
  {
    id: "trust",
    label: "To Trust",
    show: true,
  },
];
function MissedCommmittmentReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [donationDetails, setDonationDetails] = useState(null);
  const [foundNoRecord, setFoundNoRecord] = useState(false);
  const [totalDonationAmount, setTotalDonationAmount] = useState(0);
  const history = useHistory();
  const [columnData, setCoulmnData] = useState(columns);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      setDonationDetails(null);
      setFoundNoRecord(false);
      let toDate = new Date();
      toDate.setHours(23);

      await firestore
        .collectionGroup("donations")
        .where("date", "<=", toDate)
        .where("type", "==", "Commitment")
        .get({ source: "server" })
        .then((querySnapShot) => {
          if (!querySnapShot.empty) {
            let donationArray = [];
            querySnapShot.forEach(async (donation) => {
              let donationReceiptNo = donation.data().receiptNo;
              let donorId = donation.ref.parent.parent.id;

              setTotalDonationAmount(
                (prevAmount) =>
                  parseInt(prevAmount) + parseInt(donation.data().amount)
              );

              donationArray.push({
                donationId: donation.id,

                donorName: (
                  <GetReferenceDetails refId={donorId} needLink={true} />
                ),
                amount: donation.data().amount,
                donationDate: getDateInFormat(
                  donation.data().date.toDate().toString()
                ),
                bank: donation.data().bank,
                bankRef: donation.data().bankRef,
                bankRefDate:
                  donation.data().bankRefDate !== undefined &&
                  (donation.data().bankRefDate !== null) &
                    (donation.data().bankRefDate !== "")
                    ? getDateInFormat(
                        donation.data().bankRefDate.toDate().toString()
                      )
                    : "",
                trust: donation.data().trust,
                mode: donation.data().mode,
                donationReceiptNo:
                  donationReceiptNo !== undefined && donationReceiptNo !== null
                    ? donationReceiptNo
                    : `Not Available`,
                donorId: donorId,
                onRecord: donation.data().onRecord ? "Yes" : "No",
                collectedBy:
                  donation.data().collectedBy === "Self" ? (
                    donation.data().collectedBy
                  ) : (
                    <GetReferenceDetails refId={donation.data().collectedBy} />
                  ),
              });
            }); //End of forEach function
            setDonationDetails(donationArray);
          } else {
            setFoundNoRecord(true);
          } // end of if (!querySnapShot.empty) - else
        }); //End of querying donation data

      setIsLoading(false);
    }

    fetchData();
  }, []);

  const handleBackButton = (e) => {
    history.push("/donorreporthome");
    e.preventDefault();
  };

  const formClass = `${
    isLoading
      ? "card border-top-0 rounded-0 loading"
      : "card border-top-0 rounded-0"
  }`;
  return (
    <>
      <div className="card p-1 mb-1">
        <button
          className="btn btn-link"
          id="backButton"
          onClick={handleBackButton}
        >
          <TiArrowBack />
          Back to Report Home Page
        </button>
        <div className="justify-content-center d-flex">
          <h2>Missed Committment Report</h2>
        </div>
      </div>

      <div className={formClass}>
        <div className="card-body py-2 justify-content-center d-flex">
          <div className="card-title font-weight-bold m-0">
            {donationDetails !== null
              ? `Report`
              : foundNoRecord
              ? `No Record Found`
              : ``}
          </div>
        </div>

        {donationDetails !== null && donationDetails.length > 0 && (
          <>
            <div className="card-body py-2 justify-content-center d-flex">
              No of records: {donationDetails.length}
            </div>
            <div className="card-body py-2 justify-content-center d-flex">
              Total Donation Amount: {totalDonationAmount}
            </div>
            <div className="card-body py-2 justify-content-center d-flex">
              <ReactHTMLTableToExcel
                id="table-xls-success"
                className="btn btn-info"
                table="DateRangeDonationReport"
                filename="DateRangeDonationReport"
                sheet="DateRangeDonationReport"
                buttonText="Download as XLS"
              />
            </div>
            <DonationReportDetails
              data={donationDetails}
              columns={columnData}
              tableId="DateRangeDonationReport"
            />
          </>
        )}
      </div>
    </>
  );
}

export default MissedCommmittmentReport;
