import React, { useState } from "react";
import { firestore } from "../firebase/config";
import DonorList from "./DonorList";

function SearchDonorByName() {
  const [name, setName] = useState("");
  const [queryResult, setQueryResult] = useState(null); //State to store the search result
  const [isNameEntered, setIsNameEntered] = useState(true);
  return (
    <>
      <div>
        <div className="card">
          <div className="justify-content-center d-flex">
            <h2>Search Donor</h2>
          </div>
        </div>
        <div className="row justify-content-center d-flex">
          <form
            className="form-inline"
            onSubmit={async (e) => {
              e.preventDefault();
              setQueryResult(null);
              if (name !== null && name !== "") {
                const query = firestore
                  .collection(`donors`)
                  .where("fullName", ">=", name)
                  .orderBy("fullName")
                  .limit(10);
                await query.get({ source: "server" }).then((QuerySnapshot) => {
                  let donorList = [];
                  QuerySnapshot.forEach((donor) => {
                    donorList.push({
                      donorId: donor.id,
                      fullName: donor.data().fullName,
                      pan: donor.data().pan,
                    });
                  }); //End of QuerySnapshot.forEach
                  setQueryResult(donorList);
                  setName("");
                });
              } //End of if (pan !== null && pan !== "")
              else {
                setIsNameEntered(false);
              }
            }}
          >
            <label className="ml-2">Full Name:</label>
            <input
              type="text"
              className="form-control m-2"
              id="name"
              placeholder="Enter Legal Name"
              name="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value.toUpperCase());
                setIsNameEntered(true);
              }}
            />

            <button type="submit" className="btn btn-primary m-2">
              Search
            </button>
          </form>
        </div>
        <div
          className="card border-top-0 rounded-0"
          style={{ position: "inherit" }}
        >
          <div className="card-body py-2 justify-content-center d-flex">
            <div className="card-title font-weight-bold m-0">Result</div>
          </div>
        </div>

        <div
          className="card border-top-0 rounded-0"
          style={{ position: "inherit" }}
        >
          <div className="card-body py-2 justify-content-center d-flex">
            <div className="card-title font-weight-bold m-0">
              {queryResult != null && (
                <>
                  Query Results here..
                  {queryResult.length > 0 ? (
                    <DonorList queryResult={queryResult} entity="donor" />
                  ) : (
                    <div>No donor found</div>
                  )}
                </>
              )}
              {!isNameEntered ? <div>Please enter a search criteria</div> : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchDonorByName;
