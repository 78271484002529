import React, { memo, useEffect, useState } from "react";
import { firestore } from "../firebase/config";
import { Link } from "react-router-dom";
export default memo(function GetReferenceDetails(props) {
  const [refDetails, setRefDetails] = useState("");
  useEffect(() => {
    const refId = props.refId;

    if (!!firestore && refId !== null && refId.length > 0) {
      firestore
        .collection("donors")
        .doc(refId)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            let refFullName = snapshot.data().fullName;

            props.needLink
              ? setRefDetails(
                  <Link to={`/donordetails/${refId}`}>{refFullName}</Link>
                )
              : setRefDetails(refFullName);
            // setRefDetails(
            //   props.needLink ? (
            //     <Link to={`/donordetails/${refId}`}>{refFullName}</Link>
            //   ) : (
            //     { refFullName }
            //   )
            // );
          }
        }); //End of await firestore.collection('donors').
    } //end of if(!!firestore)
  }, []); //End of useEffect

  return <>{refDetails}</>;
});
