import { firestore } from "../../firebase/config";
import React, { memo, useEffect, useState, Fragment } from "react";
import { useSession } from "../../firebase/UserProvider";
import {useParams} from "react-router-dom";
import AdmissionDashboard from "./AdmissionDashboard";
import { Link } from "react-router-dom";



function AdmissionPanel(){
    const {user} = useSession();
    const [dataRecieved, setDataRecieved] = useState([]);
    let {id,name} = useParams();
    let parentId;
    
    useEffect(async() => {
       
        await firestore
            .collection("students")
            .where("updatedBy", "==", user.email)
            .get({source: "server"})
            .then(async (QuerySnapshot) => {
                QuerySnapshot.forEach((doc) => {
                setDataRecieved(prevState => [...prevState, doc.data()]);
                
                });
            });

            

    },[]);
    
    const handleProceed = (e,childName) => {
        e.preventDefault();
        console.log(childName);
        
       
    };

    return(
        <div style={{ position: "inherit" }}>
            <div className="card p-1 mb-1">
                <div className="justify-content-center d-flex">
                    <h2>Please select an admission candidate</h2>
                </div>
            </div>
          
            <div className="card p-3">
                <div className="row">
                {dataRecieved !== [] ? (
                
                dataRecieved.map((data,index) => (
                
                    <Fragment key={`${data}~${index}`}>
                        
                        
                        <div className = "col-md-3">
                        
                            <div className="card border-dark mb-3 ">
                                <div className="card-header font-weight-bold">{data.studentName}</div>
                                <div className="card-body text-dark">
                                    <h5 className="text-success font-italic">Status : In Process</h5>
                                    <Link to={`/admissionpanel/${data.studentId}`}>
                                        <button className="btn btn-outline-dark btn-block">
                                            Proceed
                                        </button>
                                    </Link>
                                </div>
                            </div>
                    
                        </div>

                        
                    </Fragment>
                    
                ))
            ) : <div className="text-danger ">
                    <h6>***Please Update Parent Details keeping at 
                least one child as currently taking admission in Bhaktivadanta Model School***</h6>
                </div>}
                    
                    
                    
                    
                    
                            
                </div> 
                
                
            </div>
            
        </div>
    );
}



export default AdmissionPanel;