import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import ReceiptLeftSection from "./ReceiptLeftSection";
import ReceiptRightSection from "./ReceiptRightSection";

const styles = StyleSheet.create({
  ViewTop: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  leftSection: {
    width: "17%",
    height: "100%",
  },
  rightSection: {
    width: "83%",
    height: "auto",
  },
});
function ReceiptMainBody() {
  return (
    <>
      <View style={styles.ViewTop}>
        <View style={styles.leftSection}>
          <ReceiptLeftSection />
        </View>
        <View style={styles.rightSection}>
          <ReceiptRightSection />
        </View>
      </View>
    </>
  );
}

export default ReceiptMainBody;
